import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { Organization, OrganizationMember, PermissionString, UserPermissionMap } from '../../../common/types/OrganizationTypes'
import { urls } from '../api/axios'
import { getOrganization, getOrganizationPermissions, removeMemberFromOrganization, updateOrganizationMember } from '../api/organizations'
import useAuth from './useAuth'
import { cancelSubscription } from '../api/subscriptions'
import moment from 'moment'
import { OrganizationAccessLevels, OrganizationSubscriptionStates } from '../../../common/enums/OrganizationEnums'
import { useHistory } from 'react-router'

interface UpdateMemberParams {
	accessLevel: string,
	memberId: string
}

interface WarningBanner {
	title: string,
	linkText: string
}

const Context = createContext<{
	organization: Organization,
	isLoading: boolean,
	accessLevel: string,
	removeMember(userId: string): Promise<void>,
	updateMember(params: UpdateMemberParams): Promise<void>,
	can(action:PermissionString): boolean | undefined,
	setOrganizatinId(s?: string): void,
	cancelOrganizationSubscription(): Promise<void>,
	warningBanner?: WarningBanner
}>(null as any)


interface ProviderProps{
	children:any,
	organizationId?:string
}

export const OrganizationProvider = ({ children, organizationId }: ProviderProps) => {
	const [organization, setOrganization] = useState<Organization>()
	const [_organizationId, setOrganizatinId] = useState<string>()
	const [isLoading, setIsLoading] = useState(true)
	const [accessLevel, setAccessLevel] = useState('')
	const [warningBanner, setWarningBanner] = useState<WarningBanner>()
	const [permissionMap, setPermissionMap] = useState<UserPermissionMap>()
	
	const { account } = useAuth()
	
	const fetchOrg = async () => {
		if (_organizationId) {
			setIsLoading(true)
			
			const newOrg = await getOrganization().then(({ data }) => data)
			
			setOrganization(newOrg)

			const me = newOrg.members.find((m:OrganizationMember) => m.user._id === account._id)
			
			if (me) {
				setAccessLevel(me.accessLevel)
			}
			
			const newMap = await getOrganizationPermissions().then(({ data }) => data)
			
			setPermissionMap(newMap)
			setIsLoading(false)
		}
	}
	
	const can = useCallback((action:PermissionString) => {
		return permissionMap && permissionMap[action]
	}, [permissionMap])

	useEffect(() => {
		setOrganizatinId(organizationId)
	}, [organizationId])
	
	useEffect(() => {
		if (_organizationId) {
			urls.organization = `${urls.baseOrganization}${_organizationId}`
		}
		
		fetchOrg()
	}, [_organizationId])
	
	const removeMember = useCallback(async (userId: string) => {
		if (!organization) {
			return
		}
		
		await removeMemberFromOrganization({ userId })
		
		await getOrganization().then(({ data }) => setOrganization(data))
	}, [organization?._id])

	const updateMember = useCallback(async ({ accessLevel, memberId }: UpdateMemberParams) => {
		if (!organization) {
			return
		}
		
		await updateOrganizationMember({ accessLevel, memberId, organizationId: organization._id })
		
		await getOrganization().then(({ data }) => setOrganization(data))
	}, [organization?._id])
	
	const cancelOrganizationSubscription = async () => {
		await cancelSubscription()
		await fetchOrg()
	}

	useEffect(() => {
		if (!organization) {
			return
		}
		if (accessLevel == OrganizationAccessLevels.admin) {
			const paymentDueInXWeeks = moment(organization.paymentDueDate).diff(moment.now(), 'w')
			if (organization.subscriptionState == OrganizationSubscriptionStates.trial) {
				if (paymentDueInXWeeks < 2) {
					setWarningBanner({
						title: 'trial_ending',
						linkText: 'subscribe',
					})
				}
			} else if (organization.subscriptionState == OrganizationSubscriptionStates.lapsed) {
				if (paymentDueInXWeeks < 1) {
					setWarningBanner({
						title: 'payment_failed',
						linkText: 'update_payment_details',
					})
				}
			}
		}
	}, [organization?._id, accessLevel])

	return <Context.Provider value={{
		isLoading,
		organization: organization as Organization,
		accessLevel,
		removeMember,
		updateMember,
		can,
		setOrganizatinId,
		cancelOrganizationSubscription,
		warningBanner,
	}}>
		{ children }
	</Context.Provider>
}

const useOrganization = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useOrganization outside provider!')
	}
	return val
}

export default useOrganization
