import React, { ReactElement, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { SelectBoxOption } from './SelectBox'
import MultiSelectPanelView from './MultiSelectPanelView/MultiSelectPanelView'
import { Container } from './AsyncSelectSerach/AsyncSelectSearch.styled'
import Line from '../../Line/Line'
import TextBox from '../TextBox'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'

interface FetchOptionsResponse {
	newOptions: SelectBoxOption[],
	hasMorePages?: boolean,
}
interface Props {
	onValuesChange(newValues: any[]): any
	values: any[],
	wide?: boolean,
	error?: any,
	className?: string,
	label?: any,
	name: string,
	append?: ReactElement,
	fetchOptions({ searchText, page }: { searchText: string, page: number }): Promise<FetchOptionsResponse> | FetchOptionsResponse,
}

const MultiSelect = React.forwardRef<any, Props>(({
	onValuesChange,
	values,
	wide,
	error,
	className,
	label,
	name,
	append,
	fetchOptions,
}:Props, ref: any) => {
	const textBoxRef = useRef<HTMLInputElement>()
	const [search, setSearch] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [hasMorePages, setHasMorePages] = useState(false)
	const [page, setPage] = useState(1)

	const [filteredOptions, setFilteredOptions] = useState<SelectBoxOption[]>([])

	const clickHandler = (opt:SelectBoxOption) => {
		if (values.map(({ value }) => value).includes(opt.value)) {
			onValuesChange(values.filter(v => v.value!==opt.value))
		} else {
			onValuesChange([...values, opt])
		}
	}
	const debounced = useRef<any>()
	
	const changeHandler = async (e:any) => {
		setSearch(e.target.value)
		
		clearTimeout(debounced.current)
		debounced.current = setTimeout(async () => {
			setIsLoading(true)
			setPage(1)
			const { newOptions, hasMorePages } = await fetchOptions({ searchText: e.target.value, page: 1 })
			setHasMorePages(Boolean(hasMorePages))
			setFilteredOptions(newOptions)
			setIsLoading(false)
		}, 500)
	}
	
	const openHandler = () => {
		textBoxRef.current && textBoxRef.current.focus()
		
		debounced.current = setTimeout(async () => {
			setIsLoading(true)
			setPage(1)
			const { newOptions, hasMorePages } = await fetchOptions({ searchText: '', page: 1 })
			setHasMorePages(Boolean(hasMorePages))
			setFilteredOptions(newOptions)
			setIsLoading(false)
		}, 0)
	}

	const loadMore = () => {
		if (!isLoading && hasMorePages) {
			clearTimeout(debounced.current)
			debounced.current = setTimeout(async () => {
				const newPage = page + 1
				setIsLoading(true)
				setPage(newPage)
				const { newOptions, hasMorePages } = await fetchOptions({ searchText: search, page: newPage })
				setHasMorePages(Boolean(hasMorePages))
				setFilteredOptions([...filteredOptions, ...newOptions])
				setIsLoading(false)
			}, 0)
		}
	}
	
	return (
		<div className='wide'>
			{label && <label className={wide ? 'wide' : ''}>{label}</label>}
			<Container>
				<MultiSelectPanelView
					wide={wide}
					error={error}
					className={className}
					values={values}
					onOpen={openHandler}
					onClickOption={clickHandler}
					options={filteredOptions}
					loadMore={loadMore}
				>
					<div className="searchContainer">
						<TextBox
							name={`search-${name}`}
							placeholder='Search'
							wide
							ref={textBoxRef}
							value={search}
							onChange={changeHandler}
						/>
						<Line />
					</div>
				</MultiSelectPanelView>
				{append}
			</Container>
		</div>
	)
})

interface FormProps extends Props {
	name: string
	rules?: any
}
export function MultiSelectForm (props: FormProps) {
	const form = useFormContext()
	const onValuesChange = (newValue: any[]) => {
		form.setValue(props.name, newValue)
		props.onValuesChange(newValue)
	}
	
	return (
		<>
			<MultiSelect {...props} error={form.formState.errors[props.name]} onValuesChange={onValuesChange} {...form.register(props.name, props.rules || {})}/>
			<ErrorMessage name={props.name} />
		</>
	)
}
export default MultiSelect
