import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FreeButton, Page, PageMessageContents } from 'react-ui-scaffold'
import TextBox from '../../components/inputs/TextBox'
import Form from '../../Form/Form'
import { useTranslation } from 'react-i18next'
import { email_regex } from '../../utils/rules'
import { Divider, LoginContainer, LogoContainer } from './ResetPassword.styled'
import { useHistory } from 'react-router'
import { LOGIN_PATH } from '../../../../common/paths'
import logo from '../../assets/logo_full.svg'
import useAuth from '../../data-hooks/useAuth'
import useMessageQueue from '../../hooks/MessageQueue/useMessageQueue'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default function ResetPassword() {
	const { t } = useTranslation()
	const { resetPassword } = useAuth()
	const { addSuccessMesasage } = useMessageQueue()
	const [message, setMessage] = useState<PageMessageContents>()

	const history = useHistory()
	const form = useForm({
		defaultValues: {
			email: '',
		},
	})
	const { register, formState: { errors }, watch, handleSubmit } = form

	const _resetPassword = (body: { [key: string]: string }) => {
		resetPassword({ email: body.email })
			.then(() => {
				addSuccessMesasage({
					title: t('email_sent'),
				})
				history.push(LOGIN_PATH)
			})
			.catch((res) => {
				if (res.response?.status == 422 && res.response?.data?.error) {
					setMessage({
						content: t(res.response.data?.error),
						sticky: true,
						color: 'var(--negativeAccentColor)',
						icon: faExclamationCircle,
					})
				}
			})
	}

	return (
		<Page className='Login' message={message}>
			<div className="flexCenter loginBody">
				<div className="loginCard">
					<LogoContainer className="logo">
						<img src={logo} />
					</LogoContainer>
					<FormProvider {...form}>
						<Form showButtons={false} onSubmit={handleSubmit(_resetPassword)}>
							<TextBox
								wide
								placeholder={t('email') as string}
								label={t('email') as string}
								error={errors.email}
								{...register('email', {
									required: true,
									validate: {
										email_regex,
									},
								})}
							/>
							<FreeButton className="button wide cion" type='submit'>{t('reset_passowrd')}</FreeButton>
						</Form>
					</FormProvider>
					<LoginContainer>
						<Divider />
						<FreeButton className="button wide neutral plain" onClick={() => history.push(LOGIN_PATH)}>{t('back_to_sign_in')}</FreeButton>
					</LoginContainer>
				</div>
			</div>
		</Page>
	)
}
