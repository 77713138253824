import { Customer, CreateCustomerParams } from '../../../../common/types/CustomerTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { createCustomer, deleteCustomer, getCustomers } from '../../api/customers'
import useOrganization from '../useOrganization'
import { AxiosResponse } from 'axios'

interface SearchParams {
	query?: string,
	email?: string,
	name?: string,
	phoneNo?: string
}
const Context = createContext<{
	customers: Customer[],
	isLoading: boolean,
	create(c:CreateCustomerParams):Promise<AxiosResponse<Customer>>,
	searchParams: SearchParams | undefined,
	setSearchParams(s:SearchParams): void,
	del(customerId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	replace(customer: Customer): void,
}>(null as any)


interface ProviderProps{
	children:any
}

export const CustomersProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [customers, setCustomers] = useState<Customer[]>([])
	
	const [searchParams, setTrueSearchParams] = useState<undefined | SearchParams>()
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	
	const setSearchParams = (s:SearchParams) => {
		setTrueSearchParams(s)
	}
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getCustomers({ page, ...searchParams })
			.then(({ data: { docs, hasMorePages } }) => {
				setCustomers(docs)
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}, [organization._id, page, searchParams])
	
	const create = useCallback(async (params:CreateCustomerParams) => {
		const newCustomer = await createCustomer(params)
		await getCustomers({}).then(({ data: { docs } }) => setCustomers(docs))
		return newCustomer
	}, [organization._id])
	
	const del = useCallback(async (customerId: string) => {
		await deleteCustomer(customerId)
		setCustomers(curr => curr.filter(c => c._id !== customerId))
	}, [])

	const replace = useCallback((customer: Customer) => {
		const index = customers.findIndex(({ _id }) => _id == customer._id)
		let newCustomers = [...customers]
		if (index != -1) {
			newCustomers[index] = customer
			setCustomers(newCustomers)
		}
	}, [customers])

	return <Context.Provider value={{
		customers,
		isLoading,
		create,
		searchParams,
		setSearchParams,
		del,
		page,
		setPage,
		hasMorePages,
		replace,
	}}>
		{ children }
	</Context.Provider>
}

const useCustomers = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useCustomers outside provider!')
	}
	return val
}

export default useCustomers
