import React, { createContext, useContext, useState } from 'react'

const DockStateContext = createContext<{
	hidden: Boolean,
	setHidden(b: Boolean): void
}>({} as any)

export default function DockStateProvider({ children }:any) {
	const [hidden, setHidden] = useState<Boolean>(true)
	
	return (
		<DockStateContext.Provider value={{ hidden, setHidden }}>
			{children}
		</DockStateContext.Provider>
	)
}

export function useDockState() {
	return useContext(DockStateContext)
}
