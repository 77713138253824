import React from 'react'
import { Route, Switch } from 'react-router'
import { ORGANIZATION_MEMBERS_ADD_PATH } from '../../../../../../../common/paths'
import InviteOrgaizationMember from './InviteOrgaizationMember/InviteOrgaizationMember'
import { OrganizationInvitesProvider } from '../../../../../data-hooks/useOrganizationInvites'

export default function OrganizationMembersRoutes() {

	return (
		<OrganizationInvitesProvider>
			<Switch>
				<Route exact path={ORGANIZATION_MEMBERS_ADD_PATH} component={InviteOrgaizationMember}/>
			</Switch>
		</OrganizationInvitesProvider>
	)
}
