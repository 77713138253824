import React, { useState } from 'react'
import useOrganization from '../../data-hooks/useOrganization'
import { useScreenSize } from '../../hooks/useScreenSize'
import { useTranslation } from 'react-i18next'
import { BannerWrapper } from './Banner.style'
import { Link } from 'react-router-dom'
import { SETTINGS_PATH } from '../../../../common/paths'

export default function Banner() {
	const [showMore, setShowMore] = useState<boolean>(false)
	const { t } = useTranslation()
	const { screenSize } = useScreenSize()
	const { warningBanner } = useOrganization()
	
	if (!warningBanner) {
		return null
	}
	
	return (
		<BannerWrapper showMore={showMore}>
			<div>
				{t(warningBanner.title)}
				&nbsp;
				<Link target='_blank' to={SETTINGS_PATH}>{t(warningBanner.linkText)}</Link>
			</div>
			{screenSize == 'mobile' && <a onClick={() => setShowMore(!showMore)}>{t(showMore ? 'show_less' : 'show_more')}</a>}
		</BannerWrapper>
	)
}
