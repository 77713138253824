import React, { useEffect } from 'react'
import { Page } from 'react-ui-scaffold'
import { Container, Subtitle, Title } from './SubscriptionRequired.styled'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DASHBOARD_PATH } from '../../../../../../common/paths'
import useOrganization from '../../../../data-hooks/useOrganization'
import { OrganizationSubscriptionStates } from '../../../../../../common/enums/OrganizationEnums'
import moment from 'moment'
import Subscriptions from '../Settings/Subscriptions/Subscriptions'
const { APP_NAME = 'Phlevo' } = process.env

export default function SubscriptionRequired() {
	const history = useHistory()
	const { t } = useTranslation()
	const { organization } = useOrganization()

	useEffect(() => {
		if (organization.subscriptionState == OrganizationSubscriptionStates.active || moment(organization.paymentDueDate).isAfter(moment.now())) {
			history.push(DASHBOARD_PATH)
		}
	}, [])
	
	return (
		<Page>
			<Container>
				<Title>{t(organization.subscriptionState == OrganizationSubscriptionStates.trial ? 'trial_is_over' : 'issue_with_payment')}</Title>
				<Subtitle>{t('to_continue_using', { app: APP_NAME })}</Subtitle>
				<Subscriptions hideText />
			</Container>
		</Page>
	)
}
