import React, { useContext, createContext, useEffect, useState } from 'react'
import { TestBank } from '../../../common/types/TestBankTypes'
import { getTestBanks } from '../api/testBanks'
import { getOrganizationTestBanks, updateOrganizationTestBanks } from '../api/organizationTestBanks'
import { UpdateOrganizationTestBankParams } from '../../../common/types/OrganizationTestBankTypes'


const Context = createContext<{
	organizationTestBanks: Array<string>,
	testBanks: Array<TestBank>,
	updateTestBanks: (e: UpdateOrganizationTestBankParams) => Promise<void>,
}>(null as any)


interface ProviderProps{
	children:any,
	organizationId?: string
}

export const TestBanksProvider = ({ children, organizationId }: ProviderProps) => {
	const [testBanks, setTestBanks] = useState<Array<TestBank>>([])
	const [organizationTestBanks, setOrganizationTestBanks] = useState<Array<string>>([])

	useEffect(() => {
		getTestBanks().then(({ data }) => setTestBanks(data))
	}, [])

	useEffect(() => {
		if (organizationId) {
			getOrganizationTestBanks().then((data) => {
				setOrganizationTestBanks(data?.data?.testBanks || [])
			})
		}
	}, [organizationId])

	const updateTestBanks = async (e: UpdateOrganizationTestBankParams) => {
		await updateOrganizationTestBanks(e).then((res) => {
			setOrganizationTestBanks(res?.data?.testBanks || [])
		})
	}

	return <Context.Provider value={{
		organizationTestBanks,
		testBanks,
		updateTestBanks,
	}}>
		{ children }
	</Context.Provider>
}

const useTestBanks = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useTestBank outside provider!')
	}
	return val
}

export default useTestBanks
