import { faCaretDown, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { Loading } from 'react-ui-scaffold'
import PanelButton, { PanelButtonRef } from '../../../PanelButton/PanelButton'
import { SelectBoxOption } from '../SelectBox'
import { Chip, ChipContainer, ChipRemoveButton, LoadingContainer, OptionsContainer } from './MultiSelectPanelView.styled'
import { useTranslation } from 'react-i18next'

interface BaseProps {
	options: SelectBoxOption[],
	filteredOptions?: SelectBoxOption[],
	onClickOption(v:SelectBoxOption):any,
	wide?:boolean,
	className?:string,
	error?:boolean,
	children?:any,
	onOpen?():void,
	onClose?():void,
	loadMore?():void,
	isLoading?: boolean,
}

interface PropsWithSingleValue extends BaseProps {
	value: any
	values?: null | undefined
}

interface PropsWithMultipleValues extends BaseProps {
	values: any[]
	value?: null | undefined
}

export default function SelectPanelView({
	options,
	filteredOptions,
	value,
	values,
	onClickOption,
	wide,
	className,
	error,
	children,
	onOpen,
	onClose,
	loadMore,
	isLoading,
}: PropsWithSingleValue | PropsWithMultipleValues) {
	const displayOptions = filteredOptions || options
	const panelButtonRef = useRef<PanelButtonRef>(null)
	const { t } = useTranslation()
	
	const isActive = (option:SelectBoxOption) => {
		if (values) {
			return values.map(({ value }) => value).includes(option.value)
		}
		
		return option.value === value
	}

	const onScroll = (e: any) => {
		const { scrollHeight, scrollTop, clientHeight } = e.target
		const nearBottom = scrollHeight - scrollTop - clientHeight < 60
		if (nearBottom) {
			loadMore?.()
		}
	}
	
	const items = <>
		{children}
		<OptionsContainer onScroll={onScroll}>
			{displayOptions.map((opt, i) => {
				const label = isActive(opt) ? <><FontAwesomeIcon icon={faCheck} /> {opt.label}</> : opt.label
				const color = isActive(opt) ? 'var(--neutralAccentColor)' : ''

				return <button
					className="button plain wide dropdownButtonItem"
					onClick={() => {
						onClickOption(opt)
						panelButtonRef.current?.close()
					}}
					key={i}
					style={{
						color,
					}}
				>
					{label}
				</button>
			})}
			{isLoading && <LoadingContainer>
				<Loading className='spinner'/>
				{t('loading...')}
			</LoadingContainer>}
		</OptionsContainer>
	</>

	const selected = <ChipContainer>
		{values?.map((opt) => {
			return (
				<Chip key={opt.value}>
					{opt.label}
					<ChipRemoveButton
						icon={faTimes}
						onClick={(e) => {
							e.stopPropagation()
							onClickOption(opt)
						}}
					/>
				</Chip>
			)
		})}
	</ChipContainer>
	
	const wideClass = wide ? 'wide' : ''
	
	return <PanelButton
		ref={panelButtonRef}
		panelContent={items}
		className={`input SelectBox hollow ${className} ${wideClass} ${error && 'error'}`}
		widePanel
		panelClassName='selectBoxPanel'
		onClose={onClose}
		onOpen={onOpen}
	>
		{selected}
		<span className="caret">
			<FontAwesomeIcon icon={faCaretDown} />
		</span>
	</PanelButton>
}
