import { TestProfile } from '../../../../../../../../common/types/TestProfileTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import LabSelect from '../../../../../../components/inputs/resourceSelects/LabSelect/LabSelect'
import TestMultiSelect from '../../../../../../components/inputs/resourceSelects/TestMultiSelect/TestMultiSelect'
import { useTranslation } from 'react-i18next'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { createTestProfileEditPath } from '../../../../../../../../common/paths'
import { Link } from 'react-router-dom'
import { SelectBoxOption } from '../../../../../../components/inputs/selects/SelectBox'
import { positive } from '../../../../../../utils/rules'
import TextArea from '../../../../../../components/inputs/TextArea'
import { WysiwygForm } from '../../../../../../components/inputs/Wysiwyg/Wysiwyg'
import { centToDollar, dollarToCent } from '../../../../../../utils/dollarUtils'


interface TestProfileForm {
	code: string
	lab: null | SelectBoxOption,
	testIds: null | Array<SelectBoxOption>,
	specimenType?: string,
	collectionRequirements?: string,
	turnaroundTime?: number,
	temp?: string,
	price?: string,
	note?: string,
	overview?: string,
	preparationInstructions?: string,
	volume?: string,
	container?: string,
	storageAndTransport?: string,
}
interface Props{
	testProfile?: TestProfile,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void
}

export default function AddEditTestProfileForm({
	testProfile,
	submitHandler,
	cancelHandler,
}:Props) {
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const form = useForm<TestProfileForm>()
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	const { handleSubmit, register, formState: { errors }, setValue, reset } = form

	useEffect(() => {
		if (testProfile) {
			setValue('code', testProfile.code)
			if (testProfile.labId) {
				setValue('lab', { label: testProfile.labId.name, value: testProfile.labId._id })
			}
			setValue('price', testProfile.price ? centToDollar(testProfile.price || 0) : '')
			setValue('collectionRequirements', testProfile.collectionRequirements)
			setValue('container', testProfile.container)
			setValue('note', testProfile.note)
			setValue('overview', testProfile.overview)
			setValue('preparationInstructions', testProfile.preparationInstructions)
			setValue('specimenType', testProfile.specimenType)
			setValue('storageAndTransport', testProfile.storageAndTransport)
			setValue('temp', testProfile.temp)
			setValue('turnaroundTime', testProfile.turnaroundTime)
			setValue('volume', testProfile.volume)
			setValue('testIds', testProfile.tests?.map((val) => ({ label: val.code, value: val._id })) as any)
		}
	}, [testProfile])

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		const body = {
			...e,
			lab: e.lab?.value,
			testIds: (e.testIds || []).map((test: any) => test.value),
			price: dollarToCent(e.price),
		}
		submitHandler(body)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(testProfile?._id ? 'test_profile_updated' : 'test_profile_created'),
					content: (
						<Link target='_blank' to={createTestProfileEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if (testProfile?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(testProfile?._id ? 'test_profile_update_failed' : 'test_profile_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
			>
				<TextBox
					wide
					placeholder={t('code') as string}
					label={t('code') as string}
					error={errors.code}
					{...register('code', { required: true })}
				/>
				<TestMultiSelect
					defaultValue={testProfile?.tests}
					rules={{
						array_required: true,
					}}
				/>
				<TextBox
					wide
					placeholder={t('price') as string}
					label={t('price') as string}
					error={errors.price}
					type="number"
					step="any"
					{...register('price', {
						validate: {
							positive,
						},
					})}
				/>
				<WysiwygForm
					wide
					label={t('collection_requirements') as string}
					error={errors.collectionRequirements}
					name='collectionRequirements'
					defaultValue={testProfile?.collectionRequirements}
				/>
				<TextBox
					wide
					placeholder={t('container') as string}
					label={t('container') as string}
					error={errors.container}
					{...register('container')}
				/>
				<TextArea
					wide
					placeholder={t('note') as string}
					label={t('note') as string}
					error={errors.note}
					{...register('note')}
				/>
				<WysiwygForm
					wide
					label={t('overview') as string}
					error={errors.overview}
					name='overview'
					defaultValue={testProfile?.overview}
				/>
				<WysiwygForm
					wide
					label={t('preparation_instructions') as string}
					error={errors.preparationInstructions}
					name='preparationInstructions'
					defaultValue={testProfile?.preparationInstructions}
				/>
				<TextBox
					wide
					placeholder={t('specimen_type') as string}
					label={t('specimen_type') as string}
					error={errors.specimenType}
					{...register('specimenType')}
				/>
				<TextBox
					wide
					placeholder={t('storage_and_transport') as string}
					label={t('storage_and_transport') as string}
					error={errors.storageAndTransport}
					{...register('storageAndTransport')}
				/>
				<TextBox
					wide
					placeholder={t('temp') as string}
					label={t('temp') as string}
					error={errors.temp}
					{...register('temp')}
				/>
				<TextBox
					wide
					placeholder={t('turnaround_time') as string}
					label={t('turnaround_time') as string}
					error={errors.turnaroundTime}
					{...register('turnaroundTime')}
				/>
				<TextBox
					wide
					placeholder={t('volume') as string}
					label={t('volume') as string}
					error={errors.volume}
					{...register('volume')}
				/>
				<LabSelect defaultValue={testProfile?.labId} clearable />
			</Form>
		</FormProvider>
	)
}
