import { faSyringe } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { createCustomerToAppointmentPath } from '../../../../../../../common/paths'
import BasicActions from '../../../../../components/Table/components/BackActions/BasicActions'
import { ActionIcon } from '../../../../../components/Table/components/BackActions/BasicActions.styled'
import { Row } from './Customers.styled'

export default function CustomerActions({ row, editHandler, deleteHandler }:QuickTableCellProps) {
	const history = useHistory()
	const { t } = useTranslation()
	
	return (
		<Row>
			<ActionIcon icon={faSyringe} onClick={() => history.push(createCustomerToAppointmentPath(row._id))} tooltip={t('create_appointment') || ''} />
			
			<BasicActions
				deleteHandler={() => deleteHandler(row)}
				editHandler={() => editHandler(row)}
			/>
		</Row>
	)
}
