import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { PageMessageContents, Page, FreeButton } from 'react-ui-scaffold'
import './Login.css'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import useAuth, { EmailAndPasswordParams } from '../../data-hooks/useAuth'
import { RESET_PASSWORD_PATH, MAIN_PATH, REGISTER_PATH, LOGIN_PATH } from '../../../../common/paths'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../Form/Form'
import TextBox from '../../components/inputs/TextBox'
import { email_regex } from '../../utils/rules'
import { Divider, ForgotPasswordLink, LoginContainer } from './Login.styled'
import LoginWithGoogle from '../../components/LoginWithGoogle/LoginWithGoogle'
import logo from '../../assets/logo_full.svg'

export default function Login() {
	const form = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
	})
	const { register, formState: { errors }, handleSubmit } = form
	const { user, error, loginWithEmailAndPassword } = useAuth()
	const [message, setMessage] = useState<PageMessageContents>()
	const { t } = useTranslation()
	const history = useHistory()
	const { search } = useLocation()

	useEffect(() => {
		if (error?.error) {
			const errorCode = error.error
			const content = t(errorCode) == errorCode ? t('something_went_wrong') : t(errorCode)
			setMessage({
				content,
				sticky: true,
				color: 'var(--negativeAccentColor)',
				icon: faExclamationCircle,
			})
		} else {
			setMessage(undefined)
		}
	}, [error])

	const onLogin = async (body: EmailAndPasswordParams) => {
		await loginWithEmailAndPassword(body)
	}

	if (user) {
		return <Redirect to={MAIN_PATH} />
	}

	return (
		<Page message={message} className='Login'>
			<div className="flexCenter loginBody">
				<div className="loginCard">
					<div className="logo">
						<img src={logo} />
					</div>

					<div className="layer">
						<FormProvider {...form}>
							<Form showButtons={false} onSubmit={handleSubmit(onLogin)}>
								<TextBox
									wide
									placeholder={t('email') as string}
									label={t('email') as string}
									error={errors.email}
									{...register('email', {
										validate: {
											email_regex,
										},
									})}
								/>
								<TextBox
									wide
									label={t('password') as string}
									error={errors.password}
									type='password'
									{...register('password', {
										required: true,
									})}
								/>
								<FreeButton className="button wide cion" onClick={() => {}}>{t('login')}</FreeButton>
								<ForgotPasswordLink to={RESET_PASSWORD_PATH}>{t('forgot_password')}</ForgotPasswordLink>
							</Form>
						</FormProvider>
						<LoginContainer>
							<Divider />
							<LoginWithGoogle setMessage={setMessage} />
							<FreeButton className="button wide neutral plain" onClick={() => history.push(`${REGISTER_PATH}${search}`)}>{t('register')}</FreeButton>
						</LoginContainer>
					</div>
				</div>
			</div>
		</Page>
	)
}
