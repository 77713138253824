import styled from 'styled-components'

const IconContainer = styled.span`
	color: #fff !important;
	cursor: pointer;
	background-color: rgba(var(--neutralAccentValues), 100%) !important;
	box-shadow: rgba(0, 0, 0, 0.01) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	font-size: 1.1rem;
	font-weight: bold;
	white-space: nowrap;
`

export {
	IconContainer,
}
