import React from 'react'
import { OrganizationMember } from '../../../../../../../../common/types/OrganizationTypes'
import useOrganizationInvites from '../../../../../../data-hooks/useOrganizationInvites'
import MemberRow from './MemberRow'
import './Members.css'

interface Props{
	members: OrganizationMember[],
	onAccessLevelChange(memberId: string, accessLevel: string): Promise<void>,
	onInviteAccessLevelChange(inviteId: string, accessLevel: string): Promise<void>
}

export default function Members({ members, onAccessLevelChange, onInviteAccessLevelChange }:Props) {
	const { invites } = useOrganizationInvites()
	
	return (
		<div className='Members'>
			{members.map(m => <MemberRow
				key={m.user._id}
				user={{ ...m.user, _id: m._id }}
				accessLevel={m.accessLevel}
				onAccessLevelChange={onAccessLevelChange}
				onInviteAccessLevelChange={onInviteAccessLevelChange}
			/>)}
			
			{invites.map(m => <MemberRow
				key={m._id}
				user={m.invitedUser}
				accessLevel={m.accessLevel}
				invite={m}
				onAccessLevelChange={onAccessLevelChange}
				onInviteAccessLevelChange={onInviteAccessLevelChange}
			/>)}
		</div>
	)
}
