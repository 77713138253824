import React from 'react'
import { Page } from 'react-ui-scaffold'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import Dock from './Dock'
import { useDockState } from './DockStateProvider'
import './MainLayout.css'

interface Props {
	children: any,
	className?:string,
	loading?: boolean
}

export default function MainLayout({ children, className, loading }:Props) {
	const { hidden } = useDockState()
	
	return (
		<div className='MainLayout flex'>
			<Dock/>
			<ErrorBoundary>
				<Page className={`notDock ${className} ${hidden ? '' : 'blurry'}`} loading={loading}>
					{children}
				</Page>
			</ErrorBoundary>
		</div>
	)
}
