import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import Main from './pages/Main/Main'
import { AuthProvider } from './data-hooks/useAuth'
import { CREATE_ORGANIZATION_PATH, RESET_PASSWORD_PATH, INVITE_PATH, LOGIN_PATH, MAIN_PATH, REGISTER_PATH } from './../../common/paths'
import Login from './pages/Login/Login'
import './stylesheets/menuStyles.css'
import './stylesheets/popupStyles.css'
import VerifiedRoute from './components/VerifiedRoute/VerifiedRoute'
import { ScreenSizeProvider, useScreenSize } from './hooks/useScreenSize'
import wrapWithProvider from './utils/wrapWithProvider'
import OrganizationInvite from './pages/OrganizationInvite/OrganizationInvite'
import CreateOrganization from './pages/CreateOrganization/CreateOrganization'
import { Page, PageMessageContents, PopupProvider } from 'react-ui-scaffold'
import { ToastProvider } from './hooks/useToast'
import { MessageQueueProvider } from './hooks/MessageQueue/useMessageQueue'
import Register from './pages/Register/Register'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import DockStateProvider from './Layouts/MainLayout/DockStateProvider'
import axios from './api/axios'
import { ThemeProvider } from 'styled-components'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ProtectedRoute from './components/ProtectedRoute'

function InnerApp() {
	const { screenSize } = useScreenSize()
	const history = useHistory()
	const [toast, setToast] = useState<PageMessageContents>()

	useEffect(() => {
		axios.interceptors.response.use(
			(res: any) => res,
			(err: any) => {
				if (err.response.status == 302 && err.response.data.redirectTo) {
					location.assign(err.response.data.redirectTo)
				}
				throw err
			}
		)
	}, [history])
	
	return (
		<ErrorBoundary>
			<PopupProvider className={screenSize}>
				<ToastProvider toast={toast} setToast={setToast}>
					<Page className={`App ${screenSize}`} message={toast}>
						<Router>
							<AuthProvider>
								<MessageQueueProvider>
									<DockStateProvider>
										<Switch>
											<Route path={LOGIN_PATH} component={Login} />
											<Route path={REGISTER_PATH} component={Register} />
											<Route path={RESET_PASSWORD_PATH} component={ResetPassword} />
											<VerifiedRoute path={CREATE_ORGANIZATION_PATH} component={CreateOrganization} />
											<ProtectedRoute path={INVITE_PATH} component={OrganizationInvite} />
											<VerifiedRoute path={`${MAIN_PATH}/`} component={Main} />
										</Switch>
									</DockStateProvider>
								</MessageQueueProvider>
							</AuthProvider>
						</Router>
					</Page>
				</ToastProvider>
			</PopupProvider>
		</ErrorBoundary>
	)
}

const ScreenThemeProvider = wrapWithProvider((props: any)  => {
	const { screenSize } = useScreenSize()
	return (
		<ScreenSizeProvider>
			<ThemeProvider theme={{ screenSize }}>
				{props.children}
			</ThemeProvider>
		</ScreenSizeProvider>
	)
}, ScreenSizeProvider)

export default wrapWithProvider(InnerApp, ScreenThemeProvider)
