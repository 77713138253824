import styled from 'styled-components'

const Container = styled.div`
	text-align: center;
	margin: auto;
	padding: 0 20px;
	margin-top: 7%;
	max-width: 500px;
`

const Title = styled.h1`
	margin-top: 30px !important;
`

const Subtitle = styled.h3`
	margin-top: 30px !important;
`

export {
	Container,
	Title,
	Subtitle,
}
