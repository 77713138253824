import React from 'react'
import './AuthLayout.css'
import logoPath from '../../assets/logo_full.svg'
import { Page } from 'react-ui-scaffold'

interface Props{
	children:any,
	logo?: boolean,
	loading?: boolean,
	className?: string
}

export default function AuthLayout({ children, logo, loading, className }:Props) {
	return (
		<Page className={`AuthLayout flexX ${className}`} loading={loading}>
			<div className="authLayoutContainer">
				{logo && <div className='logoContainer'>
					<img alt='logo' src={logoPath}/>
				</div>}
				{children}
			</div>
		</Page>
	)
}
