import React from 'react'
import { CreateCustomerParams } from '../../../../common/types/CustomerTypes'
import BaseMenu from '../BaseMenu/BaseMenu'
import AddEditCustomerForm from '../../pages/Main/pages/Customers/components/AddEditCustomerForm/AddEditCustomerForm'
import { useTranslation } from 'react-i18next'

export interface CreateCustomerMenuProps{
	onSubmit(c:CreateCustomerParams): any
	close?: Function
}


export default function CreateCustomerMenu({ onSubmit, close }: CreateCustomerMenuProps) {
	const { t }  = useTranslation()
	const cancelHandler = () => {
		close && close()
	}
	const submitHandler = async (data:any) => {
		return await onSubmit(data)
	}
	
	return (
		<BaseMenu>
			<h1>{t('create_customer')}</h1>
			<AddEditCustomerForm cancelHandler={cancelHandler} submitHandler={submitHandler} />
		</BaseMenu>
	)
}
