import React from 'react'
import { StyledLink } from '..'
import { createTestProfileEditPath } from '../../../../common/paths'
import { TestProfile } from '../../../../common/types/TestProfileTypes'
import BaseHotlinkProps from './IBaseHotlinkProps'

interface Props extends BaseHotlinkProps {
	testProfile?: TestProfile
}
export default function TestProfileHotlink({ testProfile, target }: Props) {
	if (!testProfile) {
		return null
	}
	return <StyledLink to={createTestProfileEditPath(testProfile._id)} target={target}>{testProfile.code}</StyledLink>
}
