import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Test } from '../../../../../../common/types/TestTypes'
import { createTest, getTests } from '../../../../api/tests'
import CreateTestMenu, { CreateTestMenuProps } from '../../../../Menus/CreateTestMenu/CreateTestMenu'
import PanelButton, { PanelButtonRef } from '../../../PanelButton/PanelButton'
import { MultiSelectForm } from '../../selects/MultiSelect'
import { SelectBoxOption } from '../../selects/SelectBox'
import { AddIcon, SelectContainer } from './TestMultiSelect.styled'
import { useTranslation } from 'react-i18next'
import ResourceMutliSelectProps from '../IResourceMultiSelectProps'
import { Row } from '../../FileInput/FileInput.styled'

export default function TestMultiSelect({ hideAdd, defaultValue, onChange, rules }: ResourceMutliSelectProps<Test>) {
	const [values, setValues] = useState<SelectBoxOption[]>([])
	const buttonRef = useRef<PanelButtonRef>(null)
	const { t } = useTranslation()
	const fetchOptions = useCallback(({ searchText, page }) => {
		return getTests({ searchText, page }).then(({ data: { docs, hasMorePages } }) => {
			return {
				newOptions: docs.map((doc) => {
					return {
						label: doc.code,
						value: doc._id,
					}
				}),
				hasMorePages,
			}
		})
	}, [])

	useEffect(() => {
		if (defaultValue) {
			const values = (defaultValue || []).map((val) => ({ label: val.code, value: val._id }))
			setValues(values)
		}
	}, [defaultValue])
	
	const createPanelProps:CreateTestMenuProps = {
		onSubmit: async (newLab) => {
			const res = await createTest(newLab)
			buttonRef.current?.close()
			setValues([...values, { label: res.data.code, value: res.data._id }])
			return res.data
		},
	}

	const onValueChange = (e: Array<SelectBoxOption>) => {
		onChange?.(e)
		setValues(e)
	}
	const append = useMemo(() => {
		if (hideAdd) {
			return undefined
		}
		return (
			<PanelButton
				ref={buttonRef}
				className="button hollow small headerButton"
				panelContent={CreateTestMenu}
				panelProps={createPanelProps}
			>
				<Row>
					<AddIcon icon={faPlus} />
					{t('create_test')}
				</Row>
			</PanelButton>
		)
	}, [hideAdd])

	return (
		<SelectContainer>
			<label htmlFor="Tests" className="label wide">
				<Row>
					{t('tests')} {hideAdd && append}
				</Row>
			</label>
			<MultiSelectForm
				values={values}
				onValuesChange={onValueChange}
				name='testIds'
				fetchOptions={fetchOptions}
				rules={rules}
			/>
		</SelectContainer>
	)
}

