import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
	text-align: center;
	margin: auto;
	padding: 0 20px;
	margin-top: 7%;
	max-width: 500px;
`

const Title = styled.h1`
	margin-top: 30px !important;
`

const Subtitle = styled.h3`
	margin-top: 30px !important;
`

const ButtonRow = styled.div`
	margin-top: 30px !important;
	display: flex;
	justify-content: center;
`

const Check = styled(FontAwesomeIcon)`
	font-size: 64px;
	color: rgb(49, 197, 49);
`

export {
	Container,
	Title,
	Subtitle,
	ButtonRow,
	Check,
}
