import styled from 'styled-components'
import Icon from '../../../../../../components/Icon/Icon'
import SelectBox from '../../../../../../components/inputs/selects/SelectBox'

const Card = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Title = styled.h2`
	font-size: 1.5rem;
	margin-top: 0;
`

const MemberCard = styled.div`
	background-color: #E1E1E1;
	border-radius: 5px;
	font-size: 18px;
	padding: 8px 12px;
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const AccessLevelSelect = styled(SelectBox)`
	width: 200px;
`

const DeleteIcon = styled(Icon)`
	color: #F12222;
	cursor: pointer;
`

const ManageMemberContainer = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

const MemberCardHeader = styled.div`
	display: flex;
	gap: 6px;
`

const MemberTitle = styled.div<{ screenSize: string }>`
	max-width: ${({ screenSize }) => screenSize == 'mobile' ? '170px' : 'auto'};
	overflow: overlay;
`

export {
	AccessLevelSelect,
	Card,
	DeleteIcon,
	ManageMemberContainer,
	MemberCard,
	Title,
	MemberCardHeader,
	MemberTitle,
}
