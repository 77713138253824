import React, { useMemo } from 'react'
import { WidgetContainer, WidgetContent, WidgetFooter, WidgetHeader } from '../Dashboard.styled'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/Table/Table'
import TestProfileHotlink from '../../../../../components/hotlinks/TestProfileHotlink'
import { Link, useHistory } from 'react-router-dom'
import { createOrderEditPath, ORDERS_PATH } from '../../../../../../../common/paths'
import Button from '../../../../../components/Button/Button'
import BasicActions from '../../../../../components/Table/components/BackActions/BasicActions'
import useOrders from '../../../../../data-hooks/Orders/useOrders'
import { ClickToCopy, QuickTableHeaders } from 'react-ui-scaffold'

export default function OrdersWidget() {
	const { t } = useTranslation()
	const { orders } = useOrders()
	const history = useHistory()

	const columns: QuickTableHeaders = useMemo(() => ({
		name: { title: t('name'), width: 200 },
		email: { title: t('email'), width: 400, component: ClickToCopy },
		phone: { title: t('phone'), width: 200, component: ClickToCopy },
		testProfile: { title: t('test_profile'), width: 150, component: ({ row }) => <TestProfileHotlink testProfile={row.testProfile} target='_blank' /> },
		
		actions: { title: '', component: BasicActions, width: 37, props: {
			hideDelete: () => true,
			editHandler: (element: any) => history.push(createOrderEditPath(element._id)),
		} },
	}), [])
	
	const ordersSlice = useMemo(() => orders.reverse().slice(0, 5), [orders])

	return (
		<WidgetContainer>
			<WidgetHeader>{t('orders')}</WidgetHeader>
			<WidgetContent>
				<Table
					columns={columns}
					hook={useOrders}
					dataKey='orders'
					data={ordersSlice}
					disablePagination
					hover={false}
				/>
				<WidgetFooter>
					<Link to={ORDERS_PATH}>
						<Button className={'secondary'}>{t('view_all')}</Button>
					</Link>
				</WidgetFooter>
			</WidgetContent>
		</WidgetContainer>
	)
}
