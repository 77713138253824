import styled from 'styled-components'

const Layout = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`

const WidgetsContainer = styled.div<{ screenSize: string }>`
	width: ${({ screenSize }) => screenSize == 'mobile' ? '100%' : 'calc(50% - 4px)' };
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const WidgetContainer = styled.div`
	border: 1px solid var(--borderColor);
	border-radius: var(--largeRadius);
	background-color: var(--cardBG);
`

const WidgetHeader = styled.div`
	padding: 8px;
	border-bottom: 1px solid var(--borderColor);
	background-color: var(--navColor);
	width: 100%;
	border-top-right-radius: var(--largeRadius);
	border-top-left-radius: var(--largeRadius);
	color: white;
	font-weight: bold;
`

const WidgetContent = styled.div`
	padding: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
`

const WidgetFooter = styled.div`
	display: flex;
	justify-content: flex-end;
`

export {
	Layout,
	WidgetsContainer,
	WidgetContainer,
	WidgetHeader,
	WidgetContent,
	WidgetFooter,
}
