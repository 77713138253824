import React from 'react'
import AddEditAppointmentForm from '../components/AddEditAppointmentForm/AddEditAppointmentForm'
import { useHistory } from 'react-router'
import { APPOINTMENTS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import useAppointments from '../../../../../data-hooks/Appointments/useAppointments'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function AddAppointment() {
	const { create } = useAppointments()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(APPOINTMENTS_PATH)
		return res.data
	}
	
	return (<>
		<Header>
			{t('add_appointment')}
		</Header>
		<LargeFormLayout>
			<AddEditAppointmentForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
			/>
		</LargeFormLayout>
	</>)
}
