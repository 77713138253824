import React from 'react'
import { useHistory } from 'react-router'
import useSingleOrder from '../../../../../data-hooks/Orders/useSingleOrder'
import AddEditOrderForm from '../components/AddEditOrderForm/AddEditOrderForm'
import OrderView from '../components/OrderView/OrderView'

export default function EditOrder() {
	const history = useHistory()
	const { update, order, setOrder } = useSingleOrder()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setOrder(res.data)
		return res.data
	}
	
	return (
		<OrderView>
			<AddEditOrderForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				order={order}
			/>
		</OrderView>
	)
}
