import { Appointment } from '../../../../../../../../common/types/AppointmentTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import moment from 'moment'
import CustomerSelect from '../../../../../../components/inputs/resourceSelects/CustomerSelect/CustomerSelect'
import TestProfileSelect from '../../../../../../components/inputs/resourceSelects/TestProfileSelect/TestProfileSelect'
import LabSelect from '../../../../../../components/inputs/resourceSelects/LabSelect/LabSelect'
import { useTranslation } from 'react-i18next'
import { DatePickerForm } from '../../../../../../components/inputs/DatePicker/DatePicker'
import { TimePickerForm } from '../../../../../../components/inputs/TimePicker/TimePicker'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { createAppointmentEditPath } from '../../../../../../../../common/paths'
import { Link } from 'react-router-dom'
import { SelectBoxForm, SelectBoxOption } from '../../../../../../components/inputs/selects/SelectBox'
import { positive } from '../../../../../../utils/rules'
import TestMultiSelect from '../../../../../../components/inputs/resourceSelects/TestMultiSelect/TestMultiSelect'
import OrganizationMemberSelect from '../../../../../../components/inputs/resourceSelects/OrganizationMemberSelect/OrganizationMemberSelect'
import useOrganization from '../../../../../../data-hooks/useOrganization'

interface AppointmentForm {
	price: string,
	customer: null | SelectBoxOption
	testProfile: null | SelectBoxOption,
	testIds: null | Array<SelectBoxOption>,
	lab: null | SelectBoxOption,
	date: string,
	time: string,
	organizationMember: null | SelectBoxOption,
	where: null | SelectBoxOption,
	type: null | SelectBoxOption,
	location?: string,
}

interface Props{
	appointment?: Partial<Appointment>,
	defaultCustomerSearch?: string,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void
}


export default function AddEditAppointmentForm({
	appointment,
	defaultCustomerSearch,
	submitHandler,
	cancelHandler,
}:Props) {
	const { organization: { members } } = useOrganization()
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	const onPlace = { label: t('on_site'), value: 'on_site' }
	const mobileOption = { label: t('mobile'), value: 'mobile' }
	const whereOptions: Array<SelectBoxOption> = [
		onPlace,
		mobileOption,
	]

	const testProfileOption = { label: t('test_profile'), value: 'test_profile' }
	const testsOption = { label: t('tests'), value: 'tests' }
	const typeOptions: Array<SelectBoxOption> = [
		testProfileOption,
		testsOption,
	]
	
	const form = useForm<AppointmentForm>({
		defaultValues: {
			price: '',
			customer: null,
			testProfile: null,
			lab: null,
			where: onPlace,
			type: testProfileOption,
		},
	})
	const { handleSubmit, register, formState: { errors }, setValue, getValues, watch, unregister, reset } = form

	useEffect(() => {
		if (appointment) {
			setValue('price', (appointment?.price || 0).toString())
			if (appointment.date) {
				const momented = moment(appointment.date)
				setValue('date', momented.format('YYYY-MM-DD'))
				setValue('time', momented.format('HH:mm'))
			}
			if (appointment.customer) {
				setValue('customer', { label: appointment.customer.name, value: appointment.customer._id })
			}
			if (appointment.lab) {
				setValue('lab', { label: appointment.lab.name, value: appointment.lab._id })
			}
			if (appointment.organizationMember) {
				const organizationMember = members.find((member) => member._id == appointment.organizationMember)
				if (organizationMember) {
					setValue('organizationMember', {
						label: `${organizationMember?.user.firstName} ${organizationMember?.user.lastName}`,
						value: organizationMember._id,
					})
				}
			}
			if (appointment.location) {
				setValue('where', mobileOption)
				setValue('location', appointment.location)
			}
			if (appointment.testProfile) {
				setValue('type', testProfileOption)
				setValue('testProfile', { label: appointment.testProfile.code, value: appointment.testProfile._id })
			} else {
				setValue('type', testsOption)
				setValue('testIds', appointment.tests?.map((val) => ({ label: val.code, value: val._id })) as any)
			}
		}
	}, [appointment])

	useEffect(() => {
		if (watch('type.value') == 'tests') {
			unregister('testProfile')
			setValue('testProfile', null)
		} else {
			unregister('testIds')
			setValue('testIds', null)
		}
	}, [watch('type.value')])

	const onTestProfileChange = (e: any) => {
		if (!getValues('price')) {
			setValue('price', (e.price / 100).toString())
		}
	}

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		delete e.where
		delete e.type
		const body = {
			...e,
			customer: e.customer.value,
			testProfile: e.testProfile?.value,
			lab: e.lab?.value,
			organizationMember: e.organizationMember?.value,
			testIds: (e.testIds || []).map((test: any) => test.value),
		}
		submitHandler(body)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(appointment?._id ? 'appointment_updated' : 'appointment_created'),
					content: (
						<Link target='_blank' to={createAppointmentEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if (appointment?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(appointment?._id ? 'appointment_update_failed' : 'appointment_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
			>
				<CustomerSelect defaultValue={appointment?.customer} rules={{ required: true }} defaultSearch={defaultCustomerSearch} clearable />
				<SelectBoxForm
					{...register('where', {
						required: true,
					})}
					onValueChange={() => {
						setValue('location', '')
					}}
					value={null}
					options={whereOptions}
					label={t('where') as string}
					error={errors.where}
				/>
				{watch('where.value') == 'mobile' && (
					<TextBox
						wide
						placeholder={t('location') as string}
						label={t('location') as string}
						error={errors.location}
						{...register('location')}
					/>
				)}
				<SelectBoxForm
					{...register('type', {
						required: true,
					})}
					onValueChange={() => {}}
					value={null}
					options={typeOptions}
					label={t('type') as string}
					error={errors.type}
				/>
				{watch('type.value') == 'test_profile'
					? (
						<TestProfileSelect defaultValue={appointment?.testProfile} onChange={onTestProfileChange} rules={{ required: true }} clearable />
					)
					: (
						<TestMultiSelect defaultValue={appointment?.tests} rules={{ array_required: true }} />
					)
				}
				<TextBox
					wide
					placeholder={t('price') as string}
					label={t('price') as string}
					error={errors.price}
					type='number'
					{...register('price', {
						required: true,
						validate: {
							positive,
						},
					})}
				/>
				<LabSelect defaultValue={appointment?.lab} clearable />
				<OrganizationMemberSelect defaultValue={appointment?.organizationMember} clearable />
				<DatePickerForm name='date' label={t('date') as string} />
				<TimePickerForm name='time' label={t('time') as string} />
			</Form>
		</FormProvider>
	)
}
