import styled from 'styled-components'

const SunEditorWrapper = styled.div`
	.sun-editor-editable {
		font-size: 22px;
		min-height: 105px !important;
	}
`

export {
	SunEditorWrapper,
}
