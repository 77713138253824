import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ChipContainer = styled.div`
	display: flex;
	width: calc(100% - 40px);
	flex-wrap: wrap;
	align-items: center;
	gap: 4px;
`

const Chip = styled.div`
	padding: 2px 8px;
	background-color: #D9D9D9;
	border-radius: 4px;
	font-size: 14px;
	display: flex;
	gap: 4px;
	flex-wrap: nowrap;
	align-items: center;
`

const ChipRemoveButton = styled(FontAwesomeIcon)`
	color: #555555;
	cursor: pointer;
`

const OptionsContainer = styled.div`
	max-height: 200px;
	overflow-y: auto;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, .5);
		box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}
`

const LoadingContainer = styled.div`
	height: 40px;
	display: flex;
	gap: 6px;
	align-items: center;
	padding-left: 10px;
`

export {
	Chip,
	ChipContainer,
	ChipRemoveButton,
	LoadingContainer,
	OptionsContainer,
}
