import styled from 'styled-components'

const TabContainer = styled.div`
	padding: 16px;
	border: 1px solid var(--borderColor);
`

export {
	TabContainer,
}
