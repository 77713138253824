import React, { useState } from 'react'
import { Content } from '../../../../../../components'
import { useTranslation } from 'react-i18next'
import useOrganization from '../../../../../../data-hooks/useOrganization'
import useOrganizationInvites from '../../../../../../data-hooks/useOrganizationInvites'
import { Card } from './OrganizationMembers.styled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ORGANIZATION_MEMBERS_ADD_PATH } from '../../../../../../../../common/paths'
import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import Icon from '../../../../../../components/Icon/Icon'
import Button from '../../../../../../components/Button/Button'
import Members from './Members'

export default function OrganizationMembers() {
	const { organization: { members }, updateMember, removeMember } = useOrganization()
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const { update: updateInvite, remove: removeInvite } = useOrganizationInvites()
	const { t } = useTranslation()
	const [toRevoke, setToRevoke] = useState<any>()
	const [toRemove, setToRemove] = useState<any>()
	const [loadingMap, setLoadingMap] = useState<any>({})

	const onAccessLevelChange = async (memberId: string, accessLevel: string) => {
		setLoadingMap({ ...loadingMap, [memberId]: true })
		updateMember({ accessLevel, memberId })
			.then((res: any) => {
				addSuccessMesasage({
					title: t('access_level_updated'),
				})
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t('failed_to_update_access_level'),
				})
			})
			.finally(() => {
				setLoadingMap({ ...loadingMap, [memberId]: false })
			})
	}

	const onDeleteMember = (id: string) => {
		setLoadingMap({ ...loadingMap, [id]: true })
		removeMember(id)
			.then((res: any) => {
				addSuccessMesasage({
					title: t('removed_member'),
				})
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t('failed_to_remove_member'),
				})
			})
			.finally(() => {
				setLoadingMap({ ...loadingMap, [id]: false })
			})
	}

	const onRevokeInvite = (id: string) => {
		setLoadingMap({ ...loadingMap, [id]: true })
		removeInvite(id)
			.then((res: any) => {
				addSuccessMesasage({
					title: t('revoked_invite'),
				})
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t('failed_to_revoke_invite'),
				})
			})
			.finally(() => {
				setLoadingMap({ ...loadingMap, [id]: false })
			})
	}

	const onInviteAccessLevelChange = async (inviteId: string, accessLevel: string) => {
		setLoadingMap({ ...loadingMap, [inviteId]: true })
		return updateInvite({ accessLevel, inviteId })
			.then((res: any) => {
				addSuccessMesasage({
					title: t('access_level_updated'),
				})
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t('failed_to_update_access_level'),
				})
			})
			.finally(() => {
				setLoadingMap({ ...loadingMap, [inviteId]: false })
			})
	}

	return (
		<>
			<Content>
				<h2 className='flexY' style={{ gap: 8 }}>
					{t('members')}
					<Link to={ORGANIZATION_MEMBERS_ADD_PATH}>
						<Button className='primary small button hollow'>
							<Icon icon={faPlus} />
							{t('add')}
						</Button>
					</Link>
				</h2>
				<Card>
					<Members
						members={members}
						onAccessLevelChange={onAccessLevelChange}
						onInviteAccessLevelChange={onInviteAccessLevelChange}
					/>
				</Card>
				
				<ConfirmPopup
					title={t('revoke_invite', { email: toRemove?.name })}
					confirmText={t('revoke') as string}
					confirmClassName='negative'
					onConfirm={() => onRevokeInvite(toRevoke?._id as string)}
					setVisible={() => setToRevoke(undefined)}
					visible={Boolean(toRevoke)}
				/>
				<ConfirmPopup
					title={t('remove_member', { name: `${toRemove?.user.firstName} ${toRemove?.user.lastName}` })}
					confirmText={t('remove') as string}
					confirmClassName='negative'
					onConfirm={() => onDeleteMember(toRemove?._id as string)}
					setVisible={() => setToRemove(undefined)}
					visible={Boolean(toRemove)}
				/>
			</Content>
		</>
	)
}
