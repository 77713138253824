import React from 'react'
import { Route, Switch } from 'react-router'
import { CustomersProvider } from '../../../../data-hooks/Customers/useCustomers'
import {  CUSTOMERS_PATH, CUSTOMER_ADD_PATH, CUSTOMER_EDIT_PATH, CUSTOMER_PATH, CUSTOMER_TO_APPOINTMENT_PATH } from '../../../../../../common/paths'
import AddCustomer from './AddCustomer/AddCustomer'
import Customers from './Customers/Customers'
import { SingleCustomerProvider } from '../../../../data-hooks/Customers/useSingleCustomer'
import EditCustomer from './EditCustomer/EditCustomer'
import ViewCustomer from './ViewCustomer/ViewCustomer'
import AppointmentFromCustomer from './AppointmentFromCustomer/AppointmentFromCustomer'

export default function CustomersRoutes() {

	return (
		<CustomersProvider>
			<SingleCustomerProvider>
				<Switch>
					<Route exact path={CUSTOMER_ADD_PATH} component={AddCustomer}/>
					<Route path={CUSTOMER_PATH} component={ViewCustomer}/>
					<Route path={CUSTOMER_EDIT_PATH} component={EditCustomer}/>
					<Route exact path={CUSTOMERS_PATH} component={Customers}/>
					<Route exact path={CUSTOMER_TO_APPOINTMENT_PATH} component={AppointmentFromCustomer}/>
				</Switch>
			</SingleCustomerProvider>
		</CustomersProvider>
	)
}
