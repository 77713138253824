import styled from 'styled-components'


const ResponsiveHeader = styled.h1<{ screenSize: string }>`
	padding: 10px;
	display: flex;
	gap: var(--smallSpacing) var(--mediumSpacing);
	align-items: flex-start;
	flex-wrap: wrap;
	font-size: ${({ screenSize }) => screenSize == 'mobile' ? '1.5rem' : '1.8rem'} !important;
	background-color: var(--headerBG);
	border-bottom: 1px solid var(--borderColor);
	align-items: center;
	
	&.noBorder {
		border-bottom: none;
	}
`

const HamburgerButton = styled.button`
	vertical-align: middle;
`

export {
	ResponsiveHeader,
	HamburgerButton,
}
