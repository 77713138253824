import React from 'react'
import useSingleTest from '../../../../../data-hooks/Tests/useSingleTest'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import TestView from '../components/TestView/TestView'
import TextArea from '../../../../../components/inputs/TextArea'
import LabHotlink from '../../../../../components/hotlinks/LabHotlink'
import Wysiwyg from '../../../../../components/inputs/Wysiwyg/Wysiwyg'
import { centToDollar } from '../../../../../utils/dollarUtils'

export default function ViewTest() {
	const { test } = useSingleTest()
	const { t } = useTranslation()
	
	return (
		<TestView>
			<ViewContainer>
				<div>
					<ViewLabel>{t('code')}</ViewLabel>
					<div>{test?.code}</div>
				</div>
				<div>
					<ViewLabel>{t('note')}</ViewLabel>
					<TextArea value={test?.note} wide readOnly />
				</div>
				<div>
					<ViewLabel>{t('sample_and_tube')}</ViewLabel>
					<div>{test?.specimenType}</div>
				</div>
				<div>
					<ViewLabel>{t('temp')}</ViewLabel>
					<div>{test?.temp}</div>
				</div>
				<div>
					<ViewLabel>{t('collectionRequirements')}</ViewLabel>
					<Wysiwyg
						name='collectionRequirements'
						defaultValue={test?.collectionRequirements}
						wide
						readOnly
					/>
				</div>
				<div>
					<ViewLabel>{t('turnaround_time')}</ViewLabel>
					<div>{test?.turnaroundTime}</div>
				</div>
				<div>
					<ViewLabel>{t('price')}</ViewLabel>
					<div>{test?.price ? `$${centToDollar(test.price)}` : '-'}</div>
				</div>
				<div>
					<ViewLabel>{t('lab')}</ViewLabel>
					<div>
						<LabHotlink lab={test?.labId} target='_blank' />
					</div>
				</div>
			</ViewContainer>
		</TestView>
	)
}
