import { Order } from '../../../common/types/OrderTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'

export const getOrders = ({ searchText, type, category, page }:{
	type?: string,
	searchText?: string
	category?: string
	page?: number
}) => axios.get<PaginateResponse<Order[]>>(`${urls.organization}/orders${formatQueryString({ q: searchText, type, category, page })}`)

export const createOrder = (params: any) => axios.post<Order>(`${urls.organization}/orders`, params)

export const getSingleOrder = (orderId: string, populate: string[]) => axios.get<Order>(`${urls.organization}/orders/${orderId}${formatQueryString({ populate })}`)

export const deleteOrder = (orderId: string) => axios.delete<Order>(`${urls.organization}/orders/${orderId}`)

export const updateOrder = (params: any, orderId: string) => axios.patch<Order>(`${urls.organization}/orders/${orderId}`, params)
