import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { createLabEditPath, LAB_ADD_PATH } from '../../../../../../../common/paths'
import { Lab } from '../../../../../../../common/types/LabTypes'
import { Content, Header } from '../../../../../components'
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import TextBox from '../../../../../components/inputs/TextBox'
import BasicActions from '../../../../../components/Table/components/BackActions/BasicActions'
import Table from '../../../../../components/Table/Table'
import useLabs from '../../../../../data-hooks/Labs/useLabs'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import { QuickTableHeaders } from 'react-ui-scaffold'
import Button from '../../../../../components/Button/Button'
import Spacer from '../../../../../components/Spacer/Spacer'
import { LabsTableWrapper } from './Labs.styled'


export default function Labs () {
	const { del, searchText, setSearchText } = useLabs()
	const [toDelete, setToDelete] = useState<Lab | undefined>()
	const history = useHistory()
	const { t } = useTranslation()
	
	const columns: QuickTableHeaders = useMemo(() => ({
		shortId: { title: t('id'), width: 50 },
		name: { title: t('name'), width: 230 },
		
		actions: { title: '', component: BasicActions, width: 70, props: {
			deleteHandler: (element:any) => setToDelete(element),
			editHandler: (element: any) => history.push(createLabEditPath(element._id)),
		} },
	}), [])

	const searchHandler = (e:any) => {
		setSearchText(e.target.value)
	}

	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}
	return (<div>
		<Header>
			{t('labs')}
			<Link to={LAB_ADD_PATH}>
				<Button className='primary small'>
					<Icon icon={faPlus} />
					{t('add')}
				</Button>
			</Link>
			<Spacer />
		
			<TextBox
				placeholder={t('search') as string}
				wide
				onChange={searchHandler}
				value={searchText}
			/>
		</Header>
		<Content>
			<LabsTableWrapper>
				<Table
					columns={columns}
					hook={useLabs}
					dataKey='labs'
				/>
			</LabsTableWrapper>
			<ConfirmPopup
				title={t('delete_entity', { entity: toDelete?.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
		</Content>
	</div>)
}
