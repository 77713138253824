import React, { useEffect, useState } from 'react'
import { Page } from 'react-ui-scaffold'
import { activateSubscription } from '../../../../api/subscriptions'
import { ButtonRow, Check, Container, Subtitle, Title } from './SubscriptionCheckout.styled'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/Button'
import { Header } from '../../../../components'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { DASHBOARD_PATH } from '../../../../../../common/paths'
const { APP_NAME = 'Phlevo' } = process.env

export default function SubscriptionCheckoutSuccess() {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(true)
	
	useEffect(() => {
		activateSubscription()
			.then(() => setIsLoading(false))
	}, [])
	
	return (
		<Page loading={isLoading}>
			<Header />
			<Container>
				<Check icon={faCheckCircle} />
				<Title>{t('thank_you_for_subscribing')}</Title>
				<Subtitle>{t('you_have_successfully_subscribed', { app: APP_NAME })}</Subtitle>
				<ButtonRow>
					<Link to={DASHBOARD_PATH}>
						<Button className='primary'>{t('back_to_app', { app: APP_NAME })}</Button>
					</Link>
				</ButtonRow>
			</Container>
		</Page>
	)
}
