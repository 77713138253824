import { Lab, UpdateLabParams } from '../../../../common/types/LabTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { getSingleLab, updateLab } from '../../api/labs'
import useLabs from './useLabs'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	lab?: Lab,
	labId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateLabParams, 'labId'>): Promise<AxiosResponse<Lab>>
	setLab: (lab: Lab) => void
	setLabId: (lab: string) => void
}>(null as any)


interface ProviderProps{
	children:any
}

export const SingleLabProvider = ({ children }: ProviderProps) => {
	const [labId, setLabId] = useState<string>()
	const [lab, setLab] = useState<Lab>()
	const [isLoading, setIsLoading] = useState(false)
	const { replace } = useLabs()
	
	useEffect(() => {
		if (labId) {
			setIsLoading(true)
			
			getSingleLab(labId)
				.then(({ data }) => setLab(data))
				.finally(() => setIsLoading(false))
		}
	}, [labId])
	
	const update = useCallback(async (params: Omit<UpdateLabParams, 'labId'>) => {
		const newLab = await updateLab({
			...params,
			labId: lab?._id as string,
		})
		
		replace(newLab.data)
		getSingleLab(lab?._id as string).then(({ data }) => setLab(data))
		
		return newLab
	},[lab?._id])

	return <Context.Provider value={{
		lab,
		labId,
		isLoading,
		update,
		setLab,
		setLabId,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleLab = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleLab outside provider!')
	}
	return val
}

export default useSingleLab
