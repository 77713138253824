import { faSyringe } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useHistory } from 'react-router'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { createOrderEditPath, createOrderToAppointmentStep1Path } from '../../../../../../../../common/paths'
import BasicActions from '../../../../../../components/Table/components/BackActions/BasicActions'
import { ActionIcon } from '../../../../../../components/Table/components/BackActions/BasicActions.styled'
import { Row } from '../Order.styled'

export default function OrderActions({ row, deleteHandler }:QuickTableCellProps) {
	const history = useHistory()
	
	return (
		<Row>
			<ActionIcon icon={faSyringe} onClick={() => history.push(createOrderToAppointmentStep1Path(row._id))} tooltip='convert_to_appointment' />
			<BasicActions
				deleteHandler={() => deleteHandler(row)}
				editHandler={() => { history.push(createOrderEditPath(row._id)) }}
			/>
		</Row>
	)
}
