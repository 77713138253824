import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ButtonContent, ButtonRow, FilterForm } from './TestProfilesFilters.styled'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../components/Button/Button'
import Form from '../../../../../../../Form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Loading } from 'react-ui-scaffold'
import TextBox from '../../../../../../../components/inputs/TextBox'
import TestMultiSelect from '../../../../../../../components/inputs/resourceSelects/TestMultiSelect/TestMultiSelect'
import useTestProfiles, { TestProfileSearchParmas } from '../../../../../../../data-hooks/TestProfiles/useTestProfiles'

export default function TestProfilesFilters() {
	const { setSearchParams, isLoading } = useTestProfiles()
	const { t } = useTranslation()
	const form = useForm<TestProfileSearchParmas>()
	const { handleSubmit, reset, register } = form
	const [collapsed, setCollapsed] = useState<Boolean>(true)

	const submitHandler = (data: any) => {
		data.testIds = data.testIds.map(({ value }: any) => value)
		setSearchParams(data)
	}
	
	return (
		<div>
			<Button onClick={() => setCollapsed(!collapsed)} className='secondary wide'>
				<ButtonContent>
					{isLoading && <Loading className='spinner' />}
					Search
					<FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp}/>
				</ButtonContent>
			</Button>
			<FormProvider {...form}>
				<FilterForm collapsed={collapsed}>
					<Form onSubmit={handleSubmit(submitHandler)}>
						<TextBox
							placeholder={t('search') as string}
							wide
							{...register('searchText')}
						/>
						<TestMultiSelect hideAdd/>
						
						<ButtonRow>
							<Button className='button negative' onClick={() => reset()}>{t('reset')}</Button>
							<Button className={'positive'} type='submit'>{t('search') as string}</Button>
						</ButtonRow>
					</Form>
				</FilterForm>
			</FormProvider>
		</div>
	)
}
