import styled from 'styled-components'

const Row = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`

const HiddenFileInput = styled.input`
	display: none;
`

export {
	Row,
	HiddenFileInput,
}
