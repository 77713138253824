import { OrganizationInvite } from '../../../common/types/OrganizationTypes'
import React, { useContext, createContext, useEffect, useState } from 'react'
import { getSingleOrganizationInvite, respondToOrganizationInvite } from '../api/organizations'
import useOrganization from './useOrganization'

const Context = createContext<{
	invite?: OrganizationInvite,
	error?: string,
	isLoading: boolean,
	accept():Promise<any>,
	reject():Promise<void>,
}>(null as any)


interface ProviderProps{
	children:any,
	inviteId: string
}

export const SingleOrganizationInviteProvider = ({ children, inviteId }: ProviderProps) => {
	const [invite, setInvite] = useState<OrganizationInvite>()
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	
	useEffect(() => {
		getSingleOrganizationInvite(inviteId).then(v => {
			setInvite(v.data)
		}).catch((err:any) => {
			setError(err.error)
		}).finally(() => {
			setIsLoading(false)
		})
	}, [inviteId])
	
	const accept = async () => {
		return await respondToOrganizationInvite({
			inviteId,
			accepted: true,
		})
	}
	
	const reject = async () => {
		await respondToOrganizationInvite({
			inviteId,
			accepted: false,
		})
	}

	return <Context.Provider value={{
		invite,
		error,
		isLoading,
		accept,
		reject,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleOrganizationInvite = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleOrganizationInvite outside provider!')
	}
	return val
}

export default useSingleOrganizationInvite
