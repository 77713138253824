import React from 'react'
import { Route, Switch } from 'react-router'
import { TestsProvider } from '../../../../data-hooks/Tests/useTests'
import { TESTS_PATH, TEST_ADD_PATH, TEST_EDIT_PATH, TEST_PATH } from '../../../../../../common/paths'
import AddTest from './AddTest/AddTest'
import Tests from './Tests/Tests'
import { SingleTestProvider } from '../../../../data-hooks/Tests/useSingleTest'
import EditTest from './EditTest/EditTest'
import ViewTest from './ViewTest/ViewTest'
import { PopupProvider } from 'react-ui-scaffold'

export default function TestsRoutes() {

	return (
		<PopupProvider>
			<TestsProvider>
				<SingleTestProvider>
					<Switch>
						<Route exact path={TEST_ADD_PATH} component={AddTest}/>
						<Route path={TEST_PATH} component={ViewTest}/>
						<Route path={TEST_EDIT_PATH} component={EditTest}/>
						<Route exact path={TESTS_PATH} component={Tests}/>
					</Switch>
				</SingleTestProvider>
			</TestsProvider>
		</PopupProvider>
	)
}
