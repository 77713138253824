import React from 'react'
import { StyledLink } from '..'
import { createTestPath } from '../../../../common/paths'
import { Test } from '../../../../common/types/TestTypes'
import BaseHotlinkProps from './IBaseHotlinkProps'

interface Props extends BaseHotlinkProps {
	test?: Test
}
export default function TestHotlink({ test, target }: Props) {
	if (!test) {
		return null
	}
	return <StyledLink to={createTestPath(test._id)} target={target}>{test.code}</StyledLink>
}
