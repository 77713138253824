import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ClickToCopy, QuickTableHeaders } from 'react-ui-scaffold'
import { createAppointmentEditPath } from '../../../../../../../../../common/paths'
import { Appointment } from '../../../../../../../../../common/types/AppointmentTypes'
import { PipelineStep } from '../../../../../../../../../common/types/PipelineStepTypes'
import ConfirmPopup from '../../../../../../../components/ConfirmPopup/ConfirmPopup'
import BasicActions from '../../../../../../../components/Table/components/BackActions/BasicActions'
import Table from '../../../../../../../components/Table/Table'
import useAppointments from '../../../../../../../data-hooks/Appointments/useAppointments'

export default function AppointmentTable() {
	const { del, appointmentPipeline } = useAppointments()
	const { t } = useTranslation()
	const history = useHistory()
	const [toDelete, setToDelete] = useState<Appointment | undefined>()

	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}

	const stepMap = useMemo(() => {
		const map: { [key: string]: PipelineStep } = {}
		if (!appointmentPipeline) {
			return map
		}
		(appointmentPipeline.steps || []).map((step) => {
			map[step._id] = step
		})
		return map
	}, [appointmentPipeline])

	const columns: QuickTableHeaders = useMemo(() => ({
		shortId: { title: t('id'), width: 40 },
		name: { title: t('name'), width: 200, component: ({ row }) => <>{row.customer.name}</> },
		email: { title: t('email'), width: 400, component: ({ row, ...props }) => <ClickToCopy row={row}  {...props} cell={row.customer.email as string} /> },
		phone: { title: t('phone'), width: 400, component: ({ row, ...props }) => <ClickToCopy row={row} {...props} cell={row.customer.phoneNo as string} />  },
		status: { title: t('status'), component: ({ row }) => <>{stepMap[row.pipelineStep]?.name}</> },
		
		actions: { title: '', component: BasicActions, width: 74, props: {
			deleteHandler: (element: any) => setToDelete(element),
			editHandler: (element: any) => history.push(createAppointmentEditPath(element._id)),
			
		} },
	}), [t, appointmentPipeline])

	return (
		<>
			<Table
				columns={columns}
				hook={useAppointments}
				dataKey="appointments"
			/>

			<ConfirmPopup
				title={t('delete_appointment', { name: toDelete?.customer.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
		</>
	)
}
