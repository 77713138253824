import styled from 'styled-components'

const FilterForm = styled.div<{ collapsed: Boolean, screenSize: string }>`
	border-top: 0;
	${({ collapsed }) => {
		if (!collapsed) {
			return `
				padding: 8px;
				border: 1px solid var(--borderColor);
			`
		} else {
			return `
				.Form {
					margin-bottom: 0px;
					.errorMessage {
						display: none;
					}
				}
			`
		}
	}}
	position: absolute;
	z-index: 5;
	background-color: var(--buttonBG);
	width: 300px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-top: -2px;
	.formFields {
		max-height: ${({ collapsed }) => collapsed ? 0 : '540px'};
		transition: max-height 0.15s ease-out;
		.fields {
			grid-template-columns: ${({ screenSize }) => screenSize == 'mobile' ? '1fr' : '1fr 1fr'};
			display: grid;
			gap: 8px;
			> * {
				overflow: hidden
			}
		}
		overflow: hidden;
		overflow-y: auto;
	}
`

const ButtonContent = styled.div`
	display: flex;
	gap: 6px;
	width: 100%;
	justify-content: center;
	align-items: center;
	width: 276px;
`

const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 6px;
`

export {
	FilterForm,
	ButtonContent,
	ButtonRow,
}
