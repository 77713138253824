import React from 'react'
import Button, { AsyncButton } from '../components/Button/Button'
import './Form.css'
import { ButtonRow, Container } from './Form.styled'
import { useTranslation } from 'react-i18next'

interface Props{
	children: any,
	error?:any,
	onSubmit?(e:any):any,
	showButtons?: boolean,
	showCancel?: boolean,
	loading?: boolean,
	cancelHandler?: Function,
}

export default function Form({ children, error, onSubmit, showButtons, showCancel, cancelHandler, loading }:Props) {
	const submitHandler = (e:any) => {
		e.stopPropagation()
		e.preventDefault()
		onSubmit && onSubmit(e)
	}
	const { t } = useTranslation()
	
	return (
		<div className='Form'>
			<div className="errorMessage">
				{error}
			</div>
			
			<form onSubmit={submitHandler}>
				<Container className='formFields'>
					{children}
					{showButtons && (
						<ButtonRow>
							{!showCancel && <Button className={'negative'} type='reset' onClick={(e: any) => cancelHandler && cancelHandler(e)}>{t('cancel')}</Button>}
							<AsyncButton
								className={'positive'}
								type='submit'
								loading={loading}
							>
								{t('save')}
							</AsyncButton>
						</ButtonRow>
					)}
				</Container>
			</form>
		</div>
	)
}
