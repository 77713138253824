import { Appointment, CreateAppointmentParams, UpdateAppointmentStepParams } from '../../../../common/types/AppointmentTypes'
import React, { useContext, createContext, useEffect, useState, useCallback, useRef } from 'react'
import { createAppointment, deleteAppointment, getAppointments, updateAppointmentStep } from '../../api/appointments'
import useOrganization from '../useOrganization'
import { getActiveAppointmentPipeline } from '../../api/appointmentPipelines'
import { AppointmentPipelineWithSteps } from '../../../../common/types/AppointmentPipelineTypes'
import { SelectBoxOption } from '../../components/inputs/selects/SelectBox'
import { AxiosResponse } from 'axios'

export interface AppointmentFilters {
	customer?: null | SelectBoxOption,
	testProfile?: null | SelectBoxOption,
	lab?: null | SelectBoxOption,
	startDate?: string,
	endDate?: string,
	organizationMember?: null | SelectBoxOption,
	shortId?: string
}

const Context = createContext<{
	appointments: Appointment[],
	isLoading: boolean,
	create(c:CreateAppointmentParams):Promise<AxiosResponse<Appointment>>,
	filters: AppointmentFilters,
	setFilters(s:AppointmentFilters): void,
	del(appointmentId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	appointmentPipeline?: AppointmentPipelineWithSteps,
	updateStep(u:UpdateAppointmentStepParams):Promise<AxiosResponse<Appointment>>,
	replace(appointment: Appointment): void,
}>(null as any)


interface ProviderProps{
	defaultFilters?: AppointmentFilters,
	populate?: string[],
	children:any
}

export const AppointmentsProvider = ({ defaultFilters, children, populate = ['customer', 'testProfile', 'tests'] }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [appointments, setAppointments] = useState<Appointment[]>([])
	const [appointmentPipeline, setAppointmentPipeline] = useState<AppointmentPipelineWithSteps>()
	
	const [filters, setTrueFilters] = useState<AppointmentFilters>(defaultFilters || {})
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	const debounce = useRef<any>()

	const getEntries = async (filters?: AppointmentFilters) => {
		setAppointments([])
		const searchObj: any = {}
		if (filters) {
			if (filters.shortId) {
				searchObj.shortId = filters.shortId
			}
			if (filters.customer) {
				searchObj.customer = filters.customer.value
			}
			if (filters.organizationMember) {
				searchObj.organizationMember = filters.organizationMember.value
			}
			if (filters.lab) {
				searchObj.lab = filters.lab.value
			}
			if (filters.testProfile) {
				searchObj.testProfile = filters.testProfile.value
			}
			if (filters.startDate) {
				searchObj.startDate = filters.startDate
			}
			if (filters.endDate) {
				searchObj.endDate = filters.endDate
			}
		}
		getAppointments({ filters: searchObj, page, populate })
			.then(({ data: { docs, hasMorePages } }) => {
				setAppointments(docs)
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}
	
	const setFilters = (filters:AppointmentFilters) => {
		setTrueFilters(filters)
		clearTimeout(debounce.current)
		
		debounce.current = setTimeout(() => {
			getEntries(filters)
		}, 300)
	}
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		Promise.all([
			getEntries(),
			getActiveAppointmentPipeline()
				.then(({ data }) => setAppointmentPipeline(data)),
		]).finally(() => setIsLoading(false))
	}, [organization._id])
	
	const create = useCallback(async (params:CreateAppointmentParams) => {
		const newAppointment = await createAppointment(params)
		setAppointments([...appointments, newAppointment.data])
		return newAppointment
	}, [organization._id])
	
	const del = useCallback(async (appointmentId: string) => {
		await deleteAppointment(appointmentId)
		setAppointments(curr => curr.filter(c => c._id !== appointmentId))
	}, [])

	const updateStep = useCallback(async (params: UpdateAppointmentStepParams) => {
		const updated = await updateAppointmentStep(params)
		const index = appointments.findIndex((appointment) => {
			return appointment._id == params.appointmentId
		})
		const newAppointments: Array<Appointment> = [...appointments]
		newAppointments[index].pipelineStep = updated.data.pipelineStep
		setAppointments(newAppointments)
		return updated
	}, [appointments])

	const replace = useCallback((appointment: Appointment) => {
		const index = appointments.findIndex(({ _id }) => _id == appointment._id)
		let newAppointments = [...appointments]
		if (index != -1) {
			newAppointments[index] = appointment
			setAppointments(newAppointments)
		}
	}, [appointments])

	return <Context.Provider value={{
		appointments,
		isLoading,
		create,
		del,
		page,
		setPage,
		hasMorePages,
		appointmentPipeline,
		updateStep,
		filters,
		setFilters,
		replace,
	}}>
		{ children }
	</Context.Provider>
}

const useAppointments = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useAppointments outside provider!')
	}
	return val
}

export default useAppointments
