import { CionAccount, CreateAccountParams, ResetPasswordParams, UpdateAccountParams } from '../../../common/types/UserTypes'
import axios from './axios'

export const getCurrentAccount = async () => axios.get<CionAccount>('/api/account')

export const updateAccount = async (data: UpdateAccountParams) => axios.patch<CionAccount>('/api/account', data)

export const createAccount = async (data: CreateAccountParams) => axios.post<{ cionUser: CionAccount, newUser: any }>('/api/public/accounts', data)

export const accountHasBeenSetUp = async () => axios.post<CionAccount>('/api/account/setUp')

export const sendVerificationEmail = async () => axios.post('/api/account/verifyEmail')

export const resetPassword = async (data: ResetPasswordParams) => axios.post<{ email: string }>('/api/public/account/resetPassword', data)
