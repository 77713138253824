import { faCalendar, faCog, faPlus, faTable } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { APPOINTMENT_ADD_PATH, PIPELINE_STEPS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import AppointmentsFilters from './components/AppointmentsFilters/AppointmentsFilters'
import { Icons, AppointmentContent } from './Appointments.styled'
import CalendarView from './components/CalendarView/CalendarView'
import AppointmentTable from './components/AppointmentTable/AppointmentTable'
import Button from '../../../../../components/Button/Button'


export default function Appointments () {
	const [view, setView] = useState<string>('calendar')
	const { t } = useTranslation()

	return (<div>
		<Header>
			{t('appointments')}
			<Icons>
				<Button
					className='primary small'
					onClick={() => {
						setView(view == 'calendar' ? 'table' : 'calendar')
					}}
				>
					<Icon icon={view == 'calendar' ? faTable : faCalendar} />
					{t(view == 'calendar' ? 'table' : 'calendar')}
				</Button>
				<Link to={APPOINTMENT_ADD_PATH}>
					<Button
						className='primary small'
					>
						<Icon icon={faPlus} />
						{t('add')}
					</Button>
				</Link>
			</Icons>
			<AppointmentsFilters view={view} />
		</Header>
		<AppointmentContent>
			{view == 'calendar' ? <CalendarView /> : <AppointmentTable />}
		</AppointmentContent>
	</div>)
}
