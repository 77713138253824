import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { createTestEditPath, TEST_ADD_PATH } from '../../../../../../../common/paths'
import { Test } from '../../../../../../../common/types/TestTypes'
import { Content, Header } from '../../../../../components'
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import TextBox from '../../../../../components/inputs/TextBox'
import BasicActions from '../../../../../components/Table/components/BackActions/BasicActions'
import Table from '../../../../../components/Table/Table'
import useTests from '../../../../../data-hooks/Tests/useTests'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import { CurrencyCell, QuickTableHeaders } from 'react-ui-scaffold'
import Button from '../../../../../components/Button/Button'
import Spacer from '../../../../../components/Spacer/Spacer'
import TextAreaCell from '../../../../../components/QuickTableCells/TextAreaCell/TextAreaCell'
import { Icons } from '../../Appointments/Appointments/Appointments.styled'
import EditTestBanksModal from './components/EditTestBanksModal/EditTestBanksModal'


export default function Tests () {
	const { del, searchText, setSearchText } = useTests()
	const [toDelete, setToDelete] = useState<Test | undefined>()
	const [editTestBanksOpen, setEditTestBanksOpen] = useState<boolean>(false)
	const history = useHistory()
	const { t } = useTranslation()
	
	const columns: QuickTableHeaders = useMemo(() => ({
		code: { title: t('code') },
		note: {
			title: t('note'),
			component: (props) => <TextAreaCell {...props} title={t('note')} />,
			width: 150,
		},
		specimenType: { title: t('sample_and_tube'), width: 180 },
		temp: { title: t('temp'), width: 150 },
		collectionRequirements: {
			title: t('requirements'),
			component: (props) => <TextAreaCell {...props} title={t('collection_requirements')} type='wysiwyg' />,
			width: 150,
		},
		turnaroundTime: { title: t('turnaround_time'), width: 175 },
		
		price: { title: t('price'), component: CurrencyCell, width: 150, headerStyle: { textAlign: 'center' } },
		
		actions: { title: '', width: 84, component: BasicActions, props: {
			hideDelete: (element:Test) => !element.organization,
			hideEdit: (element:Test) => !element.organization,
			deleteHandler: (test:Test) => setToDelete(test),
			editHandler: (test: Test) => history.push(createTestEditPath(test._id)),
		} },
	}), [])

	const searchHandler = (e:any) => {
		setSearchText(e.target.value)
	}

	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}
	return (<div>
		<Header>
			{t('tests')}
			<Icons>
				<Link to={TEST_ADD_PATH}>
					<Button className='primary small'>
						<Icon icon={faPlus} />
						{t('add')}
					</Button>
				</Link>
				<Button className='primary small' onClick={() => setEditTestBanksOpen(true)}>
					{t('test_banks')}
				</Button>
			</Icons>
			<Spacer />
			<TextBox
				placeholder={t('search') as string}
				wide
				onChange={searchHandler}
				value={searchText}
			/>
		</Header>
			
		<Content>
			<Table
				columns={columns}
				hook={useTests}
				dataKey='tests'
			/>
			<ConfirmPopup
				title={t('delete_entity', { entity: toDelete?.code })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
			<EditTestBanksModal open={editTestBanksOpen} setOpen={setEditTestBanksOpen} />
		</Content>
	</div>)
}
