import React from 'react'
import { createSubscriptionCheckoutSession } from '../../../../../api/subscriptions'
import { useTranslation } from 'react-i18next'
import AsyncButton from '../../../../../components/AsyncButton'
import { Content } from '../../../../../components'
import { OrganizationSubscriptionStates } from '../../../../../../../common/enums/OrganizationEnums'

interface Props {
	state: string
	hideText?: boolean
}

export default function Inactive({ state, hideText }:Props) {
	const { t } = useTranslation()

	const subscribeHandler = async () => {
		const sessionUrlRes = await createSubscriptionCheckoutSession()
		window.location.replace(sessionUrlRes.data)
	}
	
	const stateToTextMap = {
		[OrganizationSubscriptionStates.trial]: t('free_trial_account'),
	}
	
	return (
		<Content>
			{!hideText && <>
				<h2 className='flexY' style={{ gap: 8 }}>
					{t('subscription')}
				</h2>

				<p className="light">
					{stateToTextMap[state] || t('inactive_subscription')}
				</p>
			</>}

			<AsyncButton className="button" onClick={subscribeHandler}>
				{t('subscribe')}
			</AsyncButton>
		</Content>
	)
}
