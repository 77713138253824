import { TestProfile, CreateTestProfileParams, UpdateTestProfileParams, GetTestProfilesParams } from '../../../common/types/TestProfileTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'

interface ExtendedGetTestProfilesParams extends GetTestProfilesParams {
	page?: number
}
export const getTestProfiles = ({ searchText, testIds = [], page }:ExtendedGetTestProfilesParams) => axios.get<PaginateResponse<TestProfile[]>>(`${urls.organization}/testProfiles${formatQueryString({ q: searchText, testIds, page })}`)

export const createTestProfile = (params: CreateTestProfileParams) => axios.post<TestProfile>(`${urls.organization}/testProfiles`, params)

export const getSingleTestProfile = (testProfileId: string) => axios.get<TestProfile>(`${urls.organization}/testProfiles/${testProfileId}`)

export const deleteTestProfile = (testProfileId: string) => axios.delete<TestProfile>(`${urls.organization}/testProfiles/${testProfileId}`)

export const updateTestProfile = (params:UpdateTestProfileParams) => axios.patch<TestProfile>(`${urls.organization}/testProfiles/${params.testProfileId}`, params)
