import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { SETTINGS_PATH } from '../../../../../../../../common/paths'
import useOrganizationInvites from '../../../../../../data-hooks/useOrganizationInvites'
import { useTranslation } from 'react-i18next'
import { Header } from '../../../../../../components'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import { SelectBoxForm } from '../../../../../../components/inputs/selects/SelectBox'
import { OrganizationAccessLevels } from '../../../../../../../../common/enums/OrganizationEnums'
import { email_regex } from '../../../../../../utils/rules'
import TextBox from '../../../../../../components/inputs/TextBox'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import LargeFormLayout from '../../../../../../Layouts/LargeFormLayout/LargeFormLayout'
import usePricing, { PricingProvider } from '../../../../../../data-hooks/usePricing'
import useOrganization from '../../../../../../data-hooks/useOrganization'
import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'

export default function InviteOrgaizationMember() {
	const { createInvite } = useOrganizationInvites()
	const { organization: { members = [] } } = useOrganization()
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const { pricing } = usePricing()
	const history = useHistory()
	const { t } = useTranslation()
	const accessLevelOptions = useMemo(() => ([
		{ label: t(OrganizationAccessLevels.admin), value: OrganizationAccessLevels.admin },
		{ label: t(OrganizationAccessLevels.member), value: OrganizationAccessLevels.member },
	]), [])
	const [confirmed, setConfirmed] = useState<boolean>(false)
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

	const form = useForm({
		defaultValues: {
			name: '',
			email: '',
			phoneNo: '',
		},
	})
	const { register, formState: { errors }, handleSubmit } = form
	
	const submitHandler = async (data:any) => {
		if (showWarning && !confirmed) {
			setShowConfirmation(true)
		} else {
			data.accessLevel = data.accessLevel.value
			const res = await createInvite(data)
				.then((res: any) => {
					addSuccessMesasage({
						title: t('invited_member'),
					})
					return res
				})
				.catch(() => {
					addErrorMesasage({
						title: t('failed_to_invite_member'),
					})
				})
			history.push(SETTINGS_PATH)
			return res
		}
	}

	const cancelHandler = () => {
		history.goBack()
	}

	const showWarning = useMemo(() => {
		return members.length >= pricing.basePriceIncludedUsers
	}, [members.length, pricing.basePriceIncludedUsers])
	
	return (
		<div>
			<Header>
				{t('invite_member')}
			</Header>
			<LargeFormLayout>
				<FormProvider {...form}>
					<Form onSubmit={handleSubmit(submitHandler)} showButtons cancelHandler={cancelHandler}>
						<TextBox
							wide
							placeholder={t('email') as string}
							label={t('email') as string}
							error={errors.email}
							{...register('email', {
								validate: {
									email_regex,
								},
							})}
						/>
						<SelectBoxForm
							options={accessLevelOptions}
							onValueChange={() => {}}
							name='accessLevel'
							value={null}
							label={t('access_level') as string}
						/>
					</Form>
				</FormProvider>
				<ConfirmPopup
					title={t('included_users_warning')}
					confirmText={t('i_understand') as string}
					confirmClassName='positive'
					onConfirm={() => {
						setConfirmed(true)
						setShowConfirmation(false)
					}}
					setVisible={setShowConfirmation}
					visible={showConfirmation}
				/>
			</LargeFormLayout>
		</div>
	)
}
