import React, { InputHTMLAttributes } from 'react'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

interface Props extends InputHTMLAttributes<HTMLInputElement>{
	onEnter?(e:any):void,
	wide?: boolean,
	error?: any,
	className?: string,
	name?: string,
	label?: string,
}

const TextBox = React.forwardRef<any, Props>(({
	onEnter,
	className,
	wide,
	error,
	label,
	...props
}, ref) => {
	
	function keyHandler(e:any) {
		if (e.key === 'Enter' && onEnter) {
			onEnter(e)
		}
	}
	
	const wideClass = wide ? 'wide' : ''
	
	return (
		<div>
			{label && <label htmlFor={props.name} className={wide ? 'wide' : ''}>{label}</label>}
			<input
				{...props}
				id={props.name}
				ref={ref}
				onKeyPress={keyHandler}
				className={`${'input'} ${className} ${wideClass} ${error ? 'error' : ''}`}
			/>
			{props.name && <ErrorMessage name={props.name} />}
		</div>
	)
})

export default TextBox
