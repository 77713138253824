import { Test, CreateTestParams } from '../../../../common/types/TestTypes'
import React, { useContext, createContext, useEffect, useState, useCallback, useRef } from 'react'
import { createTest, deleteTest, getTests } from '../../api/tests'
import useOrganization from '../useOrganization'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	tests: Test[],
	isLoading: boolean,
	create(c:CreateTestParams):Promise<AxiosResponse<Test>>,
	searchText: string,
	setSearchText(s:string): void,
	del(labId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	replace(test: Test): void,
	loadPage(): void
}>(null as any)


interface ProviderProps{
	children:any
}

export const TestsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [tests, setTests] = useState<Test[]>([])
	
	const [searchText, setTrueSearchText] = useState('')
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	const debounce = useRef<any>()
	
	const setSearchText = (s:string) => {
		setTrueSearchText(s)
		clearTimeout(debounce.current)
		
		const str = s.trim()
		
		if (str) {
			debounce.current = setTimeout(() => {
				getTests({ searchText: str }).then(({ data: { docs } }) => setTests(docs))
			}, 300)
		} else {
			debounce.current = setTimeout(() => {
				getTests({}).then(({ data: { docs } }) => setTests(docs))
			}, 300)
		}
	}
	
	const { organization } = useOrganization()
	
	const loadPage = () => {
		setIsLoading(true)
		getTests({ page, searchText })
			.then(({ data: { docs, hasMorePages } }) => {
				setTests([...docs])
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		loadPage()
	}, [organization._id, page, searchText])
	
	const create = useCallback(async (params:CreateTestParams) => {
		const newTest = await createTest(params)
		await getTests({}).then(({ data: { docs } }) => setTests(docs))
		return newTest
	}, [organization._id])
	
	const del = useCallback(async (labId: string) => {
		await deleteTest(labId)
		setTests(curr => curr.filter(c => c._id !== labId))
	}, [])

	const replace = useCallback((test: Test) => {
		const index = tests.findIndex(({ _id }) => _id == test._id)
		let newTests = [...tests]
		if (index != -1) {
			newTests[index] = test
			setTests(newTests)
		}
	}, [tests])
	
	return <Context.Provider value={{
		tests,
		isLoading,
		create,
		searchText,
		setSearchText,
		del,
		page,
		setPage,
		hasMorePages,
		replace,
		loadPage,
	}}>
		{ children }
	</Context.Provider>
}

const useTests = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useTests outside provider!')
	}
	return val
}

export default useTests
