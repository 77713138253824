import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import useAuth from '../data-hooks/useAuth'
import Login from '../pages/Login/Login'
import { LOGIN_PATH } from '../../../common/paths'

interface Props {
	children?: any,
	path: string,
	component?: any,
}

export default function ProtectedRoute({ children, path, component }: Props) {
	const { user, account } = useAuth()
	const history = useHistory()

	if (!(user && account)) {
		return <Redirect to={`${LOGIN_PATH}?path=${history.location.pathname}`} />
	}

	return <Route path={path} component={component}>{children}</Route>
}
