import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Form from '../../../../Form/Form'
import TextArea from '../../../inputs/TextArea'
import { Page } from 'react-ui-scaffold'
import { Spacing } from './ErrorPage.styled'
import axios from '../../../../api/axios'

interface ErrorPageProps {
	appError?: string
}

export default function ErrorPage({ appError }: ErrorPageProps) {
	const { t } = useTranslation()
	const form = useForm({ defaultValues: { whatHappened: '', appError } })
	const { handleSubmit, register, formState: { errors } } = form
	const internalSubmitHandler = (e: any) => {
		axios.post('/api/error/note', e)
			.then(() => {
				location.reload()
			})
	}

	const cancelHandler = () => {
		location.reload()
	}

	return (
		<div className='MainLayout'>
			<Page className='notDock flexCenter' loading={false}>
				<Spacing>
					<h1>
						{t('something_went_wrong')}
					</h1>
					<h3>{t('error_occured')}</h3>
					<h3>{t('after_submitting')}</h3>
					<Form onSubmit={handleSubmit(internalSubmitHandler)} showButtons cancelHandler={cancelHandler}>
						<TextArea
							wide
							label={t('what_happened') as string}
							error={errors.whatHappened}
							{...register('whatHappened', {
								required: true,
							})}
						/>
					</Form>
				</Spacing>
			</Page>
		</div>
	)
}
