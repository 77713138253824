import React from 'react'
import { Popup } from 'react-ui-scaffold'
import AsyncButton from '../AsyncButton'
import './ConfirmPopup.css'

interface Props{
	title: string,
	visible?: boolean | any,
	setVisible(b:boolean): void,
	onConfirm(): any,
	onCancel?(): any,
	confirmText?: string,
	cancelText?: string,
	confirmClassName?: string,
}

export default function ConfirmPopup({
	title,
	visible,
	setVisible,
	onConfirm,
	onCancel,
	confirmText='Confirm',
	cancelText='Cancel',
	confirmClassName,
}:Props) {
	const cancelHandler = async () => {
		onCancel && await onCancel()
		setVisible(false)
	}
	
	const confirmHandler = async () => {
		await onConfirm()
		setVisible(false)
	}
	
	return (
		<Popup
			title={title}
			visible={visible}
			setVisible={setVisible}
			className='ConfirmPopup'
		>
			<div className="flexX controlsBox">
				<AsyncButton className={`${confirmClassName} button hollow control`} onClick={confirmHandler}>
					{confirmText}
				</AsyncButton>

				<button className="button hollow control" onClick={cancelHandler}>
					{cancelText}
				</button>
			</div>
		</Popup>
	)
}
