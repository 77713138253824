import styled from 'styled-components'

const CalendarWrapper = styled.div<{ dayWidth?: number, numberOfDays?: number }>`
	${(props) => {
		const width = props.dayWidth || 200
		const totalWidth = width * (props.numberOfDays || 7)
		return `
			.fc-col-header, .fc-timegrid-body, .fc-timegrid-slots > table, .fc-timegrid-cols > table, .fc-col-header, .fc-view  {
				width: max(${totalWidth + 52}px, 100%) !important;
			}
			.fc-toolbar.fc-header-toolbar {
				margin-bottom: 8px;
			}
			.fc-day {
				width: max(${width}px, calc(100% / 7));
				> div {
					width:  max(${width}px, calc(100% / 7));
				}
			}
			.fc-view-harness {
				overflow-x: auto;
			}
		`
	}}
`

export {
	CalendarWrapper,
}
