import styled from 'styled-components'

const ChecksContainer = styled.div`
	margin: 0 12px;
	margin-bottom: 18px;
	display: flex;
	flex-direction: column;
	gap: 6px;
`

export {
	ChecksContainer,
}
