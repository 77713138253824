import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: flex-end;
	align-items: flex-end;
`

const Divider = styled.div`
	border-top: solid 1px var(--borderColor);
	width: 100%;
	margin: 15px 0;
`

const ForgotPasswordLink = styled(Link)`
	margin-left: auto;
`

export {
	Divider,
	LoginContainer,
	ForgotPasswordLink,
}
