import React from 'react'
import { useHistory } from 'react-router'
import useSingleCustomer from '../../../../../data-hooks/Customers/useSingleCustomer'
import AddEditCustomerForm from '../components/AddEditCustomerForm/AddEditCustomerForm'
import CustomerView from '../components/CustomerView/CustomerView'

export default function EditCustomer() {
	const history = useHistory()
	const { update, customer, setCustomer } = useSingleCustomer()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setCustomer(res.data)
		return res.data
	}
	
	return (
		<CustomerView>
			<AddEditCustomerForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				customer={customer}
			/>
		</CustomerView>
	)
}
