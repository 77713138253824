import styled from 'styled-components'
import { Content } from '../../../../../components'

const AppointmentContent = styled(Content)`
	overflow-x: auto;
`

const Icons = styled.div`
	display: flex;
	gap: 6px;
`

export {
	Icons,
	AppointmentContent,
}
