import React from 'react'
import { useScreenSize } from '../../hooks/useScreenSize'
import { _LargeFormContainer } from './LargeFormLayout.styled'

interface Props {
	children: any
}

export default function LargeFormLayout({ children }:Props) {
	const { screenSize } = useScreenSize()
	
	return (
		<_LargeFormContainer screenSize={screenSize}>
			{children}
		</_LargeFormContainer>
	)
}
