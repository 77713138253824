import styled from 'styled-components'

const HeaderContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 8px;
	font-size: 20px;
`

export {
	HeaderContainer,
}
