import { Lab } from '../../../../../../../../common/types/LabTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import { useTranslation } from 'react-i18next'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { Link } from 'react-router-dom'
import { createLabEditPath } from '../../../../../../../../common/paths'


interface Props{
	lab?: Lab,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void
}

export default function AddEditLabForm({
	lab,
	submitHandler,
	cancelHandler,
}:Props) {
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const form = useForm({
		defaultValues: {
			name: '',
			email: '',
			phoneNo: '',
		},
	})
	const { handleSubmit, register, formState: { errors }, setValue, reset } = form
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	useEffect(() => {
		if (lab) {
			setValue('name', lab.name)
		}
	}, [lab])

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		submitHandler(e)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(lab?._id ? 'lab_updated' : 'lab_created'),
					content: (
						<Link target='_blank' to={createLabEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if (lab?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(lab?._id ? 'lab_update_failed' : 'lab_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
			>
				<TextBox
					wide
					placeholder={t('name') as string}
					label={t('name') as string}
					error={errors.name}
					{...register('name', { required: true })}
				/>
			</Form>
		</FormProvider>
	)
}
