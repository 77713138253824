import React from 'react'
import { Route, Switch } from 'react-router'
import { LabsProvider } from '../../../../data-hooks/Labs/useLabs'
import { LABS_PATH, LAB_ADD_PATH, LAB_EDIT_PATH, LAB_PATH } from '../../../../../../common/paths'
import AddLab from './AddLab/AddLab'
import Labs from './Labs/Labs'
import { SingleLabProvider } from '../../../../data-hooks/Labs/useSingleLab'
import EditLab from './EditLab/EditLab'
import ViewLab from './ViewLab/ViewLab'

export default function LabsRoutes() {

	return (
		<LabsProvider>
			<SingleLabProvider>
				<Switch>
					<Route exact path={LAB_ADD_PATH} component={AddLab}/>
					<Route path={LAB_PATH} component={ViewLab}/>
					<Route path={LAB_EDIT_PATH} component={EditLab}/>
					<Route exact path={LABS_PATH} component={Labs}/>
				</Switch>
			</SingleLabProvider>
		</LabsProvider>
	)
}
