import { Customer, UpdateCustomerParams } from '../../../../common/types/CustomerTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { createNote as _createNote, deleteNote as _deleteNote, getNotes, getSingleCustomer, updateCustomer } from '../../api/customers'
import { CreateNoteParams, DeleteNoteParams, Note } from '../../../../common/types/NoteTypes'
import useCustomers from './useCustomers'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	customer?: Customer,
	customerId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateCustomerParams, 'customerId'>): Promise<AxiosResponse<Customer>>
	setCustomer: (customer: Customer) => void
	setCustomerId: (customer: string) => void
	createNote(params: Omit<CreateNoteParams, 'parentType' | 'createdBy' | 'parent'>): Promise<AxiosResponse<Note>>,
	deleteNote(params: DeleteNoteParams): Promise<AxiosResponse<Note>>,
	notes?: Note[],
	notePage: number,
	setNotePage(newPage:number): void,
	hasMoreNotePages: boolean,
}>(null as any)


interface ProviderProps{
	children:any,
}

export const SingleCustomerProvider = ({ children }: ProviderProps) => {
	const [customerId, setCustomerId] = useState<string>()
	const [customer, setCustomer] = useState<Customer>()
	const [notes, setNotes] = useState<Note[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [notePage, setNotePage] = useState(1)
	const [hasMoreNotePages, setHasMoreNotePages] = useState(true)
	const { replace } = useCustomers()
	
	useEffect(() => {
		if (customerId) {
			setIsLoading(true)
			
			getSingleCustomer(customerId)
				.then(({ data }) => setCustomer(data))
				.finally(() => setIsLoading(false))
		}
	}, [customerId])

	useEffect(() => {
		if (customerId) {
			getNotes({ parent: customerId, page: notePage })
				.then(({ data: { docs, hasMorePages } }) => {
					setNotes(docs)
					setHasMoreNotePages(hasMorePages)
				})
		}
	}, [customerId, notePage])
	
	const update = useCallback(async (params: Omit<UpdateCustomerParams, 'customerId'>) => {
		const newCustomer = await updateCustomer({
			...params,
			customerId: customer?._id as string,
		})
		
		replace(newCustomer.data)
		getSingleCustomer(customer?._id as string).then(({ data }) => setCustomer(data))
		
		return newCustomer
	},[customer?._id])

	const createNote = useCallback(async (params: Omit<CreateNoteParams, 'parentType' | 'createdBy' | 'parent'>) => {
		const note = await _createNote({
			...params,
			parent: customer?._id as string,
		})
		
		getNotes({ parent: customerId, page: notePage })
			.then(({ data: { docs } }) => setNotes(docs))
		
		return note
	},[customer?._id, notePage])


	const deleteNote = useCallback(async (params: DeleteNoteParams) => {
		const note = await _deleteNote({
			...params,
		})
		
		getNotes({ parent: customerId, page: notePage })
			.then(({ data: { docs } }) => setNotes(docs))
		
		return note
	},[customer?._id, notePage])

	return <Context.Provider value={{
		customer,
		customerId,
		isLoading,
		update,
		setCustomer,
		setCustomerId,
		createNote,
		deleteNote,
		notes,
		hasMoreNotePages,
		notePage,
		setNotePage,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleCustomer = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleCustomer outside provider!')
	}
	return val
}

export default useSingleCustomer
