import { QuickTable } from 'react-ui-scaffold'
import styled from 'styled-components'

const StyledQuickTable = styled(QuickTable)`
	padding-bottom: 9px;
	.cellContainer {
		display: inline-grid;
	}
`

const TableContainer = styled.div`
	max-height: 660px;
	overflow: auto;
`

const PageButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 6px;
	padding-top: 12px;
`

export {
	PageButtonContainer,
	StyledQuickTable,
	TableContainer,
}
