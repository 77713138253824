import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { createOrganization } from '../../api/organizations'
import AsyncButton from '../../components/AsyncButton'
import TextBox from '../../components/inputs/TextBox'
import useAuth from '../../data-hooks/useAuth'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'
import { useTranslation } from 'react-i18next'
import './CreateOrganization.css'

export default function CreateOrganization() {
	const [name, setName] = useState('')
	const [error, setError] = useState('')
	const { account, logOut } = useAuth()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submit = () => {
		const cleanName = name.trim()
		if (!cleanName) {
			setError(t('enter_valid_workspace_name') as string)
			return
		}
		
		createOrganization({ name: cleanName })
			.then(() => {
				window.location.href = '/Dashboard'
			})
			.catch((err: any) => setError(err.error))
	}
	
	const changeHandler = (e:any) => {
		setName(e.target.value)
	}
	
	const switchAccount = async () => {
		await logOut()
		history.push('/Login')
	}
	
	return (
		<AuthLayout className='CreateOrganization'>
			<h1>{t('create_workspace')}</h1>
			<h2>{t('signed_in_as', { email: account.email })}</h2>
			<button className="button plain" onClick={switchAccount}>{t('switch_account')}</button>
			
			<div className="controls">
				<div className="form">
					<div className="errorMessage">
						{error}
					</div>
					<TextBox
						wide
						placeholder={t('workspace_name') as string}
						onEnter={submit}
						onChange={changeHandler}
						error={error}
					/>

					<div className="controls">
						<AsyncButton
							className="button wide"
							onClick={submit}
						>{t('create')}</AsyncButton>
					</div>
				</div>
			</div>
		</AuthLayout>
	)
}
