import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Page } from 'react-ui-scaffold'
import { APPOINTMENTS_PATH } from '../../../../../../../../common/paths'
import { EntityHeader, Header } from '../../../../../../components'
import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'
import useAppointments from '../../../../../../data-hooks/Appointments/useAppointments'
import useSingleAppointment from '../../../../../../data-hooks/Appointments/useSingleAppointment'
import { useTranslation } from 'react-i18next'
import { ActionIcon } from '../../../../../../components/Table/components/BackActions/BasicActions.styled'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import LargeFormLayout from '../../../../../../Layouts/LargeFormLayout/LargeFormLayout'
import Button from '../../../../../../components/Button/Button'

export default function AppointmentView({ children }: { children: ReactElement }) {
	const { params }:any = useRouteMatch()
	const { appointment, isLoading, setAppointmentId } = useSingleAppointment()
	const { del } = useAppointments()
	const history = useHistory()
	const [showDelete, setShowDelete] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		setAppointmentId(params.id)
	}, [params.id])
	
	if (isLoading || !appointment) {
		return <Page loading/>
	}

	const deleteHandler = async () => {
		await del(appointment._id)
		history.push(APPOINTMENTS_PATH)
	}

	return (
		<div>
			<ConfirmPopup
				title={t('delete_appointment', { name: appointment.customer.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={setShowDelete}
				visible={showDelete}
			/>
			<Header>
				<EntityHeader>
					{t('test_profile_for_customer', { testProfile: appointment.testProfile?.code || appointment.tests?.map((test) => test.code).join(', '), customer: appointment.customer.name })}
				</EntityHeader>
				<Button className='primary small hollow' onClick={() => setShowDelete(true)}>
					<ActionIcon icon={faTrash} />
					{t('delete')}
				</Button>
			</Header>
			<LargeFormLayout>
				{children}
			</LargeFormLayout>
			
		</div>)

}
