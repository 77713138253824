import { Lab, CreateLabParams } from '../../../../common/types/LabTypes'
import React, { useContext, createContext, useEffect, useState, useCallback, useRef } from 'react'
import { createLab, deleteLab, getLabs } from '../../api/labs'
import useOrganization from '../useOrganization'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	labs: Lab[],
	isLoading: boolean,
	create(c:CreateLabParams):Promise<AxiosResponse<Lab>>,
	searchText: string,
	setSearchText(s:string): void,
	del(labId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	replace(lab: Lab): void,
}>(null as any)


interface ProviderProps{
	children:any
}

export const LabsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [labs, setLabs] = useState<Lab[]>([])
	
	const [searchText, setTrueSearchText] = useState('')
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	const debounce = useRef<any>()
	
	const setSearchText = (s:string) => {
		setTrueSearchText(s)
		clearTimeout(debounce.current)
		
		const str = s.trim()
		
		if (str) {
			debounce.current = setTimeout(() => {
				getLabs({ searchText: str }).then(({ data: { docs } }) => setLabs(docs))
			}, 300)
		} else {
			debounce.current = setTimeout(() => {
				getLabs({}).then(({ data: { docs } }) => setLabs(docs))
			}, 300)
		}
	}
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getLabs({ page, searchText })
			.then(({ data: { docs, hasMorePages } }) => {
				setLabs(docs)
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}, [organization._id, page, searchText])
	
	const create = useCallback(async (params:CreateLabParams) => {
		const newLab = await createLab(params)
		await getLabs({}).then(({ data: { docs } }) => setLabs(docs))
		return newLab
	}, [organization._id])
	
	const del = useCallback(async (labId: string) => {
		await deleteLab(labId)
		setLabs(curr => curr.filter(c => c._id !== labId))
	}, [])

	const replace = useCallback((lab: Lab) => {
		const index = labs.findIndex(({ _id }) => _id == lab._id)
		let newLabs = [...labs]
		if (index != -1) {
			newLabs[index] = lab
			setLabs(newLabs)
		}
	}, [labs])

	return <Context.Provider value={{
		labs,
		isLoading,
		create,
		searchText,
		setSearchText,
		del,
		page,
		setPage,
		hasMorePages,
		replace,
	}}>
		{ children }
	</Context.Provider>
}

const useLabs = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useLabs outside provider!')
	}
	return val
}

export default useLabs
