import styled from 'styled-components'
import React from 'react'
import { CheckButton as _CheckButton } from 'react-ui-scaffold'

const Wrapper = styled.div`
	button {
		border: none;
		padding: 0;
		height: 22px;
		input {
			margin-left: 0;
			height: 16px;
			width: 16px;
		}
		span {
			height: 22px;
			display: inline-block;
			position: relative;
			top: -3px;
		}
	}
`
interface CheckButtonProps {
	value: any;
	onChange: Function;
	disabled?: boolean;
	checked?: boolean;
	defaultChecked?: boolean;
	style?: object;
	className?: string;
	children: React.ReactNode;
}

export default function CheckButton(props: CheckButtonProps) {
	return (
		<Wrapper>
			<_CheckButton {...props} />
		</Wrapper>
	)
}
