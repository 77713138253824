import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Page } from 'react-ui-scaffold'
import { CUSTOMERS_PATH } from '../../../../../../../../common/paths'
import { EntityHeader, Header } from '../../../../../../components'
import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'
import useLabs from '../../../../../../data-hooks/Labs/useLabs'
import useSingleLab from '../../../../../../data-hooks/Labs/useSingleLab'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../../Layouts/LargeFormLayout/LargeFormLayout'
import { ActionIcon } from '../../../../../../components/Table/components/BackActions/BasicActions.styled'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../../../../components/Button/Button'

export default function LabView({ children }: { children: ReactElement }) {
	const { params }:any = useRouteMatch()
	const { lab, isLoading, setLabId } = useSingleLab()
	const { del } = useLabs()
	const history = useHistory()
	const [showDelete, setShowDelete] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		setLabId(params.id)
	}, [params.id])
	
	if (isLoading || !lab) {
		return <Page loading/>
	}

	const deleteHandler = async () => {
		await del(lab._id)
		history.push(CUSTOMERS_PATH)
	}

	return (
		<div>
			<ConfirmPopup
				title={t('delete_entity', { entity: lab.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={setShowDelete}
				visible={showDelete}
			/>
			<Header>
				<EntityHeader>
					{`${lab.name}`}
				</EntityHeader>
				<Button className='primary small hollow' onClick={() => setShowDelete(true)}>
					<ActionIcon icon={faTrash} />
					{t('delete')}
				</Button>
			</Header>
			<LargeFormLayout>
				{children}
			</LargeFormLayout>
			
		</div>)

}
