import React from 'react'
import { CreateTestProfileParams } from '../../../../common/types/TestProfileTypes'
import BaseMenu from '../BaseMenu/BaseMenu'
import AddEditTestProfileForm from '../../pages/Main/pages/TestProfiles/components/AddEditTestProfileForm/AddEditTestProfileForm'
import { useTranslation } from 'react-i18next'

export interface CreateTestProfileMenuProps{
	onSubmit(c:CreateTestProfileParams): any
	close?: Function
}

export default function CreateTestProfileMenu({ onSubmit }: CreateTestProfileMenuProps) {
	const { t }  = useTranslation()
	
	const cancelHandler = () => {
		close && close()
	}
	const submitHandler = async (data:any) => {
		return await onSubmit(data)
	}
	
	return (
		<BaseMenu>
			<h1>{t('create_test_profile')}</h1>
			<AddEditTestProfileForm cancelHandler={cancelHandler} submitHandler={submitHandler} />
		</BaseMenu>
	)
}
