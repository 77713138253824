import { TestProfile, CreateTestProfileParams } from '../../../../common/types/TestProfileTypes'
import React, { useContext, createContext, useEffect, useState, useCallback, useRef } from 'react'
import { createTestProfile, deleteTestProfile, getTestProfiles } from '../../api/testProfiles'
import useOrganization from '../useOrganization'
import { AxiosResponse } from 'axios'

export interface TestProfileSearchParmas {
	searchText?: string
	testIds?: string[]
}
const Context = createContext<{
	testProfiles: TestProfile[],
	isLoading: boolean,
	create(c:CreateTestProfileParams):Promise<AxiosResponse<TestProfile>>,
	searchParams: TestProfileSearchParmas,
	setSearchParams(s:TestProfileSearchParmas): void,
	del(testProfileId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	replace(testProfile: TestProfile): void,
}>(null as any)


interface ProviderProps{
	children:any
}

export const TestProfilesProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [testProfiles, setTestProfiles] = useState<TestProfile[]>([])
	
	const [searchParams, setTrueSearchParams] = useState<TestProfileSearchParmas>({})
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	const debounce = useRef<any>()
	
	const setSearchParams = (s:TestProfileSearchParmas) => {
		setTrueSearchParams(s)
		clearTimeout(debounce.current)
		
		const str = (s || '').searchText?.trim()
		const { testIds = [] } = s
		
		if (str || testIds?.length) {
			debounce.current = setTimeout(() => {
				getTestProfiles({ searchText: str, testIds }).then(({ data: { docs } }) => setTestProfiles(docs))
			}, 300)
		} else {
			debounce.current = setTimeout(() => {
				getTestProfiles({}).then(({ data: { docs } }) => setTestProfiles(docs))
			}, 300)
		}
	}
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getTestProfiles({ page, searchText: searchParams.searchText, testIds: searchParams.testIds })
			.then(({ data: { docs, hasMorePages } }) => {
				setTestProfiles(docs)
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}, [organization._id, page])
	
	const create = useCallback(async (params:CreateTestProfileParams) => {
		const newTestProfile = await createTestProfile(params)
		await getTestProfiles({}).then(({ data: { docs } }) => setTestProfiles(docs))
		return newTestProfile
	}, [organization._id])
	
	const del = useCallback(async (testProfileId: string) => {
		await deleteTestProfile(testProfileId)
		setTestProfiles(curr => curr.filter(c => c._id !== testProfileId))
	}, [])

	const replace = useCallback((testProfile: TestProfile) => {
		const index = testProfiles.findIndex(({ _id }) => _id == testProfile._id)
		let newTestProfiles = [...testProfiles]
		if (index != -1) {
			newTestProfiles[index] = testProfile
			setTestProfiles(newTestProfiles)
		}
	}, [testProfiles])
	
	return <Context.Provider value={{
		testProfiles,
		isLoading,
		create,
		searchParams,
		setSearchParams,
		del,
		page,
		setPage,
		hasMorePages,
		replace,
	}}>
		{ children }
	</Context.Provider>
}

const useTestProfiles = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useTestProfiles outside provider!')
	}
	return val
}

export default useTestProfiles
