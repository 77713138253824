import React, { SetStateAction, useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useAuth from '../../data-hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

interface Props {
	setMessage: SetStateAction<any>
}

export default function LoginWithGoogle({ setMessage }: Props) {
	const { t } = useTranslation()
	const { loginWithGoogle } = useAuth()

	const [isLoading, setIsLoading] = useState(false)

	const login = async () => {
		setIsLoading(true)
		await loginWithGoogle().catch(() => setMessage({ content: t('unable_to_sign_in') }))
		setIsLoading(false)
	}

	return (
		<FreeButton loading={isLoading} className="button wide cion" onClick={login}>
			<span style={{ marginRight: 15 }}>
				<FontAwesomeIcon icon={faGoogle as any} />
			</span>
			
			{t('sign_in_with_google')}
		</FreeButton>
	)
}
