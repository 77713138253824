import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { IconContainer } from './Icon.styled'
import { useTranslation } from 'react-i18next'
import { IconContext } from './IconContext'
import { createPortal } from 'react-dom'

interface Props extends FontAwesomeIconProps {
	tooltip?: string,
	direction?: 'down' | 'right',
}
const z = 100

export default function Icon({ tooltip, direction = 'down', ...props }: Props) {
	const buttonRef = useRef<HTMLSpanElement>(null as any)
	const menuRef = useRef<HTMLDivElement>(null as any)
	const { t } = useTranslation()
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(true)

	const position = useCallback(() => {
		if (menuRef.current && buttonRef.current && menuRef.current.style.display !== 'none') {
			menuRef.current.style.transform = 'none'
			const buttonRect = buttonRef.current.getBoundingClientRect()
			
			switch (direction) {
				case 'down': {
					menuRef.current.style.top = `${buttonRect.bottom + 10}px`
					menuRef.current.style.left = `${buttonRect.left}px`
					break
				} case 'right': {
					menuRef.current.style.top = `${buttonRect.top}px`
					menuRef.current.style.left = `${buttonRect.right + 5}px`
				}
			}
			
			const menuRect = menuRef.current.getBoundingClientRect()
			const diffX = window.innerWidth - menuRect.right
			const diffY = window.innerHeight - menuRect.bottom

			const tx = diffX < 0 ? diffX - 5 : 0
			const ty = diffY < 5 ? diffY - 5 : 0

			menuRef.current.style.transform = `translate(${tx}px, ${ty}px)`
		}
	}, [])

	return (<>
		<span
			onMouseOver={() => {
				setTooltipOpen(true)
				position()
				menuRef.current.style.display = 'block'
			}}
			onMouseLeave={() => {
				setTooltipOpen(false)
				menuRef.current.style.display = 'none'
			}}
			ref={buttonRef}
		>
			<FontAwesomeIcon {...props} />
		</span>
		{tooltip && <IconContext.Provider value={{
			z: z + 1,
			hasParentMenu: true,
			parentOpen: tooltipOpen,
			parent: menuRef.current,
		}}>
			{createPortal(<div
				ref={menuRef}
				className='panelContainer'
				onMouseDown={e => e.stopPropagation()}
				style={{
					zIndex: z,
				}}
			>
				<IconContainer
					className='panelBody'
				>
					{t(tooltip)}
				</IconContainer>
			</div>, document.body)}
		</IconContext.Provider>}
	</>)
}
