import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Content, Header } from '../../../../components'
import useAuth from '../../../../data-hooks/useAuth'
import InnerOrganizationMembers from './OrganizationMembers/OrganizationMembers/OrganizationMembers'
import { OrganizationAccessLevels } from '../../../../../../common/enums/OrganizationEnums'
import useOrganization from '../../../../data-hooks/useOrganization'
import { OrganizationInvitesProvider } from '../../../../data-hooks/useOrganizationInvites'
import Subscriptions from './Subscriptions/Subscriptions'
import { useHistory } from 'react-router'
import { DASHBOARD_PATH } from '../../../../../../common/paths'

export default function Settings() {
	const { accessLevel } = useOrganization()
	const { account, accountSetUp } = useAuth()
	const history = useHistory()
	
	// const [lang, setLang] = useState<LanguageKey>(account.language)
	
	// useEffect(() => {
	// 	i18n.changeLanguage(lang)
		
	// 	updateAccount({
	// 		language: lang,
	// 	})
	// }, [lang])
	
	useEffect(() => {
		if (accessLevel == OrganizationAccessLevels.member) {
			history.replace(DASHBOARD_PATH)
		} else if (!account.hasDoneSetup) {
			accountSetUp()
		}
	}, [])
	
	const { t } = useTranslation()
	
	return (
		<>
			<Header>
				{t('settings')}
			</Header>
			
			<Content>
				{/* <h2>{t('language')}</h2>
				<RadioGroup
					value={lang}
					onValueChange={(newLang: LanguageKey) => {
						setLang(newLang)
					}}
				>
					<RadioButton value='en'>English</RadioButton>
					<RadioButton value='fr'>Français</RadioButton>
				</RadioGroup> */}
				
				{ accessLevel == OrganizationAccessLevels.admin && <OrganizationInvitesProvider>
					<InnerOrganizationMembers />
				</OrganizationInvitesProvider>}
				
				{accessLevel == OrganizationAccessLevels.admin && <Subscriptions />}
			</Content>
		</>
	)
}
