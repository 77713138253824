import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { createCustomerEditPath, CUSTOMER_ADD_PATH } from '../../../../../../../common/paths'
import { Customer } from '../../../../../../../common/types/CustomerTypes'
import { Content, Header } from '../../../../../components'
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import TextBox from '../../../../../components/inputs/TextBox'
import Table from '../../../../../components/Table/Table'
import useCustomers from '../../../../../data-hooks/Customers/useCustomers'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import { ClickToCopy, QuickTableHeaders } from 'react-ui-scaffold'
import CustomerActions from './CustomerActions'
import Button from '../../../../../components/Button/Button'
import Spacer from '../../../../../components/Spacer/Spacer'


export default function Customers () {
	const { del, searchParams, setSearchParams } = useCustomers()
	const [toDelete, setToDelete] = useState<Customer | undefined>()
	const history = useHistory()
	const { t } = useTranslation()
	
	const columns: QuickTableHeaders = useMemo(() => ({
		shortId: { title: t('id'), width: 70 },
		name: { title: t('name'), width: 200 },
		email: { title: t('email'), width: 400, component: ClickToCopy },
		phoneNo: { title: t('phone'), width: 200, component: ClickToCopy },
		postalCode: { title: t('postal_code'), width: 150 },
		
		actions: { title: '', width: 85, component: CustomerActions, props: {
			deleteHandler: (element: any) => {
				setToDelete(element)
			},
			
			editHandler: (element: any) => history.push(createCustomerEditPath(element._id)),
		} },
	}), [t])

	const searchHandler = (e:any) => {
		setSearchParams({ query: e.target.value })
	}

	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}
	return (<div>
		<Header>
			{t('customers')}
			<Link to={CUSTOMER_ADD_PATH}>
				<Button className='primary small'>
					<Icon icon={faPlus} />
					{t('add')}
				</Button>
			</Link>
			<Spacer />
			<TextBox
				placeholder={t('search') as string}
				wide
				onChange={searchHandler}
				value={searchParams?.query}
				
			/>
		</Header>

		<Content>
			<Table
				columns={columns}
				hook={useCustomers}
				dataKey="customers"
			/>
			<ConfirmPopup
				title={t('delete_entity', { entity: toDelete?.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
		</Content>
	</div>)
}
