import React from 'react'
import Inactive from './Inactive'
import useOrganization from '../../../../../data-hooks/useOrganization'
import { OrganizationSubscriptionStates } from '../../../../../../../common/enums/OrganizationEnums'
import Active from './Active'

export default function Subscriptions(props: any) {
	const { organization } = useOrganization()
	
	switch (organization.subscriptionState) {
		case OrganizationSubscriptionStates.active:
			return <Active {...props} />
	}
	
	return <Inactive state={organization.subscriptionState} {...props} />
}
