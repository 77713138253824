import { Test } from '../../../../../../../../common/types/TestTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import { useTranslation } from 'react-i18next'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { Link } from 'react-router-dom'
import { createTestEditPath } from '../../../../../../../../common/paths'
import LabSelect from '../../../../../../components/inputs/resourceSelects/LabSelect/LabSelect'
import { SelectBoxOption } from '../../../../../../components/inputs/selects/SelectBox'
import { positive } from '../../../../../../utils/rules'
import TextArea from '../../../../../../components/inputs/TextArea'
import { WysiwygForm } from '../../../../../../components/inputs/Wysiwyg/Wysiwyg'
import { centToDollar, dollarToCent } from '../../../../../../utils/dollarUtils'


interface Props{
	test?: Test,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void
}

interface TestForm {
	code: string
	lab: null | SelectBoxOption,
	specimenType?: string,
	collectionRequirements?: string,
	turnaroundTime?: number,
	temp?: string,
	price?: string,
	note?: string,
	overview?: string,
	preparationInstructions?: string,
	volume?: string,
	container?: string,
	storageAndTransport?: string,
}

export default function AddEditTestForm({
	test,
	submitHandler,
	cancelHandler,
}:Props) {
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const form = useForm<TestForm>({})
	const { handleSubmit, register, formState: { errors }, setValue, reset } = form
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (test) {
			setValue('code', test.code)
			if (test.labId) {
				setValue('lab', { label: test.labId.name, value: test.labId._id })
			}
			setValue('price', test.price ? centToDollar(test.price || 0) : '')
			setValue('collectionRequirements', test.collectionRequirements)
			setValue('container', test.container)
			setValue('note', test.note)
			setValue('overview', test.overview)
			setValue('preparationInstructions', test.preparationInstructions)
			setValue('specimenType', test.specimenType)
			setValue('storageAndTransport', test.storageAndTransport)
			setValue('temp', test.temp)
			setValue('turnaroundTime', test.turnaroundTime)
			setValue('volume', test.volume)
		}
	}, [test])

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		const body = {
			...e,
			lab: e.lab?.value,
			price: dollarToCent(e.price),
		}
		submitHandler(body)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(test?._id ? 'test_updated' : 'test_created'),
					content: (
						<Link target='_blank' to={createTestEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if (test?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(test?._id ? 'test_update_failed' : 'test_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
			>
				<TextBox
					wide
					placeholder={t('code') as string}
					label={t('code') as string}
					error={errors.code}
					{...register('code', { required: true })}
				/>
				<TextBox
					wide
					placeholder={t('price') as string}
					label={t('price') as string}
					error={errors.price}
					type="number"
					step="any"
					{...register('price', {
						validate: {
							positive,
						},
					})}
				/>
				<WysiwygForm
					wide
					label={t('collection_requirements') as string}
					error={errors.collectionRequirements}
					name='collectionRequirements'
					defaultValue={test?.collectionRequirements}
				/>
				<TextBox
					wide
					placeholder={t('container') as string}
					label={t('container') as string}
					error={errors.container}
					{...register('container')}
				/>
				<TextArea
					wide
					placeholder={t('note') as string}
					label={t('note') as string}
					error={errors.note}
					{...register('note')}
				/>
				<WysiwygForm
					wide
					label={t('overview') as string}
					error={errors.overview}
					name='overview'
					defaultValue={test?.overview}
				/>
				<WysiwygForm
					wide
					label={t('preparation_instructions') as string}
					error={errors.preparationInstructions}
					name='preparationInstructions'
					defaultValue={test?.preparationInstructions}
				/>
				<TextBox
					wide
					placeholder={t('specimen_type') as string}
					label={t('specimen_type') as string}
					error={errors.specimenType}
					{...register('specimenType')}
				/>
				<TextBox
					wide
					placeholder={t('storage_and_transport') as string}
					label={t('storage_and_transport') as string}
					error={errors.storageAndTransport}
					{...register('storageAndTransport')}
				/>
				<TextBox
					wide
					placeholder={t('temp') as string}
					label={t('temp') as string}
					error={errors.temp}
					{...register('temp')}
				/>
				<TextBox
					wide
					placeholder={t('turnaround_time') as string}
					label={t('turnaround_time') as string}
					error={errors.turnaroundTime}
					{...register('turnaroundTime')}
				/>
				<TextBox
					wide
					placeholder={t('volume') as string}
					label={t('volume') as string}
					error={errors.volume}
					{...register('volume')}
				/>
				<LabSelect defaultValue={test?.labId} clearable />
			</Form>
		</FormProvider>
	)
}
