import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { QuickTableHeaders } from 'react-ui-scaffold'
import { createAppointmentEditPath } from '../../../../../../../../common/paths'
import LabHotlink from '../../../../../../components/hotlinks/LabHotlink'
import TestProfileHotlink from '../../../../../../components/hotlinks/TestProfileHotlink'
import Table from '../../../../../../components/Table/Table'
import useAppointments from '../../../../../../data-hooks/Appointments/useAppointments'
import useOrganization from '../../../../../../data-hooks/useOrganization'

export default function InnerCustomerAppointmentTable() {
	const { t } = useTranslation()
	const { organization: { members } } = useOrganization()
	const memberMap = useMemo(() => {
		const map: any = {}
		members.map((member) => {
			map[member._id as string] = member
		})
		return map
	}, [members])
	const columns: QuickTableHeaders = useMemo(() => ({
		id: { title: t('id'), component: ({ row }) => <Link to={createAppointmentEditPath(row._id)} target='_blank'>{row.shortId}</Link>, width: 70 },
		testProfile: { title: t('test_profile'), component: ({ row }) => <TestProfileHotlink target='_blank' testProfile={row.testProfile} /> },
		lab: { title: t('lab'), component: ({ row }) => <LabHotlink target='_blank' lab={row.lab} /> },
		
		assignee: { title: t('assignee'), component: ({ row }) => {
			const member = memberMap[row._id]
			if (!member) {
				return <></>
			}
			return (<div>{member.user.firstName} {member.user.lastName}</div>)

		} },
		step: { title: t('step'), component: ({ cell }) => cell?.name },
	}), [])
	
	return (
		<Table
			columns={columns}
			hook={useAppointments}
			dataKey='appointments'
			disablePagination
		/>
	)
}
