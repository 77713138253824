import React from 'react'
import { useHistory } from 'react-router'
import useSingleTest from '../../../../../data-hooks/Tests/useSingleTest'
import AddEditTestForm from '../components/AddEditTestForm/AddEditTestForm'
import TestView from '../components/TestView/TestView'

export default function EditTest() {
	const history = useHistory()
	const { update, test, setTest } = useSingleTest()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setTest(res.data)
		return res.data
	}
	
	return (
		<TestView>
			<AddEditTestForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				test={test}
			/>
		</TestView>
	)
}
