import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled.a`
	color: #0000EE;
    text-decoration: underline;
`

export {
	StyledLink,
}
