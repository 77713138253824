import React from 'react'
import AddEditCustomerForm from '../components/AddEditCustomerForm/AddEditCustomerForm'
import { useHistory } from 'react-router'
import { CUSTOMERS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import useCustomers from '../../../../../data-hooks/Customers/useCustomers'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function AddCustomer() {
	const { create } = useCustomers()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(CUSTOMERS_PATH)
		return res.data
	}
	
	return (
		<div>
			<Header>
				{t('add_customer')}
			</Header>
			<LargeFormLayout>
				<AddEditCustomerForm
					cancelHandler={() => {
						history.goBack()
					}}
					submitHandler={submitHandler}
				/>
			</LargeFormLayout>
		</div>
	)
}
