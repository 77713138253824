import React from 'react'
import { useHistory } from 'react-router'
import useSingleLab from '../../../../../data-hooks/Labs/useSingleLab'
import AddEditLabForm from '../components/AddEditLabForm/AddEditLabForm'
import LabView from '../components/LabView/LabView'

export default function EditLab() {
	const history = useHistory()
	const { update, lab, setLab } = useSingleLab()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setLab(res.data)
		return res.data
	}
	
	return (
		<LabView>
			<AddEditLabForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				lab={lab}
			/>
		</LabView>
	)
}
