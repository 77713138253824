import React, { CSSProperties, ReactElement, ElementType, useMemo } from 'react'
import { QuickTableHeaders } from 'react-ui-scaffold'
import Button from '../Button/Button'
import { PageButtonContainer, StyledQuickTable } from './Table.styled'

export interface Column {
	header: string | ReactElement | null
	styles?: {
		header?: CSSProperties
		content?: CSSProperties
		common?: CSSProperties
	}
	Content: ElementType
}

interface Props {
	columns: QuickTableHeaders
	dataKey: string
	hook: () => any
	data?: Array<{[key:string]: any}>
	disablePagination?: Boolean
	primaryKey?: string,
	hover?: boolean,
}

export default function Table({ columns, dataKey, hook, data, disablePagination, primaryKey='_id', hover=true }: Props) {
	const hooked = hook()
	const _data: Array<any> = data ? data : hooked[dataKey]
	const { setPage, page, hasMorePages } = hooked
	
	const [nextPage, previousPage] = useMemo(() => {
		const toReturn = []
		if (hasMorePages) {
			const nextPage = () => {
				setPage(page + 1)
			}
			toReturn.push(nextPage)
		} else {
			toReturn.push(undefined)
		}
		if (page == 1) {
			toReturn.push(undefined)
		} else {
			const previousPage = () => {
				setPage(page - 1)
			}
			toReturn.push(previousPage)
		}
		return toReturn
	}, [page, setPage, hasMorePages])

	return (
		<div>
			<StyledQuickTable
				primaryKey={primaryKey}
				data={_data}
				headers={columns}
				hover={hover}
			/>
			
			{!disablePagination && <PageButtonContainer>
				{ previousPage && <Button onClick={previousPage} className={'negative'}>Previous</Button> }
				{ nextPage && <Button onClick={nextPage} className={'negative'}>Next</Button> }
			</PageButtonContainer>}
		</div>
	)
}
