import { Order, CreateOrderParams } from '../../../../common/types/OrderTypes'
import React, { useContext, createContext, useEffect, useState, useCallback, useRef } from 'react'
import { createOrder, deleteOrder, getOrders } from '../../api/orders'
import useOrganization from '../useOrganization'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	orders: Order[],
	isLoading: boolean,
	create(c:CreateOrderParams):Promise<AxiosResponse<Order>>,
	searchText: string,
	setSearchText(s:string): void,
	del(labId:string): Promise<void>,
	page: number,
	setPage(newPage:number): void,
	hasMorePages: boolean,
	replace(order: Order): void,
}>(null as any)


interface ProviderProps{
	children:any
}

export const OrdersProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [orders, setOrders] = useState<Order[]>([])
	
	const [searchText, setTrueSearchText] = useState('')
	const [page, setPage] = useState(1)
	const [hasMorePages, setHasMorePages] = useState(true)
	const debounce = useRef<any>()
	
	const setSearchText = (s:string) => {
		setTrueSearchText(s)
		clearTimeout(debounce.current)
		
		const str = s.trim()
		
		if (str) {
			debounce.current = setTimeout(() => {
				getOrders({ searchText: str }).then(({ data: { docs } }) => setOrders(docs))
			}, 300)
		} else {
			debounce.current = setTimeout(() => {
				getOrders({}).then(({ data: { docs } }) => setOrders(docs))
			}, 300)
		}
	}
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getOrders({ page, searchText })
			.then(({ data: { docs, hasMorePages } }) => {
				setOrders(docs)
				setHasMorePages(hasMorePages)
			})
			.finally(() => setIsLoading(false))
	}, [organization._id, page, searchText])
	
	const create = useCallback(async (params:CreateOrderParams) => {
		const newOrder = await createOrder(params)
		await getOrders({}).then(({ data: { docs } }) => setOrders(docs))
		return newOrder
	}, [organization._id])
	
	const del = useCallback(async (labId: string) => {
		await deleteOrder(labId)
		setOrders(curr => curr.filter(c => c._id !== labId))
	}, [])

	const replace = useCallback((order: Order) => {
		const index = orders.findIndex(({ _id }) => _id == order._id)
		let newOrders = [...orders]
		if (index != -1) {
			newOrders[index] = order
			setOrders(newOrders)
		}
	}, [orders])
	
	return <Context.Provider value={{
		orders,
		isLoading,
		create,
		searchText,
		setSearchText,
		del,
		page,
		setPage,
		hasMorePages,
		replace,
	}}>
		{ children }
	</Context.Provider>
}

const useOrders = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useOrders outside provider!')
	}
	return val
}

export default useOrders
