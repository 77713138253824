import styled from 'styled-components'

const ButtonRow = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	label {
		font-size: 16px;
		font-weight: bold;
	}
`

export {
	ButtonRow,
	Container,
}
