import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useDockState } from './DockStateProvider'

interface Props{
	children: any,
	onClick?():any,
	to: string,
	title?: string
	defaultPath?: boolean
}

export default function DockButton({ children, to, title, defaultPath }:Props) {
	const location = useLocation()
	const activeClass = useMemo(() => location.pathname?.startsWith(`${to}`) || (defaultPath && location.pathname == '/') ? ' live ' : '', [location.pathname, to])
	const { setHidden } = useDockState()
	
	return (
		<Link to={to} className='dockButtonContainer' onClick={() => setHidden(true)}>
			<button className={`button DockButton wide plain ${activeClass}`}>
				{children} <span className="titleBox">{title}</span>
			</button>
		</Link>
	)
}
