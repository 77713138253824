import React from 'react'
import { Route, Switch } from 'react-router'
import { AppointmentsProvider } from '../../../../data-hooks/Appointments/useAppointments'
import { APPOINTMENTS_PATH, APPOINTMENT_ADD_PATH, APPOINTMENT_EDIT_PATH, APPOINTMENT_PATH } from '../../../../../../common/paths'
import AddAppointment from './AddAppointment/AddAppointment'
import Appointments from './Appointments/Appointments'
import { SingleAppointmentProvider } from '../../../../data-hooks/Appointments/useSingleAppointment'
import EditAppointment from './EditAppointment/EditAppointment'
import ViewAppointment from './ViewAppointment/ViewAppointment'

export default function AppointmentRoutes() {

	return (
		<AppointmentsProvider>
			<SingleAppointmentProvider>
				<Switch>
					<Route exact path={APPOINTMENT_ADD_PATH} component={AddAppointment}/>
					<Route path={APPOINTMENT_PATH} component={ViewAppointment}/>
					<Route path={APPOINTMENT_EDIT_PATH} component={EditAppointment}/>
					<Route exact path={APPOINTMENTS_PATH} component={Appointments}/>
				</Switch>
			</SingleAppointmentProvider>
		</AppointmentsProvider>
	)
}
