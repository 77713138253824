import React from 'react'
import { CreateTestParams } from '../../../../common/types/TestTypes'
import BaseMenu from '../BaseMenu/BaseMenu'
import { useTranslation } from 'react-i18next'
import AddEditTestForm from '../../pages/Main/pages/Tests/components/AddEditTestForm/AddEditTestForm'

export interface CreateTestMenuProps{
	onSubmit(c:CreateTestParams): any
	close?: Function
}

export default function CreateTestMenu({ onSubmit }: CreateTestMenuProps) {
	const { t }  = useTranslation()
	
	const cancelHandler = () => {
		close && close()
	}
	const submitHandler = async (data:any) => {
		return await onSubmit(data)
	}
	
	return (
		<BaseMenu>
			<h1>{t('create_test')}</h1>
			<AddEditTestForm cancelHandler={cancelHandler} submitHandler={submitHandler} />
		</BaseMenu>
	)
}
