import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const MessageContainer = styled.div`
	width: 200px;
	max-height: 100vh;
	overflow-y: hidden;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 502;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Message = styled.div<{ type: 'success' | 'error'}>`
	padding: 4px 5px;
	border-radius: 5px;
	background-color: #f1f1f1;
	border-left: 3px solid ${({ type }) => type == 'success' ? 'green' : 'red'};
`

const MessageTitle = styled.div`
	font-weight: bold;
	font-size: 17px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const CloseButton = styled(FontAwesomeIcon)`
	cursor: pointer;
	font-size: 16px;
`

const MessageBody = styled.div`

`

export {
	CloseButton,
	Message,
	MessageBody,
	MessageContainer,
	MessageTitle,
}
