import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AsyncButton, Popup } from 'react-ui-scaffold'
import useOrganization from '../../../../../data-hooks/useOrganization'

export default function Active() {
	const { t } = useTranslation()
	
	const [showCancel, setShowCancel] = useState(false)
	const [loading, setLoading] = useState(false)
	const { cancelOrganizationSubscription } = useOrganization()

	const _cancelOrganizationSubscription = async () => {
		setLoading(true)
		cancelOrganizationSubscription()
			.catch(() => {
				setLoading(false)
			})
	}
	
	return (
		<div>
			<h2>
				{t('subscription')}
			</h2>
			<p>
				{t('active_subscription')}
			</p>
			
			<button className="button plain light" onClick={() => setShowCancel(true)}>
				{t('cancel_subscription')}
			</button>
			
			<Popup visible={showCancel} setVisible={setShowCancel} title={`${t('cancel_subscription')}?`}>
				{t('cancel_subscription_are_you_sure')}
				<div className='marginBottom marginTop'>
					<button className="button wide neutral" onClick={() => setShowCancel(false)}>No</button>
					<p>
						<AsyncButton loading={loading} className="button wide light plain" onClick={_cancelOrganizationSubscription}>Yes</AsyncButton>
					</p>
				</div>
			</Popup>
		</div>
	)
}
