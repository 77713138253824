import React, { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import SelectPanelView from './SelectPanelView/SelectPanelView'


export type SelectBoxOption = { label: string, value: any }

interface Props {
	onValueChange(newValue:SelectBoxOption):any
	value: any,
	wide?: boolean,
	error?: any,
	className?: string,
	options: SelectBoxOption[],
	label?: string,
	name?: string,
	clearable?: boolean,
}

const SelectBox = React.forwardRef<any, Props>(({
	className,
	value,
	wide,
	error,
	onValueChange,
	options,
	label,
	name,
	clearable,
}: Props, ref) => {
	return (
		<>
			{label && <label>{label}</label>}
			<SelectPanelView
				wide={wide}
				error={error}
				className={className}
				value={value}
				onValueChange={onValueChange}
				options={options}
				clearable={clearable}
			/>
		</>
	)
})


interface FormProps extends Props {
	name: string
	rules?: any
	onValueChange(newValue:SelectBoxOption):any
}
export const SelectBoxForm = forwardRef<any, FormProps>((props, ref) => {
	const form = useFormContext()
	const onValueChange = (newValue: SelectBoxOption) => {
		form.setValue(props.name, newValue)
		props.onValueChange(newValue)
	}

	return (
		<div>
			<SelectBox {...props} value={form.watch(props.name)} error={form.formState.errors[props.name]} onValueChange={onValueChange} {...form.register(props.name, props.rules || {})}/>
			<ErrorMessage name={props.name} />
		</div>
	)
})

export default SelectBox
