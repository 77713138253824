import { Order } from '../../../../../../../../common/types/OrderTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import { useTranslation } from 'react-i18next'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { Link } from 'react-router-dom'
import { createOrderEditPath } from '../../../../../../../../common/paths'
import { email_regex } from '../../../../../../utils/rules'
import PhoneInput from '../../../../../../components/inputs/PhoneInput'
import TestProfileSelect from '../../../../../../components/inputs/resourceSelects/TestProfileSelect/TestProfileSelect'
import { SelectBoxOption } from '../../../../../../components/inputs/selects/SelectBox'
import { FileInputForm } from '../../../../../../components/inputs/FileInput/FileInput'
import { DatePickerForm } from '../../../../../../components/inputs/DatePicker/DatePicker'
import moment from 'moment'
import { RequisitionRow } from './AddEditOrderForm.styled'
import useOrganization from '../../../../../../data-hooks/useOrganization'
import OrganizationMemberSelect from '../../../../../../components/inputs/resourceSelects/OrganizationMemberSelect/OrganizationMemberSelect'

interface OrderForm {
	name?: string,
	email?: string,
	phoneNo?: string,
	testProfile?: SelectBoxOption,
	dob?: string,
	organizationMember: null | SelectBoxOption,
}

interface Props{
	order?: Order,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void
}

export default function AddEditOrderForm({
	order,
	submitHandler,
	cancelHandler,
}:Props) {
	const { organization: { members } } = useOrganization()
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const form = useForm<OrderForm>()
	const { handleSubmit, register, formState: { errors }, setValue, reset } = form
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	useEffect(() => {
		if (order) {
			setValue('name', order.name)
			setValue('email', order.email)
			setValue('phoneNo', order.phoneNo)
			if (order.dob) {
				const momented = moment(order.dob)
				setValue('dob', momented.format('YYYY-MM-DD'))
			}
			if (order.testProfile) {
				setValue('testProfile', { label: order.testProfile.code, value: order.testProfile._id })
			}
			if (order.organizationMember) {
				const organizationMember = members.find((member) => member._id == order.organizationMember)
				if (organizationMember) {
					setValue('organizationMember', {
						label: `${organizationMember?.user.firstName} ${organizationMember?.user.lastName}`,
						value: organizationMember._id,
					})
				}
			}
		}
	}, [order])

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		const formData = new FormData()
		if (e.testProfile) {
			formData.append('testProfile', e.testProfile?.value)
		}
		delete e.testProfile
		if (e.organizationMember) {
			formData.append('organizationMember', e.organizationMember?.value)
		}
		delete e.organizationMember
		Object.keys(e).map((key: string) => {
			formData.append(key, e[key])
		})
		submitHandler(formData)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(order?._id ? 'order_updated' : 'order_created'),
					content: (
						<Link target='_blank' to={createOrderEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if  (order?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(order?._id ? 'order_update_failed' : 'order_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
			>
				<TextBox
					wide
					placeholder={t('name') as string}
					label={t('name') as string}
					error={errors.name}
					{...register('name', { required: true })}
				/>
				<TextBox
					wide
					placeholder={t('email') as string}
					label={t('email') as string}
					error={errors.email}
					{...register('email', {
						validate: {
							email_regex,
						},
					})}
				/>
				<PhoneInput name='phoneNo' />
				<DatePickerForm name='dob' label={t('dob') as string} />
				<TestProfileSelect clearable />
				<OrganizationMemberSelect defaultValue={order?.organizationMember} clearable />
				<RequisitionRow>
					<FileInputForm name='image' label={t(order?.requisitionImageUrl ? 'overwrite_requisition' : 'upload_requisition') as string} />
					{order?.requisitionImageUrl && <a href={order.requisitionImageUrl} target='_blank' rel='noreferrer'>{t('view_requisition')}</a>}
				</RequisitionRow>
			</Form>
		</FormProvider>
	)
}
