import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SectionTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 4px;
`

const CustomerCard = styled.div`
	display: flex;
	justify-content: space-between;
	border: 1px solid var(--borderColor);
	border-radius: var(--smallRadius);
	padding: 10px 14px;
	background-color: white;
	cursor: pointer;
`

const CustomerArrow = styled(FontAwesomeIcon)`
	margin: auto 0;
	font-size: 24px;
`

const CustomerData = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
`

const CustomerDataTitle = styled.span`
	margin-right: 4px;
	font-weight: bold;
`

const CustomerCardsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

export {
	SectionTitle,
	CustomerCard,
	CustomerData,
	CustomerDataTitle,
	CustomerArrow,
	CustomerCardsContainer,
}
