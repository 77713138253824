import styled from 'styled-components'
import _AsyncButton from '../AsyncButton'

const commonStyles = `
	&.small {
		padding: 6px 8px;
	}
	&.positive:not(.hollow) {
		color: #fff;
		background-color: #55bb55;
		svg {
			color: #fff !important;	
		}
	}
	&.negative {
		color: #fff !important;
		background-color: #555555;
		svg {
			color: #fff !important;	
		}
	}
	&.primary {
		color: #fff;
		background-color: var(--navColor);
		svg {
			color: #fff !important;	
		}
	}
	&.primary.hollow {
		color: var(--navColor);
		border: 1px solid var(--navColor);
		background-color: #FFF;
		svg {
			color: var(--navColor) !important;	
		}
	}
	&.secondary {
		color: var(--mainTextColor);
		background-color: #E1E1E1;
		svg {
			color: var(--mainTextColor) !important;	
		}
	}
`

const Button = styled.button`
	border: none;
	border-radius: 5px;
	padding: 8px 12px;
	cursor: pointer;
	outline: none !important;
	font-size: 15px;
	font-family: inherit;
	font-weight: bold;
	display: flex;
	gap: 8px;
	${commonStyles}
`

const AsyncButton = styled(_AsyncButton)`
	${commonStyles}
`

export default Button
export {
	AsyncButton,
}
