import React, { useEffect, useMemo, useState } from 'react'
import { SelectBoxForm, SelectBoxOption } from '../../selects/SelectBox'
import { SelectContainer } from './OrganizationMemberSelect.styled'
import { useTranslation } from 'react-i18next'
import ResourceSelectProps from '../IResourceSelectProps'
import useOrganization from '../../../../data-hooks/useOrganization'
import { OrganizationMember } from '../../../../../../common/types/OrganizationTypes'

export default function OrganizationMemberSelect({ defaultValue, onChange, rules, clearable }: ResourceSelectProps<OrganizationMember>) {
	const { organization: { members } } = useOrganization()
	const [value, setValue] = useState<SelectBoxOption>()
	const { t } = useTranslation()
	const options: Array<SelectBoxOption> = useMemo(() => {
		return members.map((member) => {
			return {
				label: `${member.user.firstName} ${member.user.lastName}`,
				value: member._id as string,
			}
		})
	}, [members])

	useEffect(() => {
		if (defaultValue) {
			const member = options.find(({ value }) => value == defaultValue)
			if (member) {
				setValue(member)
			}
		}
	}, [defaultValue])


	const onValueChange = (e: SelectBoxOption) => {
		onChange?.(e)
		setValue(e)
	}

	return (
		<SelectContainer>
			<SelectBoxForm
				onValueChange={onValueChange}
				value={value}
				options={options}
				label={t('assignee') as string}
				rules={rules}
				name='organizationMember'
				clearable={clearable}
			/>
		</SelectContainer>
	)
}

