import React, { useContext, createContext } from 'react'
import { PageMessageContents } from 'react-ui-scaffold'

const Context = createContext<{
	setToast(m:PageMessageContents): void,
	toast?: PageMessageContents
}>(null as any)


interface ProviderProps{
	children:any
	setToast(m: PageMessageContents): void,
	toast?: PageMessageContents
}

export const ToastProvider = ({ children, setToast, toast }: ProviderProps) => {
	return <Context.Provider value={{
		setToast,
		toast,
	}}>
		{ children }
	</Context.Provider>
}

const useToast = () => {
	const val = useContext(Context)
	return val
}

export default useToast
