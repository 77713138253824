import React, { useState } from 'react'
import { Popup, QuickTableCellProps } from 'react-ui-scaffold'
import Wysiwyg from '../../inputs/Wysiwyg/Wysiwyg'
import TextArea from '../../inputs/TextArea'
import { CELL_SPAN_STYLE } from '../cellStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

interface Props extends QuickTableCellProps {
	onEdit?(row:any, newValue:string): Promise<any> | any
	title: string,
	type?: string,
}

const TextAreaWrapper = styled.span`
	textarea {
		min-height: 350px;
	}
`
export default function TextAreaCell({ cell, type, title }: Props) {
	const [popupOpen, setPopupOpen] = useState<boolean>(false)
	return (
		<>
			<Popup
				visible={popupOpen}
				onExit={() => {
					setPopupOpen(false)
				}}
				title={title}
				setVisible={setPopupOpen}
				style={{
					width: 'min(100%, 500px)',
				}}
				className='textAreaPopup'
			>
				<TextAreaWrapper>
					{type == 'wysiwyg' ? (
						<Wysiwyg
							name='wysiwyg'
							defaultValue={cell}
							wide
							readOnly
						/>
					) : (
						<TextArea
							value={cell}
							wide
							readOnly
						/>
					)}
				</TextAreaWrapper>
			</Popup>
			<span
				style={{
					...CELL_SPAN_STYLE,
					cursor: 'pointer',
					minWidth: '100px',
				}}
				title={cell}
				onClick={() => setPopupOpen(true)}
			>
				{cell && <button className="button plain light" onClick={() => setPopupOpen(true)}>
					<FontAwesomeIcon icon={faEye} />
				</button>}
			</span>
		</>
	)
}
