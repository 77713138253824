const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
const postalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

function email_regex(v: string | undefined) {
	if (!v) {
		return true
	}
	return emailRegex.test(v)
}

function phone_regex(v: string | undefined) {
	if (!v) {
		return true
	}
	return phoneRegex.test(v)
}

function postal_code_regex(v: string | undefined) {
	if (!v) {
		return true
	}
	return postalCodeRegex.test(v.toUpperCase())
}

function positive(v: string | number | undefined) {
	if (!v) {
		return true
	}
	return parseInt(`${v || 0}`) > 0
}

function array_required(v: Array<any>) {
	return v && Boolean(v.length)
}

export {
	email_regex,
	phone_regex,
	positive,
	array_required,
	postal_code_regex,
}
