import { PricingDetails } from '../../../common/types/PricingTypes'
import axios, { urls } from './axios'

export const createSubscriptionCheckoutSession = () => axios.get<string>(`${urls.organization}/subscription/session`)

export const activateSubscription = () => axios.post(`${urls.organization}/subscription/activate`)

export const cancelSubscription = () => axios.delete(`${urls.organization}/subscription`)

export const getPricing = () => axios.get<PricingDetails>(`${urls.base}api/getPricing`)
