import React from 'react'
import AddEditLabForm from '../components/AddEditLabForm/AddEditLabForm'
import { useHistory } from 'react-router'
import { LABS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import useLabs from '../../../../../data-hooks/Labs/useLabs'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function AddLab() {
	const { create } = useLabs()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(LABS_PATH)
		return res.data
	}
	
	return (<>
		<Header>
			{t('add_lab')}
		</Header>
		<LargeFormLayout>
			<AddEditLabForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
			/>
		</LargeFormLayout>
	</>)
}
