import React from 'react'
import { Route, Switch } from 'react-router'
import { TestProfilesProvider } from '../../../../data-hooks/TestProfiles/useTestProfiles'
import { TEST_PROFILES_PATH, TEST_PROFILE_ADD_PATH, TEST_PROFILE_EDIT_PATH, TEST_PROFILE_PATH, TEST_PROFILE_TO_APPOINTMENT_PATH } from '../../../../../../common/paths'
import AddTestProfile from './AddTestProfile/AddTestProfile'
import TestProfiles from './TestProfiles/TestProfiles'
import { SingleTestProfileProvider } from '../../../../data-hooks/TestProfiles/useSingleTestProfile'
import EditTestProfile from './EditTestProfile/EditTestProfile'
import ViewTestProfile from './ViewTestProfile/ViewTestProfile'
import AppointmentFromTestProfile from './AppointmentFromTestProfile/AppointmentFromTestProfile'

export default function TestProfilesRoutes() {

	return (
		<TestProfilesProvider>
			<SingleTestProfileProvider>
				<Switch>
					<Route exact path={TEST_PROFILE_ADD_PATH} component={AddTestProfile}/>
					<Route path={TEST_PROFILE_PATH} component={ViewTestProfile}/>
					<Route path={TEST_PROFILE_EDIT_PATH} component={EditTestProfile}/>
					<Route exact path={TEST_PROFILES_PATH} component={TestProfiles}/>
					<Route exact path={TEST_PROFILE_TO_APPOINTMENT_PATH} component={AppointmentFromTestProfile}/>
				</Switch>
			</SingleTestProfileProvider>
		</TestProfilesProvider>
	)
}
