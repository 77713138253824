import React from 'react'
import { useHistory } from 'react-router'
import useSingleAppointment from '../../../../../data-hooks/Appointments/useSingleAppointment'
import AddEditAppointmentForm from '../components/AddEditAppointmentForm/AddEditAppointmentForm'
import AppointmentView from '../components/AppointmentView/AppointmentView'

export default function EditAppointment() {
	const history = useHistory()
	const { update, appointment, setAppointment } = useSingleAppointment()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setAppointment(res.data)
		return res.data
	}
	
	return (
		<AppointmentView>
			<AddEditAppointmentForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				appointment={appointment}
			/>
		</AppointmentView>
	)
}
