import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FreeButton, Page, PageMessageContents } from 'react-ui-scaffold'
import TextBox from '../../components/inputs/TextBox'
import Form from '../../Form/Form'
import { useTranslation } from 'react-i18next'
import { email_regex } from '../../utils/rules'
import { Divider, ForgotPasswordLink, LoginContainer, LogoContainer } from './Register.styled'
import LoginWithGoogle from '../../components/LoginWithGoogle/LoginWithGoogle'
import useAuth from '../../data-hooks/useAuth'
import { useHistory, useLocation } from 'react-router'
import { LOGIN_PATH, RESET_PASSWORD_PATH } from '../../../../common/paths'
import logo from '../../assets/logo_full.svg'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default function Register() {
	const { t } = useTranslation()
	const { createAccount } = useAuth()
	const history = useHistory()
	const { search } = useLocation()
	const [message, setMessage] = useState<PageMessageContents>()
	const [showForgotYourPassword, setShowForgotYourPassword] = useState<boolean>(false)
	const form = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			passwordConfirmation: '',
		},
	})
	const { register, formState: { errors }, watch, handleSubmit } = form
	
	const registerAccount = (body: { [key: string]: string }) => {
		setShowForgotYourPassword(false)
		createAccount({ email: body.email, firstName: body.firstName, lastName: body.lastName, password: body.password })
			.then((res) => {
				console.log(`${LOGIN_PATH}${search}`)
				history.push(`${LOGIN_PATH}${search}`)
			})
			.catch(({ response }) => {
				if (response?.status == 422 && response?.data?.error) {
					setMessage({
						content: t(response.data?.error),
						sticky: true,
						color: 'var(--negativeAccentColor)',
						icon: faExclamationCircle,
					})
					if (response?.data?.error == 'auth/email-already-exists') {
						setShowForgotYourPassword(true)
					}
				}
			})
	}

	return (
		<Page className='Login' message={message}>
			<div className="flexCenter loginBody">
				<div className="loginCard">
					<LogoContainer className="logo">
						<img src={logo} />
					</LogoContainer>
					<FormProvider {...form}>
						<Form showButtons={false} onSubmit={handleSubmit(registerAccount)}>
							<TextBox
								wide
								placeholder={t('email') as string}
								label={t('email') as string}
								error={errors.email}
								{...register('email', {
									validate: {
										email_regex,
									},
								})}
							/>
							<TextBox
								wide
								label={t('first_name') as string}
								error={errors.firstName}
								{...register('firstName', {
									required: true,
								})}
							/>
							<TextBox
								wide
								label={t('last_name') as string}
								error={errors.lastName}
								{...register('lastName', {
									required: true,
								})}
							/>
							<TextBox
								wide
								label={t('password') as string}
								error={errors.password}
								type='password'
								{...register('password', {
									required: true,
								})}
							/>
							<TextBox
								wide
								label={t('password_confirmation') as string}
								error={errors.passwordConfirmation}
								type='password'
								{...register('passwordConfirmation', {
									required: true,
									validate: (val: string) => {
										if (watch('password') != val) {
											return 'passwords_dont_match'
										}
									},
								})}
							/>
							<FreeButton className="button wide cion" type='submit'>{t('register')}</FreeButton>
							{showForgotYourPassword && <ForgotPasswordLink to={RESET_PASSWORD_PATH}>{t('forgot_password')}</ForgotPasswordLink>}
						</Form>
					</FormProvider>
					<LoginContainer>
						<Divider />
						<LoginWithGoogle setMessage={setMessage} />
						<FreeButton className="button wide neutral plain" onClick={() => history.push(LOGIN_PATH)}>{t('login_instead')}</FreeButton>
					</LoginContainer>
				</div>
			</div>
		</Page>
	)
}
