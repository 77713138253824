import React from 'react'
import { CreateLabParams } from '../../../../common/types/LabTypes'
import BaseMenu from '../BaseMenu/BaseMenu'
import AddEditLabForm from '../../pages/Main/pages/Labs/components/AddEditLabForm/AddEditLabForm'
import { useTranslation } from 'react-i18next'

export interface CreateLabMenuProps{
	onSubmit(c:CreateLabParams): any
	close?: Function
}

export default function CreateLabMenu({ onSubmit }: CreateLabMenuProps) {
	const { t }  = useTranslation()
	
	const cancelHandler = () => {
		close && close()
	}
	const submitHandler = async (data:any) => {
		return await onSubmit(data)
	}
	
	return (
		<BaseMenu>
			<h1>{t('create_lab')}</h1>
			<AddEditLabForm cancelHandler={cancelHandler} submitHandler={submitHandler} />
		</BaseMenu>
	)
}
