export const OrganizationAccessLevels = {
	admin: 'admin',
	member: 'member',
}

export const OrganizationInviteStatuses = {
	accepted: 'Accepted',
	rejected: 'Rejected',
	pending : 'Pending',
}

export const OrganizationSubscriptionStates = {
	free: 'Free', // all billing waived for this organization
	active: 'Active',
	trial: 'Trial',
	cancelled: 'Cancelled',
	lapsed: 'Lapsed' // Cancelled due to billing failure
}
