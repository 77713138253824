import React, { ReactElement, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../../../ErrorMessage/ErrorMessage'
import Line from '../../../Line/Line'
import TextBox from '../../TextBox'
import { SelectBoxOption } from '../SelectBox'
import SelectPanelView from '../SelectPanelView/SelectPanelView'
import { Container } from './AsyncSelectSearch.styled'

interface FetchOptionsResponse {
	newOptions: SelectBoxOption[],
	hasMorePages?: boolean,
}
interface Props {
	name?: string;
	onValueChange(newValue?: SelectBoxOption): any
	value: any,
	wide?: boolean,
	error?: any,
	className?: string,
	fetchOptions({ searchText, page }: { searchText: string, page: number }): Promise<FetchOptionsResponse> | FetchOptionsResponse,
	defaultOptions?: SelectBoxOption[],
	label?: string,
	append?: ReactElement,
	defaultSearch?: string,
	clearable?: boolean,
}

const AsyncSelectSearch = React.forwardRef<any, Props>(({
	name,
	className,
	value,
	wide,
	error,
	onValueChange,
	fetchOptions,
	defaultOptions,
	label,
	append,
	defaultSearch,
	clearable,
}: Props, ref) => {
	const textBoxRef = useRef<HTMLInputElement>()
	const [search, setSearch] = useState(defaultSearch || '')
	const [isLoading, setIsLoading] = useState(true)
	const [hasMorePages, setHasMorePages] = useState(false)
	const [page, setPage] = useState(1)

	const [filteredOptions, setFilteredOptions] = useState<SelectBoxOption[]>(defaultOptions || [])
	
	const debounced = useRef<any>()
	
	const changeHandler = async (e:any) => {
		setSearch(e.target.value)
		
		clearTimeout(debounced.current)
		debounced.current = setTimeout(async () => {
			setIsLoading(true)
			setPage(1)
			const { newOptions, hasMorePages } = await fetchOptions({ searchText: e.target.value, page: 1 })
			setHasMorePages(Boolean(hasMorePages))
			setFilteredOptions(newOptions)
			setIsLoading(false)
		}, 500)
	}
	const openHandler = () => {
		textBoxRef.current && textBoxRef.current.focus()
		
		debounced.current = setTimeout(async () => {
			setIsLoading(true)
			setPage(1)
			const { newOptions, hasMorePages } = await fetchOptions({
				searchText: search,
				page: 1,
			})
			setHasMorePages(Boolean(hasMorePages))
			setFilteredOptions(newOptions)
			setIsLoading(false)
		}, 0)
	}

	const loadMore = () => {
		if (!isLoading && hasMorePages) {
			clearTimeout(debounced.current)
			debounced.current = setTimeout(async () => {
				const newPage = page + 1
				setIsLoading(true)
				setPage(newPage)
				const { newOptions, hasMorePages } = await fetchOptions({ searchText: search, page: newPage })
				setHasMorePages(Boolean(hasMorePages))
				setFilteredOptions([...filteredOptions, ...newOptions])
				setIsLoading(false)
			}, 0)
		}
	}

	return (
		<div className='wide'>
			{label && <label htmlFor={name}>{label}</label>}
			<Container>
				<SelectPanelView
					wide={wide}
					error={error}
					className={className}
					value={value}
					onValueChange={onValueChange}
					options={filteredOptions}
					onOpen={openHandler}
					onClose={() => textBoxRef.current && textBoxRef.current.blur()}
					loadMore={loadMore}
					isLoading={isLoading}
					clearable={clearable}
				>
					<div className="searchContainer">
						<TextBox
							placeholder='Search'
							wide
							ref={textBoxRef}
							value={search}
							onChange={changeHandler}
							name={name}
						/>
						<Line />
					</div>
				</SelectPanelView>
				{append}
			</Container>
		</div>
	)
})

interface FormProps extends Props {
	name: string
	rules?: any,
	defaultSearch?: string,
}
export function AsyncSelectSearchForm (props: FormProps) {
	const form = useFormContext()
	const onValueChange = (newValue: SelectBoxOption) => {
		form.setValue(props.name, newValue)
		props.onValueChange(newValue)
	}

	return (
		<>
			<AsyncSelectSearch
				{...props}
				value={form.watch(props.name)}
				error={form.formState.errors[props.name]}
				onValueChange={onValueChange}
				{...form.register(props.name, props.rules || {})}
			/>
			<ErrorMessage name={props.name} />
		</>
	)
}
export default AsyncSelectSearchForm
