import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Page } from 'react-ui-scaffold'
import { createOrderToAppointmentStep1Path, CUSTOMERS_PATH } from '../../../../../../../../common/paths'
import { EntityHeader, Header } from '../../../../../../components'
import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'
import useOrders from '../../../../../../data-hooks/Orders/useOrders'
import useSingleOrder from '../../../../../../data-hooks/Orders/useSingleOrder'
import EditIcons from '../../../../../../components/EditIcons/EditIcons'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function OrderView({ children }: { children: ReactElement }) {
	const { params }:any = useRouteMatch()
	const { order, isLoading, setOrderId } = useSingleOrder()
	const { del } = useOrders()
	const history = useHistory()
	const [showDelete, setShowDelete] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		setOrderId(params.id)
	}, [params.id])
	
	if (isLoading || !order) {
		return <Page loading/>
	}

	const deleteHandler = async () => {
		await del(order._id)
		history.push(CUSTOMERS_PATH)
	}

	return (
		<div>
			<ConfirmPopup
				title={t('delete_entity', { entity: order.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={setShowDelete}
				visible={showDelete}
			/>
			<Header>
				<EntityHeader>
					{`${order.name}`}
				</EntityHeader>
				<EditIcons
					items={[
						{ label: t('convert_to_appointment') as string, color: 'var(--negativeAccentColor)', onClick: () => history.push(createOrderToAppointmentStep1Path(order._id)) },
						{ label:  t('delete') as string, color: 'var(--negativeAccentColor)', onClick: () => setShowDelete(true) },
					]}
				/>
			</Header>
			<LargeFormLayout>
				{children}
			</LargeFormLayout>
			
		</div>)

}
