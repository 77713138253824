import styled from 'styled-components'

const ErrorText = styled.div`
	color: var(--negativeAccentColor);
	padding-left: 4px;
	font-size: 14px;
`

export {
	ErrorText,
}
