import React from 'react'
import { Route } from 'react-router-dom'
import useAuth from '../../data-hooks/useAuth'
import ProtectedRoute from '../ProtectedRoute'
import VerifyEmailScreen from './components/VerifyEmailScreen'

interface Props {
	children?: any,
	path: string,
	component?: any,
}

export default function VerifiedRoute({ children, path, component }: Props) {
	const { user } = useAuth()

	if (user && !user.emailVerified) {
		return <Route path={path} component={() => <VerifyEmailScreen />} />
	}

	return <ProtectedRoute path={path} component={component}>{children}</ProtectedRoute>
}
