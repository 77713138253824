import { Customer } from '../../../../../../../../common/types/CustomerTypes'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../../../components/inputs/TextBox'
import { FormProvider, useForm } from 'react-hook-form'
import Form from '../../../../../../Form/Form'
import { useTranslation } from 'react-i18next'
import useMessageQueue from '../../../../../../hooks/MessageQueue/useMessageQueue'
import { Link } from 'react-router-dom'
import { createCustomerEditPath } from '../../../../../../../../common/paths'
import { email_regex } from '../../../../../../utils/rules'
import PhoneInput from '../../../../../../components/inputs/PhoneInput'
import PostalCodeInput from '../../../../../../components/inputs/PostalCodeInput'

interface Props{
	customer?: Partial<Customer>,
	submitHandler: Function,
	cancelHandler: (v: boolean) => void,
	showCancel?: boolean,
}

export default function AddEditCustomerForm({
	customer,
	showCancel,
	submitHandler,
	cancelHandler,
}:Props) {
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const form = useForm({
		defaultValues: {
			name: '',
			email: '',
			phoneNo: '',
			postalCode: '',
		},
	})
	
	const { handleSubmit, register, formState: { errors }, setValue, reset } = form
	const { t } = useTranslation()
	const [loading, setLoading] = useState<boolean>(false)
	useEffect(() => {
		if (customer) {
			setValue('name', customer.name || '')
			setValue('email', customer.email || '')
			setValue('phoneNo', customer.phoneNo || '')
			setValue('postalCode', customer.postalCode || '')
		}
	}, [customer])

	const internalSubmitHandler = (e: any) => {
		setLoading(true)
		submitHandler(e)
			.then((res: any) => {
				addSuccessMesasage({
					title: t(customer?._id ? 'customer_updated' : 'customer_created'),
					content: (
						<Link target='_blank' to={createCustomerEditPath(res._id)}>{t('click_to_view')}</Link>
					),
				})
				if (customer?._id) {
					reset()
				}
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t(customer?._id ? 'customer_update_failed' : 'customer_create_failed'),
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={handleSubmit(internalSubmitHandler)}
				showButtons
				cancelHandler={cancelHandler}
				loading={loading}
				showCancel={showCancel}
			>
				<TextBox
					wide
					placeholder={t('name') as string}
					label={t('name') as string}
					error={errors.name}
					{...register('name', { required: true })}
				/>
				<TextBox
					wide
					placeholder={t('email') as string}
					label={t('email') as string}
					error={errors.email}
					{...register('email', {
						validate: {
							email_regex,
						},
					})}
				/>
				<PhoneInput name='phoneNo' />
				<PostalCodeInput name='postalCode' />
			</Form>
		</FormProvider>
	)
}
