import React, { useEffect, useState } from 'react'
import { createContext, useContext } from 'react'
import { AxiosResponse } from 'axios'
import { PricingDetails } from '../../../common/types/PricingTypes'
import { getPricing } from '../api/subscriptions'

const PricingContext = createContext<{
	pricing: PricingDetails
}>(null as any)

export function PricingProvider({ children }: any) {
	const [pricing, setPricing] = useState<PricingDetails>({ basePrice: 0, extraUserPrice: 0, basePriceIncludedUsers: 0 })

	useEffect(() => {
		getPricing().then((res) => {
			setPricing(res.data)
		})
	}, [])

	return <PricingContext.Provider value={{
		pricing,
	}}>
		{children}
	</PricingContext.Provider>
}

export default function usePricing() {
	const val = useContext(PricingContext)
	if (!val) {
		throw new Error('usePricing outside provider!')
	}
	return val
}
