import styled from 'styled-components'


const EditIconsRow = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`

export {
	EditIconsRow,
}
