import styled from 'styled-components'
import Icon from '../../../Icon/Icon'

const ActionRow = styled.div`
	display: flex;
	gap: 8px;
`

const ActionIcon = styled(Icon)`
	cursor: pointer;
	color: #777777;
`

export {
	ActionRow,
	ActionIcon,
}
