import React from 'react'
import { Route, Switch } from 'react-router'
import { OrdersProvider } from '../../../../data-hooks/Orders/useOrders'
import { ORDERS_PATH, ORDER_ADD_PATH, ORDER_EDIT_PATH, ORDER_PATH, ORDER_TO_APPOINTMENT_STEP_1_PATH, ORDER_TO_APPOINTMENT_STEP_2_PATH } from '../../../../../../common/paths'
import AddOrder from './AddOrder/AddOrder'
import Orders from './Orders/Orders'
import { SingleOrderProvider } from '../../../../data-hooks/Orders/useSingleOrder'
import EditOrder from './EditOrder/EditOrder'
import ViewOrder from './ViewOrder/ViewOrder'
import ConvertOrderToAppointment from './ConvertOrderToAppointment/ConvertOrderToAppointment'
import SelectCustomerForOrder from './ConvertOrderToAppointment/SelectCustomerForOrder/SelectCustomerForOrder'

export default function OrdersRoutes() {

	return (
		<OrdersProvider>
			<SingleOrderProvider>
				<Switch>
					<Route exact path={ORDER_ADD_PATH} component={AddOrder}/>
					<Route path={ORDER_PATH} component={ViewOrder}/>
					<Route path={ORDER_EDIT_PATH} component={EditOrder}/>
					<Route path={ORDER_TO_APPOINTMENT_STEP_1_PATH} component={SelectCustomerForOrder}/>
					<Route path={ORDER_TO_APPOINTMENT_STEP_2_PATH} component={ConvertOrderToAppointment}/>
					<Route exact path={ORDERS_PATH} component={Orders}/>
				</Switch>
			</SingleOrderProvider>
		</OrdersProvider>
	)
}
