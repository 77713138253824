import styled from 'styled-components'

const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: var(--mediumSpacing);
`

const Row = styled.div`
	display: flex;
	gap: 8px;
`

export {
	ButtonRow,
	Row,
}
