import styled from 'styled-components'

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	gap: 8px;
`

const Divider = styled.div`
	border-top: solid 1px var(--borderColor);
	width: 100%;
`

const LogoContainer = styled.div`
	padding: 0 !important;
	margin-top: -10px;
	margin-bottom: -16px;
`

export {
	Divider,
	LoginContainer,
	LogoContainer,
}
