import React from 'react'
import { useFormContext } from 'react-hook-form'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import i18n from '../../../i18n'
import { SunEditorWrapper } from './Wysiwyg.styled'

interface Props {
	wide?: boolean,
	error?: any,
	className?: string,
	name: string,
	label?: string,
	onChange?: any,
	defaultValue?: string,
	readOnly?: boolean,
}

const Wysiwyg = ({
	label,
	className,
	wide,
	error,
	name,
	onChange,
	defaultValue,
	readOnly,
	...props
}: Props) => {

	const wideClass = wide ? 'wide' : ''
	return (
		<div>
			{label && <label>{label}</label>}
			<SunEditorWrapper className={`${className} ${wideClass} ${error && 'error'}`}>
				<SunEditor
					{...props}
					name={name}
					defaultValue={defaultValue}
					onChange={onChange}
					readOnly={readOnly}
					hideToolbar={readOnly}
					lang={(i18n.language || 'en') as 'en'}
					
				/>
			</SunEditorWrapper>
		</div>
	)
}

export function WysiwygForm({
	name,
	...props
}: Props) {
	const form = useFormContext<any>()
	const { setValue } = form

	const onSuneditorChange = (value: any) => {
		const regex = /(<([^>]+)>)/gi
		const whitespaceRegex = /&nbsp;|\u2028|\u2029|\u200Bb|\u200Cc|\u200Dd|\uFEFFe/gi
		let hasText = !!value.replaceAll(regex, '').replaceAll(whitespaceRegex, '').length
		value = hasText ? value : ''
		setValue(name, value)
	}

	return <Wysiwyg {...props} name={name} onChange={onSuneditorChange} />

}
export default Wysiwyg
