import React from 'react'
import { StyledLink } from '..'
import { createCustomerEditPath } from '../../../../common/paths'
import { Customer } from '../../../../common/types/CustomerTypes'
import BaseHotlinkProps from './IBaseHotlinkProps'

interface Props extends BaseHotlinkProps {
	customer?: Customer
}
export default function CustomerHotlink({ customer, target }: Props) {
	if (!customer) {
		return null
	}
	return <StyledLink to={createCustomerEditPath(customer._id)} target={target}>{customer.name}</StyledLink>
}
