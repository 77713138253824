import { auth } from '..'
import _axios from 'axios'

async function tokenBuilder() {
	const token = await auth.currentUser?.getIdToken().catch(() => '')
	return token || ''
}

const urls = {
	base: `${process.env.REACT_APP_SERVER_URL}/`,
	baseOrganization: '/api/private/organizations/',
	organization: '',
}
const axios = _axios.create({
	baseURL: urls.base,
})

axios.interceptors.request.use(async (req: any) => {
	req.headers.Authorization = await tokenBuilder()
	return req
})

export default axios
export {
	urls,
}
