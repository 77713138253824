import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { User, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth'
import { accountHasBeenSetUp, createAccount as createAccountAPI, getCurrentAccount, resetPassword as resetPasswordAPI, sendVerificationEmail, updateAccount } from '../api/accounts'
import { Page } from 'react-ui-scaffold'
import type { CionAccount, CreateAccountParams, ResetPasswordParams } from '../../../common/types/UserTypes'
import { useHistory, useLocation } from 'react-router'
import { SETTINGS_PATH } from '../../../common/paths'
import { auth } from '..'
import { AxiosResponse } from 'axios'

export interface EmailAndPasswordParams {
	email: string,
	password: string,
}

const AuthContext = createContext<{
	user: User | null,
	isLoading: boolean,
	loginWithGoogle(): Promise<void>,
	logOut(): any
	account: CionAccount,
	setAccount(newAcc:CionAccount): void,
	error: any,
	verifyEmail(): Promise<AxiosResponse<void>>
	createAccount(body: CreateAccountParams): Promise<any>
	loginWithEmailAndPassword(body: EmailAndPasswordParams): Promise<void>
	accountSetUp(): Promise<void>
	resetPassword(body: ResetPasswordParams): Promise<any>
}>(null as any)

export function AuthProvider({ children }: any) {
	const history = useHistory()
	const location = useLocation()
	const [user, setUser] = useState<User | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [account, setAccount] = useState<CionAccount>(null as any) // null as any, beacsue if you are signed out the rest of the app won't render anyway
	const [error, setError] = useState<any>()
	const { search } = useLocation()
	
	const loginWithGoogle = async () => {
		const provider = new GoogleAuthProvider()
		const googleRes = await signInWithPopup(auth, provider)
		updateAccount({
			//@ts-ignore
			firstName: googleRes._tokenResponse.firstName,
			//@ts-ignore
			lastName: googleRes._tokenResponse.lastName,
		})
	}

	const loginWithEmailAndPassword = async ({ email, password }: { email: string, password: string }) => {
		await signInWithEmailAndPassword(auth, email, password)
			.catch((err: any) => {
				setError({ error: err.code })
				return Promise.reject(err.code)
			})
	}

	const createAccount = async (body: CreateAccountParams) => {
		return await createAccountAPI(body)
	}

	const resetPassword = async (body: ResetPasswordParams) => {
		return await resetPasswordAPI(body)
	}

	const accountSetUp = async () => {
		await accountHasBeenSetUp()
	}

	useEffect(() => {
		setError(null)

		const unsubAuth = auth.onAuthStateChanged(async (newUser) => {
			if (newUser) {
				const acc = await getCurrentAccount().catch((res) => {
					setError(res)
					auth.signOut()
				})
				const path = new URLSearchParams(search).get('path')

				if (path) {
					history.push(path)
				} else if (!acc?.data?.hasDoneSetup && !location.pathname.includes('OrganizationInvites')) {
					history.push(SETTINGS_PATH)
				}

				setAccount(acc?.data || null as any)
			} else {
				setAccount(null as any)
			}

			setUser(newUser)
			setIsLoading(false)
		})
		return unsubAuth
	}, [search])

	// Hook/Provoider files shouldn't be responsible for UI but we're making an exception
	if (isLoading) {
		return <Page loading />
	}

	const logOut = async () => {
		setUser(null)
		await auth.signOut()
	}

	const verifyEmail = sendVerificationEmail

	return <AuthContext.Provider value={{
		user,
		isLoading,
		account,
		logOut,
		verifyEmail,
		error,
		loginWithGoogle,
		createAccount,
		loginWithEmailAndPassword,
		accountSetUp,
		resetPassword,
		setAccount,
	}}>
		{children}
	</AuthContext.Provider>
}

export default function useAuth() {
	const val = useContext(AuthContext)
	if (!val) {
		throw new Error('useAuth outside provider!')
	}
	return val
}
