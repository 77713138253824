import { Customer, CreateCustomerParams, UpdateCustomerParams } from '../../../common/types/CustomerTypes'
import { CreateNoteParams, DeleteNoteParams, GetNotesParams, Note } from '../../../common/types/NoteTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'

export const getCustomers = ({ query, type, category, page, email, name, phoneNo }:{
	type?: string,
	query?: string
	category?: string
	page?: number,
	name?: string,
	email?: string,
	phoneNo?: string,
}) => axios.get<PaginateResponse<Customer[]>>(`${urls.organization}/customers${formatQueryString({ q: query, type, category, page, email, name, phoneNo })}`)

export const createCustomer = (params: CreateCustomerParams) => axios.post<Customer>(`${urls.organization}/customers`, params)

export const getSingleCustomer = (customerId: string) => axios.get<Customer>(`${urls.organization}/customers/${customerId}`)

export const deleteCustomer = (customerId: string) => axios.delete<Customer>(`${urls.organization}/customers/${customerId}`)

export const updateCustomer = (params:UpdateCustomerParams) => axios.patch<Customer>(`${urls.organization}/customers/${params.customerId}`, params)

interface ExtendedGetNotes extends GetNotesParams {
	page?: number
}

export const getNotes = (params: Omit<ExtendedGetNotes, 'parentType'>) => axios.get<PaginateResponse<Note[]>>(`${urls.organization}/customers/${params.parent}/notes${formatQueryString({ ...params })}`)

export const createNote = (params: Omit<CreateNoteParams, 'parentType' | 'createdBy'>) => axios.post<Note>(`${urls.organization}/customers/${params.parent}/notes`, params)

export const deleteNote = (params: DeleteNoteParams) => axios.delete<Note>(`${urls.organization}/customers/note/${params.noteId}`, { params })
