import React from 'react'
import { StyledLink } from '..'
import { createLabEditPath } from '../../../../common/paths'
import { Lab } from '../../../../common/types/LabTypes'
import BaseHotlinkProps from './IBaseHotlinkProps'

interface Props extends BaseHotlinkProps {
	lab?: Lab
}
export default function LabHotlink({ lab, target }: Props) {
	if (!lab) {
		return null
	}
	return <StyledLink to={createLabEditPath(lab._id)} target={target}>{lab.name}</StyledLink>
}
