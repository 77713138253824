import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
	en: {
		translation: {
			'name': 'Name',
			'email': 'Email',
			'phone': 'Phone',
			'postal_code': 'Postal Code',
			'price': 'Price',
			'search': 'Search',
			'cancel': 'Cancel',
			'save': 'Save',
			'edit': 'Edit',
			'reset': 'Reset',
			'free_trial_account': 'Your account is on a free trial. Subscribe to keep your access to Phlevo.',
			'inactive_subscription': 'Your Phlevo subscription is not active. Subscribe to avoid losing access.',
			'active_subscription': 'Your subscription is active, thanks for using Phlevo!',
			'cancel_subscription': 'Cancel Subscription',
			'cancel_subscription_are_you_sure': 'Are you sure you want to cancel your subscription?',
			'delete': 'Delete',
			'create': 'Create',
			'delete_title': 'Delete',
			'delete_entity': 'Delete {{entity}}?',
			'unable_to_sign_in': 'Unable to sign in!',
			'sign_in_with_google': 'Sign in with Google',
			'enter_valid_workspace_name': 'Please enter a valid workspace name',
			'create_workspace': 'Create your workspace',
			'switch_account': 'Switch Account',
			'signed_in_as': 'Signed in as {{email}}',
			'workspace_name': 'Workspace Name',
			'org_dashboard': '{{name}} Dashboard',
			'settings': 'Settings',
			'date': 'Date',
			'time': 'Time',
			'dashboard': 'Dashboard',
			'sign_out': 'Sign Out',
			'subscription': 'Subscription',
			'subscribe': 'Subscribe',
			'click_to_view': 'Click to view',
			'add_customer': 'Add Customer',
			'customers': 'Customers',
			'customer': 'Customer',
			'customer_created': 'Customer Created',
			'customer_updated': 'Customer Updated',
			'customer_create_failed': 'Failed to create customer',
			'customer_update_failed': 'Failed to update customer',
			'create_customer': 'Create Customer',
			'add_lab': 'Add Lab',
			'labs': 'Labs',
			'lab': 'Lab',
			'lab_created': 'Lab Created',
			'lab_updated': 'Lab Updated',
			'lab_create_failed': 'Failed to create lab',
			'lab_update_failed': 'Failed to update lab',
			'add_test': 'Add Test',
			'tests': 'Tests',
			'test': 'Test',
			'create_lab': 'Create Lab',
			'copy_invite_link': 'Copy Invite Link',
			'test_created': 'Test Created',
			'test_updated': 'Test Updated',
			'test_create_failed': 'Failed to create test',
			'test_update_failed': 'Failed to update test',
			'create_test': 'Create Test',
			'add_test_profile': 'Add Test Profile',
			'test_profiles': 'Test Profiles',
			'test_profile': 'Test Profile',
			'test_profile_created': 'Test Profile Created',
			'test_profile_updated': 'Test Profile Updated',
			'test_profile_create_failed': 'Failed to create test profiles',
			'test_profile_update_failed': 'Failed to update test profiles',
			'add_appointment': 'Add Appointment',
			'appointments': 'Appointments',
			'appointment': 'Appointment',
			'appointment_created': 'Appointment Created',
			'appointment_updated': 'Appointment Updated',
			'appointment_create_failed': 'Failed to create appointment',
			'appointment_update_failed': 'Failed to update appointment',
			'create_test_profile': 'Create Test Profile',
			'prerequisites': 'Prerequisites',
			'error_required': 'This field is required',
			'error_email_regex': 'Invalid email',
			'error_phone_regex': 'Invalid phone number',
			'error_postal_code_regex': 'Invalid postal code',
			'error_positive': 'This field must be positive',
			'error_array_required': 'This field must have at least 1 value',
			'invited_member': 'Invited Member',
			'failed_to_invite_member': 'Failed to invite member',
			'invite_member': 'Invite Member',
			'access_level': 'Access Level',
			'admin': 'Admin',
			'member': 'Member',
			'members': 'Members',
			'invites': 'Invites',
			'invite_pending': 'Invite Pending',
			'organization_members': 'Members',
			'access_level_updated': 'Access level updated',
			'failed_to_update_access_level': 'Failed to update access level',
			'removed_member': 'Removed Member',
			'failed_to_remove_member': 'Failed to remove member',
			'revoked_invite': 'Revoked Invite',
			'failed_to_revoke_invite': 'Failed to revoke invite',
			'revoke_invite': 'Revoke Invite?',
			'remove_member': 'Remove Member?',
			'revoke': 'Revoke',
			'update_access_level': 'Update Access Level',
			'remove': 'Remove',
			'add': 'Add',
			'more': 'More',
			'login': 'Login',
			'login_instead': 'Have an account? Sign in',
			'register': 'Register',
			'first_name': 'First Name',
			'last_name': 'Last Name',
			'password': 'Password',
			'password_confirmation': 'Confirm Password',
			'auth/invalid-email': 'Invalid Email',
			'auth/user-not-found': 'User not found',
			'auth/wrong-password': 'Invalid email or password combination',
			'verify_email_required': 'Please verify your email!',
			'passwords_dont_match': 'Passwords do not match',
			'test_profile_for_customer': '{{testProfile}} for {{customer}}',
			'start_date': 'Start Date',
			'end_date': 'End Date',
			'add_order': 'Add Order',
			'orders': 'Orders',
			'order': 'Order',
			'order_created': 'Order Created',
			'order_updated': 'Order Updated',
			'order_create_failed': 'Failed to create order',
			'order_update_failed': 'Failed to update order',
			'create_order': 'Create Order',
			'upcoming_appointments': 'Upcoming Appointments',
			'view_all': 'View All',
			'close': 'Close',
			'id': 'ID',
			'configure': 'Configure',
			'add_pipe_line_step': 'Add Appointment Step',
			'pipe_line_steps': 'Appointment Steps',
			'pipe_line_step': 'Appointment Step',
			'pipe_line_step_created': 'Appointment Step Created',
			'pipe_line_step_updated': 'Appointment Step Updated',
			'pipe_line_step_create_failed': 'Failed to create appointment step',
			'pipe_line_step_update_failed': 'Failed to update appointment step',
			'create_pipe_line_step': 'Create Appointment Step',
			'max_steps_reached': 'Maximum of 10 steps allowed',
			'existing_appointment_with_this_step': 'A appointment is currently on this step. Please move all appointments to a different step and try again.',
			'fax': 'Fax',
			'details': 'Details',
			'notes': 'Notes',
			'your_note': 'Your Note',
			'create_note': 'Create Note',
			'delete_note': 'Delete Note',
			'note_created': 'Note created',
			'note_create_failed': 'Failed to create note',
			'note': 'Note',
			'created_by': 'Created By',
			'step': 'Step',
			'something_went_wrong': 'Something went wrong...',
			'what_happened': 'What Happened?',
			'error_occured': 'It seems like we ran into an error. We want to get to the bottom of this and ensure it doesn\'t happen again. Could you provide a brief note on what you were doing before this happened?',
			'after_submitting': 'After submitting we\'ll refresh the page for you.',
			'convert_to_appointment': 'Convert to Appointment',
			'create_appointment': 'Create Appointment',
			'code': 'Code',
			'turnaround_time': 'Turnaround Time',
			'sample_and_tube': 'Sample and Tube',
			'collection_requirements': 'Collection Requirement',
			'requirements': 'Requirement',
			'temp': 'Temp. (°C)',
			'location': 'Location',
			'on_site': 'On Site',
			'mobile': 'Mobile',
			'type': 'Type',
			'where': 'Where',
			'calendar': 'Calendar',
			'table': 'Table',
			'color': 'Color',
			'assignee': 'Assignee',
			'select_file': 'Select File',
			'upload_requisition': 'Upload Requisition',
			'overwrite_requisition': 'Overwrite Requisition',
			'requisition': 'Requisition',
			'view': 'View',
			'status': 'Status',
			'dob': 'Date of Birth',
			'language': 'Language',
			'verify_your_email': 'Verify Your Email',
			'verify_email_to_complete_registration': 'You will need to verify your email to complete your registration',
			'resend': 'Resend Email',
			'sent': 'Sent!',
			'select_or_create_a_customer': 'Select a customer to continue',
			'create_a_customer': 'Create a new customer',
			'or_select_a_customer': 'Or select an existing customer',
			'view_requisition': 'View Requisition',
			'requisition_provided': 'Requistion Provided',
			'container': 'Container',
			'overview': 'Overview',
			'preparation_instructions': 'Preparation Instructions',
			'specimen_type': 'Specimen Type',
			'storage_and_transport': 'Storage and Transport',
			'volume': 'Volume',
			'test_banks': 'Test Banks',
			'test_bank_descriptions': 'Add our preset tests to your workspace.',
			'trial_ending': 'Oh no, your free trial has ended. Subscribe today to keep using Phlevo!',
			'payment_failed': 'Oh no, your last payment didn\'t go through. Update your payment details to keep using Phlevo,',
			'update_payment_details': 'Update Payment Details',
			'show_more': 'Show More',
			'show_less': 'Show Less',
			'thank_you_for_subscribing': 'Thank you for subscribing!',
			'you_have_successfully_subscribed': 'Welcome to {{ app }}! Let’s redefine your practice together.',
			'back_to_app': 'Back to {{ app }}',
			'trial_is_over': 'Your free trial has ended',
			'issue_with_payment': 'There was an issue with your last payment',
			'to_continue_using': 'To avoid losing access to {{ app }}, update your subscription now.',
			'my_intake_form': 'My Intake Form',
			'intake_form_link_copied': 'Intake form link copied',
			'reset_passowrd': 'Reset Password',
			'forgot_password': 'Forgot your password?',
			'back_to_sign_in': 'Go back to sign in',
			'email_sent': 'Email Sent',
			'auth/email-not-found': 'This email does not match any of our records',
			'auth/email-already-exists': 'An account with this email already exists',
			'delete_appointment': 'Delete appointment with {{ name }}',
			'included_users_warning': 'if you have reached the limit of included users. Once the invitation is accepted, you will start being charged for the extra user',
			'i_understand': 'I Understand',
		},
	},
	fr: {
		translation: {
			'name': 'Nom',
			'email' : 'E-mail',
			'phone' : 'Téléphone',
			'postal_code': 'Code Postal',
			'prix' : 'Prix',
			'search': 'Chercher',
			'cancel': 'Annuler',
			'save': 'Enregistrer',
			'edit' : 'Modifier',
			'reset' : 'Réinitialiser',
			'delete' : 'supprimer',
			'create' : 'Créer',
			'delete_title' : 'Supprimer',
			'delete_entity': 'Supprimer {{entity}} ?',
			'unable_to_sign_in': 'Connexion impossible !',
			'sign_in_with_google' : 'Se connecter avec Google',
			'enter_valid_workspace_name': 'Veuillez entrer un nom d\'espace de travail valide',
			'create_workspace': 'Créez votre espace de travail',
			'switch_account' : 'Changer de compte',
			'signed_in_as': 'Connecté en tant que {{email}}',
			'workspace_name' : 'Nom de l\'espace de travail',
			'org_dashboard' : '{{name}} Tableau de bord',
			'settings' : 'Paramètres',
			'date' : 'Date',
			'time': 'Temps',
			'dashboard' : 'Tableau de bord',
			'sign_out' : 'Déconnexion',
			'click_to_view' : 'Cliquez pour afficher',
			'add_customer' : 'Ajouter un client',
			'customers' : 'Clients',
			'customer' : 'Client',
			'customer_created' : 'Client créé',
			'customer_updated' : 'Client mis à jour',
			'customer_create_failed' : 'Échec de la création du client',
			'customer_update_failed' : 'Échec de la mise à jour du client',
			'create_customer' : 'Créer un client',
			'add_lab' : 'Ajouter un laboratoire',
			'labs' : 'Laboratoires',
			'lab' : 'laboratoire',
			'lab_created' : 'Laboratoire créé',
			'lab_updated' : 'Laboratoire mis à jour',
			'lab_create_failed' : 'Échec de la création de l\'atelier',
			'lab_update_failed': 'Échec de la mise à jour du laboratoire',
			'add_test' : 'Ajouter un test',
			'tests': '',
			'tester': 'Tester',
			'create_lab' : 'Créer un laboratoire',
			'test_created' : 'Test créé',
			'test_updated' : 'Test mis à jour',
			'test_create_failed' : 'Échec de la création du test',
			'test_update_failed': 'Échec de la mise à jour du test',
			'create_test' : 'Créer un test',
			'add_test_profile' : 'Ajouter un profil de test',
			'test_profiles' : 'Tester les profils',
			'test_profile' : 'Tester le profil',
			'test_profile_created' : 'Profil de test créé',
			'test_profile_updated' : 'Profil de test mis à jour',
			'test_profile_create_failed' : 'Échec de la création des profils de test',
			'test_profile_update_failed' : 'Échec de la mise à jour des profils de test',
			'add_appointment' : 'Ajouter un appointmentus',
			'appointments': 'Appointmentus',
			'appointment' : 'Traiter',
			'appointment_created' : 'Appointmentus créé',
			'appointment_updated' : 'Appointmentus mis à jour',
			'appointment_create_failed' : 'Échec de la création du appointmentus',
			'appointment_update_failed' : 'Échec de la mise à jour du appointmentus',
			'create_test_profile' : 'Créer un profil de test',
			'prerequisites': 'Prérequis',
			'error_required': '',
			'error_email_regex': '',
			'error_phone_regex': '',
			'error_postal_code_regex': '',
			'error_positive': '',
			'error_array_required': '',
			'invited_member': '',
			'failed_to_invite_member': '',
			'invite_member': '',
			'access_level': '',
			'admin': '',
			'member': '',
			'members': '',
			'invites': '',
			'invite_pending': 'Invitation envoyée',
			'organization_members': '',
			'update_access_level': 'Changer de rôle',
			'access_level_updated': '',
			'failed_to_update_access_level': '',
			'removed_member': '',
			'failed_to_remove_member': '',
			'revoked_invite': '',
			'failed_to_revoke_invite': '',
			'revoke_invite': '',
			'remove_member': '',
			'revoke': '',
			'remove': '',
			'add': '',
			'more': '',
			'login': '',
			'register': '',
			'first_name': '',
			'last_name': '',
			'password': '',
			'password_confirmation': '',
			'auth/invalid-email': '',
			'auth/user-not-found': '',
			'auth/wrong-password': '',
			'verify_email_required': '',
			'passwords_dont_match': '',
			'test_profile_for_customer': '',
			'start_date': '',
			'end_date': '',
			'orders': '',
			'order': '',
			'order_created': '',
			'order_updated': '',
			'order_create_failed': '',
			'order_update_failed': '',
			'create_order': '',
			'upcoming_appointments': '',
			'view_all': '',
			'close': '',
			'id': '',
			'configure': '',
			'pipe_line_steps': '',
			'pipe_line_step': '',
			'pipe_line_step_created': '',
			'pipe_line_step_updated': '',
			'pipe_line_step_create_failed': '',
			'pipe_line_step_update_failed': '',
			'create_pipe_line_step': '',
			'max_steps_reached': '',
			'existing_appointment_with_this_step': '',
			'fax': '',
			'details': '',
			'notes': '',
			'your_note': '',
			'create_note': '',
			'delete_note': '',
			'note_created': '',
			'note_create_failed': '',
			'note': '',
			'created_by': '',
			'step': '',
			'something_went_wrong': '',
			'what_happened': '',
			'error_occured': '',
			'after_submitting': '',
			'convert_to_appointment': '',
			'create_appointment': '',
			'code': '',
			'turnaround_time': '',
			'sample_and_tube': '',
			'collection_requirements': '',
			'requirements': 'Requirement',
			'temp': '',
			'location': '',
			'on_site': '',
			'mobile': '',
			'type': '',
			'where': '',
			'calendar': '',
			'table': '',
			'color': '',
			'assignee': '',
			'select_file': '',
			'upload_requisition': '',
			'overwrite_requisition': '',
			'requisition': '',
			'view': '',
			'status': '',
			'dob': '',
			'language': 'Langue',
			'verify_your_email': '',
			'verify_email_to_complete_registration': '',
			'resend': '',
			'sent': '',
			'select_or_create_a_customer': '',
			'create_a_customer': '',
			'or_select_a_customer': '',
			'view_requisition': '',
			'requisition_provided': '',
			'container': 'Container',
			'overview': '',
			'preparation_instructions': '',
			'specimen_type': '',
			'storage_and_transport': '',
			'volume': '',
			'test_banks': '',
			'trial_ending': '',
			'payment_failed': '',
			'update_payment_details': '',
			'show_more': '',
			'show_less': '',
			'thank_you_for_subscribing': '',
			'you_have_successfully_subscribed': '',
			'back_to_app': '',
			'trial_is_over': '',
			'issue_with_payment': '',
			'to_continue_using': '',
			'my_intake_form': '',
			'intake_form_link_copied': '',
			'reset_passowrd': '',
			'forgot_password': '',
			'back_to_sign_in': '',
			'email_sent': '',
			'auth/email-not-found': '',
			'auth/email-already-exists': '',
			'delete_appointment': '',
			'included_users_warning': '',
			'i_understand': '',
		},
	},
}

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: localStorage.getItem('lang') || 'en',
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
