import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, createContext, useState, ReactElement } from 'react'
import { CloseButton, Message, MessageBody, MessageContainer, MessageTitle } from './useMessageQueue.styled'

interface IPartialMessage {
	title: string,
	content?: string | ReactElement
	type?: 'success' | 'error'
}
interface IMessage extends IPartialMessage {
	id?: number
	type: 'success' | 'error'
}

const Context = createContext<{
	addMessage: Function,
	addSuccessMesasage: Function,
	addErrorMesasage: Function,
	messages: Array<IMessage>,
}>(null as any)


interface ProviderProps{
	children:any,
}

export const MessageQueueProvider = ({ children }: ProviderProps) => {
	const [messages, setMessages] = useState<Array<IMessage>>([])

	const removeMessage = (idToRemove?: number) => {
		const newMessages = messages.filter(({ id }) => id != idToRemove)
		setMessages(newMessages)
	}
	
	const addMessage = (message: IPartialMessage) => {
		const id = Date.now()
		const newMessage = { ...message, id, type: message.type || 'success' }
		setMessages([...messages, newMessage])
		setTimeout(() => {
			removeMessage(id)
		}, 20000)
		return newMessage
	}
	
	const addSuccessMesasage = (message: IPartialMessage) => {
		return addMessage({ ...message, type: 'success' })
	}

	const addErrorMesasage = (message: IPartialMessage) => {
		return addMessage({ ...message, type: 'error' })
	}

	return <Context.Provider value={{
		messages,
		addMessage,
		addSuccessMesasage,
		addErrorMesasage,
	}}>
		<MessageContainer>
			{messages.map(({ id, title, content, type }, i) => {
				return (
					<Message key={i} type={type}>
						<MessageTitle>
							<div>{title}</div>
							<CloseButton icon={faTimes} onClick={() => removeMessage(id)} />
						</MessageTitle>
						<MessageBody>{content}</MessageBody>
					</Message>
				)
			})}
		</MessageContainer>
		{children}
	</Context.Provider>
}

const useMessageQueue = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useMessageQueue outside provider!')
	}
	return val
}

export default useMessageQueue
