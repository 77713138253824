import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import DropdownButton, { DropdownItem } from 'react-ui-scaffold/components/DropdownButton/DropdownButton'
import { AsyncButton, Popup, SelectBox } from 'react-ui-scaffold'
import { OrganizationInvite } from '../../../../../../../../common/types/OrganizationTypes'
import { CionAccount } from '../../../../../../../../common/types/UserTypes'
import useOrganization from '../../../../../../data-hooks/useOrganization'
import useOrganizationInvites from '../../../../../../data-hooks/useOrganizationInvites'
import useAuth from '../../../../../../data-hooks/useAuth'
import { copyToClipboard } from '../../../../../../utils/clipboardUtils'
import { createInvitePath } from '../../../../../../../../common/paths'
import { useTranslation } from 'react-i18next'
import { OrganizationAccessLevels } from '../../../../../../../../common/enums/OrganizationEnums'
import { SelectBoxOption } from 'react-ui-scaffold/components/inputs/selects/SelectBox'

interface Props{
	user: CionAccount,
	accessLevel: string,
	invite?:OrganizationInvite,
	onAccessLevelChange(memberId: string, accessLevel: string): Promise<void>,
	onInviteAccessLevelChange(inviteId: string, accessLevel: string): Promise<void>
}

export default function MemberRow({ user, accessLevel, invite, onAccessLevelChange, onInviteAccessLevelChange }:Props) {
	const [removing, setRemoving] = useState(false)
	const { removeMember } = useOrganization()
	const { remove } = useOrganizationInvites()
	const { account } = useAuth()
	const [updatingAccess, setUpdatingAccess] = useState(false)
	const { t } = useTranslation()
	
	const accessLevelOptions = useMemo(() => ([
		{ label: t(OrganizationAccessLevels.admin), value: OrganizationAccessLevels.admin },
		{ label: t(OrganizationAccessLevels.member), value: OrganizationAccessLevels.member },
	]), [])
	
	const removeHandler = () => setRemoving(true)
	const updateAccessLevelHandler = () => setUpdatingAccess(true)
	
	const confirmRemove = async () => {
		if (invite) {
			await remove(invite._id)
		} else {
			await removeMember(user._id)
		}
		
		setRemoving(false)
	}
	
	const accessLevelChangeHandler = async (v:SelectBoxOption) => {
		if (invite) {
			await onInviteAccessLevelChange(invite._id, v.value)
		} else {
			await onAccessLevelChange(user._id, v.value)
		}
	}
	
	const inviteOptions:DropdownItem[] = invite ? [
		{ label: t('copy_invite_link'), icon: faCopy, onClick: () => copyToClipboard(`${window.location.host}${createInvitePath(invite._id)}`) },
	] : []
	
	return (
		<div className='MemberRow flexY'>
			<Popup
				title={invite ? `${t('remove')} ${user.email}` : `${t('remove')} ${user.firstName}?` }
				visible={removing}
				setVisible={setRemoving}
			>
				<div className="flexX">
					<AsyncButton className="button hollow negative" style={{ marginRight: 30 }} onClick={confirmRemove}>
						{t('remove')}
					</AsyncButton>

					<button className="button hollow" onClick={() => setRemoving(false)}>
						{t('cancel')}
					</button>
				</div>
			</Popup>
			
			<Popup
				title={invite ? `${t('update_access_level')}: ${user.email}` : `${t('update_access_level')}: ${user.firstName}?`}
				visible={updatingAccess}
				setVisible={setUpdatingAccess}
			>
				<div className="flexX">
					<SelectBox
						wide
						options={accessLevelOptions}
						onValueChange={accessLevelChangeHandler}
						value={{ label: t(accessLevel), value: accessLevel }}
					/>
				</div>
			</Popup>
			
			<Popup
				title={invite ? `Remove ${user.email}` : `Remove ${user.firstName}?`}
				visible={removing}
				setVisible={setRemoving}
			>
				<div className="flexX">
					<AsyncButton className="button hollow negative" style={{ marginRight: 30 }} onClick={confirmRemove}>
						Remove
					</AsyncButton>

					<button className="button hollow" onClick={() => setRemoving(false)}>
						Cancel
					</button>
				</div>
			</Popup>
			
			<div className="dataPoint">
				<div className="name">
					{user.firstName} {user.lastName}
				</div>
				
				<div className="email">
					{user.email}
				</div>
			</div>
			
			<div className='dataPoint'>
				<span className={`accessLevel${accessLevel} accessLevel`}>
					{t(accessLevel)}
				</span>
				
				{invite && <span className={'pendingInvite accessLevel'}>
					{t('invite_pending')}
				</span>}
			</div>
			
			{user._id !== account._id && <div className="controls flexCenter">
				<DropdownButton
					className="plain"
					items={[
						...inviteOptions,
						{ label: t('update_access_level'), onClick: updateAccessLevelHandler },
						{ label: t('remove_member'), color: 'var(--negativeAccentColor)', onClick: removeHandler },
					]}
				>
					<FontAwesomeIcon icon={faEllipsisV}/>
				</DropdownButton>
			</div>}
		</div>
	)
}
