import React, { useEffect } from 'react'
import TextBox from './TextBox'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { phone_regex } from '../../utils/rules'

interface Props {
	label?: string,
	name: string
}
export default function PhoneInput({ label, name }: Props) {
	const { register, formState: { errors }, watch, setValue } = useFormContext()
	const { t } = useTranslation()

	useEffect(() => {
		let val = (watch(name) || '').replace(/ /gm, '')
		let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`
		num = num.trim()
		setValue(name, num)
	}, [watch(name)])

	return (
		<TextBox
			wide
			placeholder={t(label || 'phone') as string}
			label={t(label || 'phone') as string}
			error={errors[name]}
			{...register(name, {
				validate: {
					phone_regex,
				},
			})}
		/>
	)
}
