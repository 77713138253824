import styled from 'styled-components'

const Container = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;
	.SelectBox {
		width: 100%;
	}
`

export {
	Container,
}
