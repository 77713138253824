import { Test, CreateTestParams, UpdateTestParams } from '../../../common/types/TestTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'

export const getTests = ({ searchText, type, category, page }:{
	type?: string,
	searchText?: string
	category?: string
	page?: number
}) => axios.get<PaginateResponse<Test[]>>(`${urls.organization}/tests${formatQueryString({ q: searchText, type, category, page })}`)

export const createTest = (params: CreateTestParams) => axios.post<Test>(`${urls.organization}/tests`, params)

export const getSingleTest = (testId: string) => axios.get<Test>(`${urls.organization}/tests/${testId}`)

export const deleteTest = (testId: string) => axios.delete<Test>(`${urls.organization}/tests/${testId}`)

export const updateTest = (params:UpdateTestParams) => axios.patch<Test>(`${urls.organization}/tests/${params.testId}`, params)
