import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { createOrderToAppointmentStep2Path } from '../../../../../../../../common/paths'
import { Content, Header } from '../../../../../../components'
import useCustomers, { CustomersProvider } from '../../../../../../data-hooks/Customers/useCustomers'
import useSingleOrder, { SingleOrderProvider } from '../../../../../../data-hooks/Orders/useSingleOrder'
import AddEditCustomerForm from '../../../Customers/components/AddEditCustomerForm/AddEditCustomerForm'
import { CustomerArrow, CustomerCard, CustomerCardsContainer, CustomerData, CustomerDataTitle, SectionTitle } from './SelectCustomerForOrder.styled'

function InnerSelectCustomerForOrder() {
	const { create, setSearchParams, customers } = useCustomers()
	const { setOrderId, order } = useSingleOrder()
	const { t } = useTranslation()
	const history = useHistory()
	const { id }:any = useParams()

	useEffect(() => {
		setOrderId(id)
	}, [id])

	useEffect(() => {
		if (order) {
			setSearchParams({
				email: order.email,
				name: order.name,
				phoneNo: order.phoneNo,
			})
		}
	}, [order])

	const onSelectCustomer = (customerId: string) => {
		
		history.push(createOrderToAppointmentStep2Path(id, customerId))
	}

	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(createOrderToAppointmentStep2Path(id, res.data?._id))
		return res.data
	}

	if (!order) {
		return null
	}

	return (<>
		<Header>
			{t('select_or_create_a_customer')}
		</Header>
		<Content>
			<SectionTitle>{t('create_a_customer')}</SectionTitle>
			<AddEditCustomerForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				customer={{
					email: order.email,
					name: order.name || '',
					phoneNo: order.phoneNo,
					postalCode: order.postalCode,
				}}
				showCancel
			/>
			{Boolean(customers.length) && <>
				<SectionTitle>{t('or_select_a_customer')}</SectionTitle>
				<CustomerCardsContainer>
					{customers.map((customer) => {
						return (
							<CustomerCard
								key={customer._id}
								onClick={() => onSelectCustomer(customer._id)}
							>
								<CustomerData>
									<div>
										<CustomerDataTitle>{t('name')}</CustomerDataTitle>
										<span>{customer.name}</span>
									</div>
									<div>
										<CustomerDataTitle>{t('phone')}</CustomerDataTitle>
										<span>{customer.phoneNo}</span>
									</div>
									<div>
										<CustomerDataTitle>{t('email')}</CustomerDataTitle>
										<span>{customer.email}</span>
									</div>
									<div>
										<CustomerDataTitle>{t('postal_code')}</CustomerDataTitle>
										<span>{customer.postalCode}</span>
									</div>
								</CustomerData>
								<CustomerArrow icon={faArrowRight} />
							</CustomerCard>
						)
					})}
				</CustomerCardsContainer>
			</>}
		</Content>
	</>)
}

export default function SelectCustomerForOrder() {
	return (
		<SingleOrderProvider populate={['labId']}>
			<CustomersProvider>
				<InnerSelectCustomerForOrder />
			</CustomersProvider>
		</SingleOrderProvider>
	)
}
