import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useScreenSize } from '../../hooks/useScreenSize'
import { useDockState } from '../../Layouts/MainLayout/DockStateProvider'
import { HamburgerButton, ResponsiveHeader } from './Header.styled'
import Banner from '../Banner/Banner'

export default function Header({ children, className }: any) {
	const { screenSize } = useScreenSize()
	const { setHidden } = useDockState()
	
	return <div>
		<ResponsiveHeader screenSize={screenSize} className={className}>
			{screenSize === 'mobile' && <HamburgerButton className='button plain neutral' onClick={() => setHidden(false)}>
				<FontAwesomeIcon icon={faBars}/>
			</HamburgerButton>}
			{children}
		</ResponsiveHeader>
		<Banner />
	</div>
}
