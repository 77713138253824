import React from 'react'
import AddEditTestForm from '../components/AddEditTestForm/AddEditTestForm'
import { useHistory } from 'react-router'
import { TESTS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import useTests from '../../../../../data-hooks/Tests/useTests'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function AddTest() {
	const { create } = useTests()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(TESTS_PATH)
		return res.data
	}
	
	return (
		<div>
			<Header>
				{t('add_test')}
			</Header>
			<LargeFormLayout>
				<AddEditTestForm
					cancelHandler={() => {
						history.goBack()
					}}
					submitHandler={submitHandler}
				/>
			</LargeFormLayout>
		</div>
	)
}
