import React from 'react'
import TextBox from './TextBox'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { postal_code_regex } from '../../utils/rules'

interface Props {
	name: string
}
export default function PostalCodeInput({ name }: Props) {
	const { register, formState: { errors } } = useFormContext()
	const { t } = useTranslation()

	return (
		<TextBox
			wide
			placeholder={t('postal_code') as string}
			label={t('postal_code') as string}
			error={errors[name]}
			{...register(name, {
				validate: {
					postal_code_regex,
				},
			})}
		/>
	)
}
