import { CreateOrganizationInviteParams, CreateOrganizationParams, Organization, OrganizationInvite, UpdateOrganizationInviteParams, UpdateOrganizationMemberParams, UserPermissionMap } from '../../../common/types/OrganizationTypes'
import axios, { urls } from './axios'

export const createOrganization = (data: CreateOrganizationParams) => axios.post<Organization>('/api/organizations', data)

export const getOrganization = () => axios.get(urls.organization)

export const inviteMemberToOrganization = (params: CreateOrganizationInviteParams) => axios.post<OrganizationInvite>(`${urls.organization}/members`, params)

export const updateOrganizationInvite = (params: UpdateOrganizationInviteParams) => axios.patch<OrganizationInvite>(`${urls.organization}/invites/${params.inviteId}`, params)

export const removeMemberFromOrganization = ({ userId }:{
	userId: string
}) => axios.delete(`${urls.organization}/members/${userId}`)

export const updateOrganizationMember = (params: UpdateOrganizationMemberParams) => axios.patch<Organization>(`${urls.organization}/members/${params.memberId}`, params)

export const getOrganizationPermissions = () => axios.get<UserPermissionMap>(`${urls.organization}/permissions`)

export const getOrganizationInvites = () => axios.get<OrganizationInvite[]>(`${urls.organization}/invites`)

export const getSingleOrganizationInvite = (inviteId: string) => axios.get<OrganizationInvite>(`/api/public/invites/${inviteId}`)

export const respondToOrganizationInvite = ({ inviteId, accepted }:{
	inviteId: string,
	accepted: boolean
}) => axios.put(`/api/public/invites/${inviteId}`, { accepted })

export const deleteOrganizationInvite = (id: string) => axios.delete(`${urls.organization}/invites/${id}`)

export const organizationAccessLevels = ['admin', 'member']
