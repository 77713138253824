import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Lab } from '../../../../../../common/types/LabTypes'
import { createLab, getLabs } from '../../../../api/labs'
import CreateLabMenu, { CreateLabMenuProps } from '../../../../Menus/CreateLabMenu/CreateLabMenu'
import PanelButton, { PanelButtonRef } from '../../../PanelButton/PanelButton'
import { AsyncSelectSearchForm } from '../../selects/AsyncSelectSerach/AsyncSelectSearch'
import { SelectBoxOption } from '../../selects/SelectBox'
import { AddIcon, SelectContainer } from './LabSelect.styled'
import { useTranslation } from 'react-i18next'
import ResourceSelectProps from '../IResourceSelectProps'

export default function LabSelect({ hideAdd, defaultValue, onChange, rules, clearable }: ResourceSelectProps<Lab>) {
	const [value, setValue] = useState<SelectBoxOption>()
	const buttonRef = useRef<PanelButtonRef>(null)
	const { t } = useTranslation()
	const fetchOptions = useCallback(({ searchText, page }) => {
		return getLabs({ searchText, page }).then(({ data: { docs, hasMorePages } }) => {
			return {
				newOptions: docs.map((doc) => {
					return {
						label: doc.name,
						value: doc._id,
					}
				}),
				hasMorePages,
			}
		})
	}, [])

	useEffect(() => {
		if (defaultValue) {
			setValue({ label: defaultValue.name, value: defaultValue._id })
		}
	}, [defaultValue])
	const defaultOptions = useMemo(() => {
		if (defaultValue) {
			return [{ label: defaultValue.name, value: defaultValue._id }]
		}
		return []
	}, [defaultValue])

	const createPanelProps:CreateLabMenuProps = {
		onSubmit: async (newLab) => {
			const res = await createLab(newLab)
			buttonRef.current?.close()
			setValue({ label: res.data.name, value: res.data._id })
			return res.data
		},
	}

	const onValueChange = (e: SelectBoxOption) => {
		onChange?.(e)
		setValue(e)
	}
	const append = useMemo(() => {
		if (hideAdd) {
			return undefined
		}
		return (
			<PanelButton
				ref={buttonRef}
				className="button plain headerButton"
				panelContent={CreateLabMenu}
				panelProps={createPanelProps}
			>
				<AddIcon icon={faPlus} />
			</PanelButton>
		)
	}, [hideAdd])

	return (
		<SelectContainer>
			<AsyncSelectSearchForm
				value={value}
				onValueChange={onValueChange}
				fetchOptions={fetchOptions}
				name='lab'
				defaultOptions={defaultOptions}
				label={t('lab') as string}
				append={append}
				rules={rules}
				clearable={clearable}
			/>
		</SelectContainer>
	)
}

