import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '../../Button/Button'
import { HiddenFileInput, Row } from './FileInput.styled'

interface FileInputFormProps {
	name: string,
	label?: string,
}

interface FileInputProps extends FileInputFormProps {
	onChange?: (e: any) => void,
}

export default function FileInput({ onChange, label, name }: FileInputProps) {
	const { t } = useTranslation()
	const [imageData, setImageData] = useState<any>(null)
	const inputFile = useRef(null)
	return (
		<div>
			{label && <label htmlFor={name}>{label}</label>}
			<Row>
				<Button
					className={'positive'}
					onClick={(e) => {
						e.preventDefault()
						;(inputFile.current as any).click()
					}}
				>
					<Row>
						<FontAwesomeIcon icon={faFileUpload} />
						{t('select_file')}
					</Row>
				</Button>
				{imageData && (
					imageData.name
				)}
				<HiddenFileInput
					className="ImageUploadInput"
					ref={inputFile}
					type="file"
					onChange={(e) => {
						setImageData(e.target?.files?.[0])
						onChange?.(e.target?.files?.[0])
					}}
					accept="image/*"
				/>
			</Row>
		</div>
	)
}

export function FileInputForm({ name, ...props }: FileInputFormProps) {
	const { setValue } = useFormContext()
	const onChange = (e: any) => {
		setValue(name, e)
	}

	return (
		<FileInput onChange={onChange} {...props} name={name} />
	)
}
