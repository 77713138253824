import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { TEST_PROFILE_ADD_PATH } from '../../../../../../../common/paths'
import { TestProfile } from '../../../../../../../common/types/TestProfileTypes'
import { Content, Header } from '../../../../../components'
import Button from '../../../../../components/Button/Button'
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import Table from '../../../../../components/Table/Table'
import useTestProfiles from '../../../../../data-hooks/TestProfiles/useTestProfiles'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import { CurrencyCell, QuickTableHeaders } from 'react-ui-scaffold'
import TestProfileActions from './components/TestProfileActions'
import TestProfilesFilters from './components/TestProfilesFilters/TestProfilesFilters'
import TextAreaCell from '../../../../../components/QuickTableCells/TextAreaCell/TextAreaCell'

export default function TestProfiles () {
	
	const { del } = useTestProfiles()
	const [toDelete, setToDelete] = useState<TestProfile | undefined>()
	const { t } = useTranslation()
	
	const columns: QuickTableHeaders = useMemo(() => ({
		code: { title: t('code') },
		note: {
			title: t('note'),
			component: (props) => <TextAreaCell {...props} title={t('note')} />,
			width: 150,
		},
		specimenType: { title: t('sample_and_tube'), width: 180 },
		temp: { title: t('temp'), width: 150 },
		collectionRequirements: {
			title: t('collection_requirements'),
			component: (props) => <TextAreaCell {...props} title={t('collection_requirements')} type='wysiwyg' />,
			width: 210,
		},
		turnaroundTime: { title: t('turnaround_time'), width: 200 },
		price: { title: t('price'), component: CurrencyCell, width: 150, headerStyle: { textAlign: 'center' } },
		tests: { title: t('tests'), component: ({ cell }) => cell?.length || 0 },
		basicActions: { title: '', component: TestProfileActions, with: 84, props:{
		} },
	}), [])


	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}
	return (<div>
		<Header className='noBorder'>
			{t('test_profiles')}
			<Link to={TEST_PROFILE_ADD_PATH}>
				<Button className='primary small'>
					<Icon icon={faPlus} />
					{t('add')}
				</Button>
			</Link>
			<TestProfilesFilters />
		</Header>
		
		<Content>
			<Table
				columns={columns}
				hook={useTestProfiles}
				dataKey='testProfiles'
			/>
			<ConfirmPopup
				title={t('delete_entity', { entity: toDelete?.code })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
		</Content>
	</div>)
}
