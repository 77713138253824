import React, { useMemo } from 'react'
import { WidgetContainer, WidgetContent, WidgetFooter, WidgetHeader } from '../Dashboard.styled'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/Table/Table'
import useAppointments from '../../../../../data-hooks/Appointments/useAppointments'
import { Link, useHistory } from 'react-router-dom'
import { createAppointmentEditPath, APPOINTMENTS_PATH } from '../../../../../../../common/paths'
import Button from '../../../../../components/Button/Button'
import { DateCell, QuickTableHeaders } from 'react-ui-scaffold'
import ClickToCopy from '../../../../../components/QuickTableCells/ClickToCopy'

export default function UpcomingAppointments() {
	const { t } = useTranslation()
	const { appointments } = useAppointments()
	const history = useHistory()

	const columns: QuickTableHeaders = useMemo(() => ({
		shortId: { title: t('id'), width: 40 },
		name: { title: t('name'), width: 150, component: ({ row }) => <>{row.customer.name}</> },
		email: { title: t('email'), width: 150, component: ({ row, ...props }) => <ClickToCopy row={row} {...props} cell={row.customer.email as string} /> },
		phone: { title: t('phone'), width: 150, component: ({ row, ...props }) => <ClickToCopy row={row} {...props} cell={row.customer.phoneNo as string} />  },
		date: { title: t('date'), component: DateCell },
		
		actions: { title: '', width: 30, props: {
			hideDelete: () => true,
			editHandler: (row:any) => history.push(createAppointmentEditPath(row._id)),
		} },
	}), [])

	return (
		<WidgetContainer>
			<WidgetHeader>{t('upcoming_appointments')}</WidgetHeader>
			<WidgetContent>
				<Table
					columns={columns}
					hook={useAppointments}
					dataKey='appointments'
					data={appointments.reverse().slice(0, 5)}
					disablePagination
					hover={false}
				/>
				<WidgetFooter>
					<Link to={APPOINTMENTS_PATH}>
						<Button className={'secondary'}>{t('view_all')}</Button>
					</Link>
				</WidgetFooter>
			</WidgetContent>
		</WidgetContainer>
	)
}
