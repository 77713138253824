import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Note } from '../../../../common/types/NoteTypes'
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup'
import Icon from '../Icon/Icon'
import Form from '../../Form/Form'
import Table from '../Table/Table'
import { HeaderContainer } from './NotesTable.styled'
import useMessageQueue from '../../hooks/MessageQueue/useMessageQueue'
import TextArea from '../inputs/TextArea'
import { DateCell, NotesCell, Popup, QuickTableHeaders } from 'react-ui-scaffold'
import BasicActions from '../Table/components/BackActions/BasicActions'

interface NotesTableProps {
	hook: () => any
}

export default function NotesTable({ hook }: NotesTableProps) {
	const { addErrorMesasage, addSuccessMesasage } = useMessageQueue()
	const { deleteNote, createNote } = hook()
	const { t } = useTranslation()
	const [showDelete, setShowDelete] = useState<boolean>(false)
	const [createVisible, setCreateVisible] = useState<boolean>(false)
	const [toDelete, setToDelete] = useState<Note>()
	const form = useForm({
		defaultValues: {
			content: '',
		},
	})
	const { handleSubmit, register, formState: { errors } } = form
	const _hook = (): any => {
		const hooked = hook()
		return {
			...hooked,
			hasMorePages: hooked.hasMoreNotePages,
			page: hooked.notePage,
			setPage: hooked.setNotePage,
		}
	}

	const deleteHandler = () => {
		deleteNote({
			noteId: toDelete?._id,
		})
	}

	const internalSubmitHandler = (e: any) => {
		createNote(e)
			.then((res: any) => {
				addSuccessMesasage({
					title: t('note_created'),
				})
				setCreateVisible(false)
				return res
			})
			.catch(() => {
				addErrorMesasage({
					title: t('note_create_failed'),
				})
			})
	}

	const cancelHandler = () => {
		setCreateVisible(false)
	}

	
	const columns: QuickTableHeaders = useMemo(() => ({
		note: { title: t('note'), component: NotesCell },
		createdBy: { title: t('created_by'), width: 150 },
		date: { title: t('date'), component: DateCell },
		
		actions: { title: '', component: BasicActions, width: 30, props: {
			hideEdit: true,
			deleteHandler: (element:Note) => {
				setToDelete(element)
				setShowDelete(true)
			},
		} },
	}), [t])

	return (
		<>
			<HeaderContainer>
				<Icon icon={faPlus} tooltip='create' onClick={() => setCreateVisible(true)} />
			</HeaderContainer>
			
			<Table hook={_hook} columns={columns} dataKey='notes'/>
			
			<ConfirmPopup
				title={t('delete_note')}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={setShowDelete}
				visible={showDelete}
			/>
			<Popup
				title={t('create_note')}
				visible={createVisible}
				setVisible={setCreateVisible}
				className='ConfirmPopup'
			>
				<Form
					showButtons
					cancelHandler={cancelHandler}
					onSubmit={handleSubmit(internalSubmitHandler)}
				>
					<TextArea
						placeholder={t('your_note') as string}
						error={errors.content}
						{...register('content', { required: true })}
					/>
				</Form>
			</Popup>
		</>
	)
}
