import React from 'react'
import './BaseMenu.css'

interface Props{
	children: any
}

export default function BaseMenu({ children }:Props) {
	return (
		<div className='BaseMenu'>
			{children}
		</div>
	)
}
