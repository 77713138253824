import styled from 'styled-components'

const LabsTableWrapper = styled.div`
	.QuickTable {
		.tableHeaders, .tableBody .QuickTableRow {
			width: 100%;
			.headerCell, .cellContainer {
				&:nth-child(2) {
					width: calc(100% - 120px) !important;
				}
			}
		}
	}
`

export {
	LabsTableWrapper,
}
