import React from 'react'
import { useHistory } from 'react-router'
import AsyncButton from '../../components/AsyncButton'
import useAuth from '../../data-hooks/useAuth'
import useSingleOrganizationInvite, { SingleOrganizationInviteProvider } from '../../data-hooks/useSingleOrganizationInvite'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'
import { MAIN_PATH } from '../../../../common/paths'
import { OrganizationProvider } from '../../data-hooks/useOrganization'


function InnerOrganizationInvite() {
	const {
		error,
		isLoading,
		accept,
		reject,
		invite,
	} = useSingleOrganizationInvite()
	
	const history = useHistory()
	const { account, logOut, setAccount } = useAuth()

	const acceptHandler = async () => {
		const res = await accept()
		setAccount({ ...account, activeOrganization: res.data.organization })
		history.push(MAIN_PATH)
	}
	
	const rejectHandler = async () => {
		await reject()
		history.push('/')
	}
	
	const switchAccountHandler = () => {
		logOut()
	}
	
	if (error) {
		return <AuthLayout>
			<h1>{error}</h1>
			<h2>signed in as {account.email}</h2>
			<button className="button hollow" onClick={switchAccountHandler}>Switch Account</button>
			
			<div className="controls">
				<a href='/'>
					<button className="button cion wide">
						Back to home
					</button>
				</a>
			</div>
		</AuthLayout>
	}
	
	return (
		<AuthLayout loading={isLoading}>
			<h1>{invite?.organization.name}</h1>
			<h2>Has invited you to join their workspace</h2>
			
			<div className="controls">
				<AsyncButton className="button cion wide" onClick={acceptHandler}>Accept</AsyncButton>
				<AsyncButton className="button plain" onClick={rejectHandler}>Reject</AsyncButton>
			</div>
		</AuthLayout>
	)
}

export default function OrganizationInvite({ match }:any) {
	return <OrganizationProvider>
		<SingleOrganizationInviteProvider inviteId={match.params.inviteId}>
			<InnerOrganizationInvite/>
		</SingleOrganizationInviteProvider>
	</OrganizationProvider>
}
