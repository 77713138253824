import { faClipboard, faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ORDER_ADD_PATH } from '../../../../../../../common/paths'
import { Order } from '../../../../../../../common/types/OrderTypes'
import { Content, Header } from '../../../../../components'
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import TextBox from '../../../../../components/inputs/TextBox'
import Table from '../../../../../components/Table/Table'
import useOrders from '../../../../../data-hooks/Orders/useOrders'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../components/Icon/Icon'
import TestProfileHotlink from '../../../../../components/hotlinks/TestProfileHotlink'
import { ClickToCopy, DateCell, QuickTableHeaders } from 'react-ui-scaffold'
import Button from '../../../../../components/Button/Button'
import Spacer from '../../../../../components/Spacer/Spacer'
import OrderActions from './components/OrderActions'
import useMessageQueue from '../../../../../hooks/MessageQueue/useMessageQueue'
import useOrganization from '../../../../../data-hooks/useOrganization'

const { REACT_APP_CUSTOMER_APP_URL }= process.env


export default function Orders () {
	const { addSuccessMesasage } = useMessageQueue()
	const { organization } = useOrganization()
	const { del, searchText, setSearchText } = useOrders()
	const [toDelete, setToDelete] = useState<Order | undefined>()
	const { t } = useTranslation()
	
	const columns: QuickTableHeaders = useMemo(() => ({
		shortId: { title: t('id'), width: 40 },
		name: { title: t('name'), width: 200 },
		email: { title: t('email'), width: 300, component: ClickToCopy },
		phoneNo: { title: t('phone'), width: 200, component: ClickToCopy },
		testProfile: { title: t('test_profile'), width: 150, component: ({ row }) => <TestProfileHotlink testProfile={row.testProfile} target='_blank' /> },
		dob: { title: t('dob'), width: 190, component: DateCell },
		requisition: { title: t('requisition_provided'), width: 180, component: ({ row }) => <>{row.requisitionImageUrl ? 'Yes' : 'No'}</> },
		
		actions: { title: '', width: 84, component: OrderActions, props: {
			deleteHandler: (element:any) => setToDelete(element),
		} },
	}), [])

	const searchHandler = (e:any) => {
		setSearchText(e.target.value)
	}

	const deleteHandler = async () => {
		if (toDelete) {
			await del(toDelete._id)
		}
	}
	return (<div>
		<Header>
			{t('orders')}
			<Link to={ORDER_ADD_PATH}>
				<Button className='primary small'>
					<Icon icon={faPlus} />
					{t('add')}
				</Button>
			</Link>
			<Button
				className='primary small'
				onClick={() => {
					navigator.clipboard.writeText(`${REACT_APP_CUSTOMER_APP_URL}${organization.slugWithSuffix}`)
					addSuccessMesasage({
						title: t('intake_form_link_copied'),
					})
				}}
			>
				<Icon icon={faClipboard as any} />
				{t('my_intake_form')}
			</Button>
			<Spacer />
			<TextBox
				placeholder={t('search') as string}
				wide
				onChange={searchHandler}
				value={searchText}
			/>
		</Header>
		
		<Content>
			<Table
				columns={columns}
				hook={useOrders}
				dataKey='orders'
			/>
			<ConfirmPopup
				title={t('delete_entity', { entity: toDelete?.name })}
				confirmText={t('delete') as string}
				confirmClassName='negative'
				onConfirm={deleteHandler}
				setVisible={() => setToDelete(undefined)}
				visible={Boolean(toDelete)}
			/>
		</Content>
	</div>)
}
