import React, { useEffect, useState } from 'react'
import { Popup } from 'react-ui-scaffold'
import useTestBanks, { TestBanksProvider } from '../../../../../../../data-hooks/useTestBanks'
import useOrganization from '../../../../../../../data-hooks/useOrganization'
import AsyncButton from '../../../../../../../components/AsyncButton'
import { useTranslation } from 'react-i18next'
import CheckButton from '../../../../../../../components/inputs/CheckButton'
import { ChecksContainer } from './EditTestBanksModal.style'
import useTests from '../../../../../../../data-hooks/Tests/useTests'

interface Props {
	open: boolean,
	setOpen(v: boolean): any
}
function InnerEditTestBanksModal({ open, setOpen }: Props) {
	const { t } = useTranslation()
	const [localTestBanks, setLocalTestBanks] = useState<Array<string>>([])
	const { organizationTestBanks, testBanks, updateTestBanks } = useTestBanks()
	const { loadPage } = useTests()

	useEffect(() => {
		setLocalTestBanks(organizationTestBanks)
	}, [open])

	const confirmHandler = () => {
		updateTestBanks({ testBanks: localTestBanks }).then(() => {
			setOpen(false)
			loadPage()
		})
	}

	return (
		<Popup
			title={t('test_banks')}
			visible={open}
			setVisible={(v) => setOpen(v)}
			className='ConfirmPopup'
		>
			<ChecksContainer>
				<div>{t('test_bank_descriptions')}</div>
				{testBanks?.map((testBank) => {
					const isChecked = localTestBanks.includes(testBank._id)
					return (<CheckButton
						key={testBank._id}
						value={isChecked}
						checked={isChecked}
						onChange={() => {
							if (isChecked) {
								setLocalTestBanks(localTestBanks.filter((x) => x != testBank._id))
							} else {
								setLocalTestBanks([...localTestBanks, testBank._id])
							}
						}}
					>
						{testBank.name} ({testBank.testCount} {t('tests')})
					</CheckButton>)
				})}
			</ChecksContainer>
			<div className="flexX controlsBox">
				<AsyncButton className='button control' onClick={confirmHandler}>
					{t('save')}
				</AsyncButton>

				<button className="button hollow control" onClick={() => setOpen(false)}>
					{t('cancel')}
				</button>
			</div>
		</Popup>
	)
}

export default function EditTestBanksModal(props: Props) {
	const { organization } = useOrganization()
	
	return (<TestBanksProvider organizationId={organization._id}>
		<InnerEditTestBanksModal {...props} />
	</TestBanksProvider>)
}
