import { Appointment, CreateAppointmentParams, UpdateAppointmentParams, UpdateAppointmentStepParams } from '../../../common/types/AppointmentTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'
import { CreateNoteParams, DeleteNoteParams, GetNotesParams, Note } from '../../../common/types/NoteTypes'

export const getAppointments = ({ filters, type, category, page, pageSize, populate }:{
	type?: string,
	filters?: any
	category?: string
	page?: number
	pageSize?: number
	populate?: string[]
}) => axios.get<PaginateResponse<Appointment[]>>(`${urls.organization}/appointments${formatQueryString({ ...filters, type, category, page, pageSize, populate })}`)

export const createAppointment = (params: CreateAppointmentParams) => axios.post<Appointment>(`${urls.organization}/appointments`, params)

export const getSingleAppointment = (appointmentId: string) => axios.get<Appointment>(`${urls.organization}/appointments/${appointmentId}`)

export const deleteAppointment = (appointmentId: string) => axios.delete<Appointment>(`${urls.organization}/appointments/${appointmentId}`)

export const updateAppointment = (params:UpdateAppointmentParams) => axios.patch<Appointment>(`${urls.organization}/appointments/${params.appointmentId}`, params)

export const updateAppointmentStep = (params:UpdateAppointmentStepParams) => axios.patch<Appointment>(`${urls.organization}/appointments/${params.appointmentId}/appointmentStep`, params)

interface ExtendedGetNotes extends GetNotesParams {
	page?: number
}

export const getNotes = (params: Omit<ExtendedGetNotes, 'parentType'>) => axios.get<PaginateResponse<Note[]>>(`${urls.organization}/appointments/${params.parent}/notes${formatQueryString({ ...params })}`)

export const createNote = (params: Omit<CreateNoteParams, 'parentType' | 'createdBy'>) => axios.post<Note>(`${urls.organization}/appointments/${params.parent}/notes`, params)

export const deleteNote = (params: DeleteNoteParams) => axios.delete<Note>(`${urls.organization}/appointments/note/${params.noteId}`, { params })
