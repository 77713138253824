import React, { CSSProperties } from 'react'
import './Line.css'

interface Props{
	style?:CSSProperties
}

export default function Line({ style }:Props) {
	return (
		<div className='Line' style={style}>
			
		</div>
	)
}
