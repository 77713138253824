import React, { useEffect, useState } from 'react'
import useSingleCustomer from '../../../../../data-hooks/Customers/useSingleCustomer'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import CustomerView from '../components/CustomerView/CustomerView'
import { useHistory, useParams } from 'react-router'
import TabHeaders from '../../../../../components/TabHeaders/TabHeaders'
import { TabContainer } from './ViewCustomer.styled'
import { createCustomerPath } from '../../../../../../../common/paths'
import NotesTable from '../../../../../components/NotesTable/NotesTable'
import CustomerAppointmentTable from './components/CustomerAppointmentTable'
import { AppointmentsProvider } from '../../../../../data-hooks/Appointments/useAppointments'

export default function ViewCustomer() {
	const history = useHistory()
	const { tab } = useParams<any>()
	const { customer } = useSingleCustomer()
	const { t } = useTranslation()
	const [activeTabId, setActiveTabId] = useState<string>(tab)

	useEffect(() => {
		if (activeTabId != tab) {
			history.replace(createCustomerPath(customer?._id, activeTabId))
		}
	}, [activeTabId, tab])

	return (
		<CustomerView>
			{customer && (
				<AppointmentsProvider
					defaultFilters={{ customer: { label: customer.name, value: customer._id } }}
					populate={['lab', 'customer', 'pipelineStep', 'testProfile']}
				>
					<TabHeaders
						activeTabId={activeTabId}
						setActiveTabId={setActiveTabId}
						tabs={[
							{ id: 'Details', title: t('details') },
							{ id: 'Notes', title: t('notes') },
							{ id: 'Appointments', title: t('appointments') },
						]}
					/>
					<TabContainer>
						{activeTabId == 'Details' && <ViewContainer>
							<div>
								<ViewLabel>{t('id')}</ViewLabel>
								<div>{customer?.shortId}</div>
							</div>
							
							<div>
								<ViewLabel>{t('name')}</ViewLabel>
								<div>{customer?.name}</div>
							</div>
							
							<div>
								<ViewLabel>{t('email')}</ViewLabel>
								<div>{customer?.email || '-'}</div>
							</div>
							
							<div>
								<ViewLabel>{t('phone')}</ViewLabel>
								<div>{customer?.phoneNo || '-'}</div>
							</div>
							
							<div>
								<ViewLabel>{t('postal_code')}</ViewLabel>
								<div>{customer?.postalCode || '-'}</div>
							</div>
						</ViewContainer>}
						{activeTabId == 'Notes' && <NotesTable hook={useSingleCustomer} />}
						{activeTabId == 'Appointments' && <CustomerAppointmentTable />}
					</TabContainer>
				</AppointmentsProvider>
			)}
		</CustomerView>
	)
}
