import React from 'react'
import AddEditOrderForm from '../components/AddEditOrderForm/AddEditOrderForm'
import { useHistory } from 'react-router'
import { ORDERS_PATH } from '../../../../../../../common/paths'
import { Header } from '../../../../../components'
import useOrders from '../../../../../data-hooks/Orders/useOrders'
import { useTranslation } from 'react-i18next'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'

export default function AddOrder() {
	const { create } = useOrders()
	const history = useHistory()
	const { t } = useTranslation()
	
	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(ORDERS_PATH)
		return res.data
	}
	
	return (<>
		<Header>
			{t('add_order')}
		</Header>
		<LargeFormLayout>
			<AddEditOrderForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
			/>
		</LargeFormLayout>
	</>)
}
