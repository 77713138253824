import { faCaretDown, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Loading } from 'react-ui-scaffold'
import PanelButton, { PanelButtonRef } from '../../../PanelButton/PanelButton'
import { SelectBoxOption } from '../SelectBox'
import { LoadingContainer, OptionsContainer } from './SelectPanelView.styled'
import { useTranslation } from 'react-i18next'

interface Props{
	options: SelectBoxOption[],
	filteredOptions?: SelectBoxOption[],
	value: any,
	onValueChange(v?:SelectBoxOption):any,
	wide?:boolean,
	className?:string,
	error?:boolean,
	children?:any,
	onOpen?():void,
	onClose?():void,
	loadMore?():void,
	isLoading?: boolean,
	clearable?: boolean,
}

export default function SelectPanelView({
	options,
	filteredOptions,
	value,
	onValueChange,
	wide,
	className,
	error,
	children,
	onOpen,
	onClose,
	loadMore,
	isLoading,
	clearable,
}:Props) {
	const displayOptions = useMemo(() => {
		return filteredOptions || options
	}, [filteredOptions, options])
	const panelButtonRef = useRef<PanelButtonRef>(null)
	const { t } = useTranslation()

	const onScroll = (e: any) => {
		const { scrollHeight, scrollTop, clientHeight } = e.target
		const nearBottom = scrollHeight - scrollTop - clientHeight < 60
		if (nearBottom) {
			loadMore?.()
		}
	}
	
	const items = <>
		{children}
		<OptionsContainer onScroll={onScroll} id='optionContainer'>
			{displayOptions.map((opt, i) => {
				const label = opt.value === value?.value ? <><FontAwesomeIcon icon={faCheck} /> {opt.label}</> : opt.label
				const color = opt.value === value?.value ? 'var(--neutralAccentColor)' : ''

				return <button
					className="button plain wide dropdownButtonItem"
					onClick={() => {
						onValueChange(opt)
						panelButtonRef.current?.close()
					}}
					key={i}
					style={{
						color,
					}}
				>
					{label}
				</button>
			})}
			{isLoading && <LoadingContainer>
				<Loading className='spinner'/>
				{t('loading...')}
			</LoadingContainer>}
		</OptionsContainer>
	</>
	
	const [activeOption, setActiveOption] = useState(() => {
		return options.find(opt => opt.value === value)
	})
	
	useEffect(() => {
		setActiveOption(value)
	}, [options, value])
	
	const wideClass = wide ? 'wide' : ''
	const hasClear = Boolean(clearable && activeOption)
	
	return <PanelButton
		ref={panelButtonRef}
		panelContent={items}
		className={`input SelectBox hollow ${className} ${wideClass} ${error ? 'error' : ''}`}
		widePanel
		panelClassName='selectBoxPanel'
		onClose={onClose}
		onOpen={():any => {
			document.getElementById('optionContainer')?.scroll(0,0)
			return onOpen?.()
		}}
	>
		<div className="selectedOption">{activeOption?.label || ''}</div>
		{hasClear && (
			<span
				className="clear"
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					setActiveOption(undefined)
					onValueChange()
				}}
			>
				<FontAwesomeIcon icon={faTimes} />
			</span>
		)}
		<span className="caret">
			<FontAwesomeIcon icon={faCaretDown} />
		</span>
	</PanelButton>
}
