import React from 'react'
import useOrganization from '../../../../data-hooks/useOrganization'
import { useTranslation } from 'react-i18next'
import { Container, Header } from '../../../../components'
import { useScreenSize } from '../../../../hooks/useScreenSize'
import { Layout, WidgetsContainer } from './Dashboard.styled'
import { AppointmentsProvider } from '../../../../data-hooks/Appointments/useAppointments'
import UpcomingAppointments from './components/UpcomingAppointments'
import OrdersWidget from './components/OrdersWidget'
import { OrdersProvider } from '../../../../data-hooks/Orders/useOrders'

export default function Dashboard() {
	const { screenSize } = useScreenSize()
	const { organization } = useOrganization()
	const { t } = useTranslation()
	
	const name = screenSize === 'mobile' ? '' : organization.name
	
	return (
		<AppointmentsProvider>
			<OrdersProvider>
				<Header>
					{t('org_dashboard', { name })}
				</Header>
				<Container>
					<Layout>
						<WidgetsContainer screenSize={screenSize}>
							<UpcomingAppointments />
						</WidgetsContainer>
						<WidgetsContainer screenSize={screenSize}>
							<OrdersWidget />
						</WidgetsContainer>
					</Layout>
				</Container>
			</OrdersProvider>
		</AppointmentsProvider>
	)
}
