import { Test, UpdateTestParams } from '../../../../common/types/TestTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { getSingleTest, updateTest } from '../../api/tests'
import useTests from './useTests'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	test?: Test,
	testId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateTestParams, 'testId'>): Promise<AxiosResponse<Test>>
	setTest: (test: Test) => void
	setTestId: (test: string) => void
}>(null as any)


interface ProviderProps{
	children:any
}

export const SingleTestProvider = ({ children }: ProviderProps) => {
	const [testId, setTestId] = useState<string>()
	const [test, setTest] = useState<Test>()
	const [isLoading, setIsLoading] = useState(false)
	const { replace } = useTests()
	
	useEffect(() => {
		if (testId) {
			setIsLoading(true)
			
			getSingleTest(testId)
				.then(({ data }) => setTest(data))
				.finally(() => setIsLoading(false))
		}
	}, [testId])
	
	const update = useCallback(async (params: Omit<UpdateTestParams, 'testId'>) => {
		const newTest = await updateTest({
			...params,
			testId: test?._id as string,
		})
		
		replace(newTest.data)
		getSingleTest(test?._id as string).then(({ data }) => setTest(data))
		
		return newTest
	},[test?._id])

	return <Context.Provider value={{
		test,
		testId,
		isLoading,
		update,
		setTest,
		setTestId,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleTest = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleTest outside provider!')
	}
	return val
}

export default useSingleTest
