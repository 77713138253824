import React from 'react'
import Button from '../../Button/Button'
import styled from 'styled-components'
import useAuth from '../../../data-hooks/useAuth'
import useMessageQueue from '../../../hooks/MessageQueue/useMessageQueue'
import { useTranslation } from 'react-i18next'

const Centered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const ButtonRow = styled.div`
	display: flex;
	gap: 4px;
	justify-content: center;
`

export default function VerifyEmailScreen() {
	const { verifyEmail, logOut } = useAuth()
	const { t } = useTranslation()
	const { addSuccessMesasage } = useMessageQueue()
	return (
		<Centered>
			<h2>{t('verify_your_email')}</h2>
			<h4>{t('verify_email_to_complete_registration')}</h4>
			<ButtonRow>
				<Button className='primary' onClick={() => verifyEmail().then(() => addSuccessMesasage({ title: t('sent') }))}>{t('resend')}</Button>
				<Button className='primary hollow' onClick={logOut}>{t('sign_out')}</Button>
			</ButtonRow>
		</Centered>
	)
}
