import React from 'react'
import { useHistory } from 'react-router'
import useSingleTestProfile from '../../../../../data-hooks/TestProfiles/useSingleTestProfile'
import AddEditTestProfileForm from '../components/AddEditTestProfileForm/AddEditTestProfileForm'
import TestProfileView from '../components/TestProfileView/TestProfileView'

export default function EditTestProfile() {
	const history = useHistory()
	const { update, testProfile, setTestProfile } = useSingleTestProfile()
	
	const submitHandler = async (data:any) => {
		const res = await update(data)
		setTestProfile(res.data)
		return res.data
	}
	
	return (
		<TestProfileView>
			<AddEditTestProfileForm
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
				testProfile={testProfile}
			/>
		</TestProfileView>
	)
}
