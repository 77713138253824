import React from 'react'
import useSingleTestProfile from '../../../../../data-hooks/TestProfiles/useSingleTestProfile'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import TestHotlink from '../../../../../components/hotlinks/TestHotlink'
import TextArea from '../../../../../components/inputs/TextArea'
import TestProfileView from '../components/TestProfileView/TestProfileView'
import Wysiwyg from '../../../../../components/inputs/Wysiwyg/Wysiwyg'
import { centToDollar } from '../../../../../utils/dollarUtils'

export default function ViewTestProfile() {
	const { testProfile } = useSingleTestProfile()
	const { t } = useTranslation()
	
	return (
		<TestProfileView>
			<ViewContainer>
				<div>
					<ViewLabel>{t('code')}</ViewLabel>
					<div>{testProfile?.code}</div>
				</div>
				<div>
					<ViewLabel>{t('tests')}</ViewLabel>
					<div>
						{testProfile?.tests.map((test, i) => (
							<span key={test._id}>
								{i > 0 && ', '}
								<TestHotlink test={test} />
							</span>
						))}
					</div>
				</div>
				<div>
					<ViewLabel>{t('note')}</ViewLabel>
					<TextArea value={testProfile?.note} wide readOnly />
				</div>
				<div>
					<ViewLabel>{t('sample_and_tube')}</ViewLabel>
					<div>{testProfile?.specimenType}</div>
				</div>
				<div>
					<ViewLabel>{t('temp')}</ViewLabel>
					<div>{testProfile?.temp}</div>
				</div>
				<div>
					<ViewLabel>{t('collectionRequirements')}</ViewLabel>
					<Wysiwyg
						name='collectionRequirements'
						defaultValue={testProfile?.collectionRequirements}
						wide
						readOnly
					/>
				</div>
				<div>
					<ViewLabel>{t('turnaround_time')}</ViewLabel>
					<div>{testProfile?.turnaroundTime}</div>
				</div>
				<div>
					<ViewLabel>{t('price')}</ViewLabel>
					<div>{testProfile?.price ? `$${centToDollar(testProfile.price)}` : '-'}</div>
				</div>
			</ViewContainer>
		</TestProfileView>
	)
}
