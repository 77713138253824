import { Appointment, UpdateAppointmentParams } from '../../../../common/types/AppointmentTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { createNote as _createNote, deleteNote as _deleteNote, getNotes, getSingleAppointment, updateAppointment } from '../../api/appointments'
import useAppointments from './useAppointments'
import { CreateNoteParams, DeleteNoteParams, Note } from '../../../../common/types/NoteTypes'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	appointment?: Appointment,
	appointmentId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateAppointmentParams, 'appointmentId'>): Promise<AxiosResponse<Appointment>>
	setAppointment: (appointment: Appointment) => void
	setAppointmentId: (appointment: string) => void
	createNote(params: Omit<CreateNoteParams, 'parentType' | 'createdBy' | 'parent'>): Promise<AxiosResponse<Note>>,
	deleteNote(params: DeleteNoteParams): Promise<AxiosResponse<Note>>,
	notes?: Note[],
	notePage: number,
	setNotePage(newPage:number): void,
	hasMoreNotePages: boolean,
}>(null as any)


interface ProviderProps{
	children:any
}
/**
 * Single Appointment context NEEDS to be nested in useAppointments
 */
export const SingleAppointmentProvider = ({ children }: ProviderProps) => {
	const { appointments, replace } = useAppointments()
	const [appointmentId, setAppointmentId] = useState<string>()
	const [appointment, setAppointment] = useState<Appointment>()
	const [notes, setNotes] = useState<Note[]>()
	const [isLoading, setIsLoading] = useState(false)
	const [notePage, setNotePage] = useState(1)
	const [hasMoreNotePages, setHasMoreNotePages] = useState(true)
	
	useEffect(() => {
		if (appointmentId) {
			setIsLoading(true)
			
			getSingleAppointment(appointmentId)
				.then(({ data }) => setAppointment(data))
				.finally(() => setIsLoading(false))
			getNotes({ parent: appointmentId })
				.then(({ data: { docs, hasMorePages } }) => {
					setNotes(docs)
					setHasMoreNotePages(hasMorePages)
				})
		}
	}, [appointmentId])

	useEffect(() => {
		if (appointmentId) {
			getNotes({ parent: appointmentId, page: notePage })
				.then(({ data: { docs, hasMorePages } }) => {
					setNotes(docs)
					setHasMoreNotePages(hasMorePages)
				})
		}
	}, [appointmentId, notePage])
	
	const update = useCallback(async (params: Omit<UpdateAppointmentParams, 'appointmentId'>) => {
		const newAppointment = await updateAppointment({
			...params,
			appointmentId: appointment?._id as string,
		})
		
		replace(newAppointment.data)
		getSingleAppointment(appointment?._id as string).then(({ data }) => setAppointment(data))
		
		
		return newAppointment
	},[appointment?._id, appointments])

	const createNote = useCallback(async (params: Omit<CreateNoteParams, 'parentType' | 'createdBy' | 'parent'>) => {
		const note = await _createNote({
			...params,
			parent: appointment?._id as string,
		})
		
		getNotes({ parent: appointmentId, page: notePage })
			.then(({ data: { docs } }) => setNotes(docs))
		
		return note
	},[appointment?._id, notePage])


	const deleteNote = useCallback(async (params: DeleteNoteParams) => {
		const note = await _deleteNote({
			...params,
		})
		
		getNotes({ parent: appointmentId, page: notePage })
			.then(({ data: { docs } }) => setNotes(docs))
		
		return note
	},[appointment?._id, notePage])

	return <Context.Provider value={{
		appointment,
		appointmentId,
		isLoading,
		update,
		setAppointment,
		setAppointmentId,
		createNote,
		deleteNote,
		notes,
		hasMoreNotePages,
		notePage,
		setNotePage,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleAppointment = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleAppointment outside provider!')
	}
	return val
}

export default useSingleAppointment
