import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SelectContainer = styled.div`
	width: 100%;
	> .SelectBox {
		width: 100%;
	}
`

const AddIcon = styled(FontAwesomeIcon)`

`

export {
	SelectContainer,
	AddIcon,
}
