import { faSyringe } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useHistory } from 'react-router'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { createTestProfileEditPath, createTestProfileToAppointmentPath } from '../../../../../../../../common/paths'
import BasicActions from '../../../../../../components/Table/components/BackActions/BasicActions'
import { ActionIcon } from '../../../../../../components/Table/components/BackActions/BasicActions.styled'
import { Row } from '../TestProfiles.styled'

interface _Props { // eslint-disable-line
	
}

export default function TestProfileActions({ row, setToDelete, cell }:QuickTableCellProps) {
	const history = useHistory()
	
	return (
		<Row>
			<ActionIcon icon={faSyringe} onClick={() => history.push(createTestProfileToAppointmentPath(row._id))} tooltip='create_appointment' />
			
			<BasicActions
				deleteHandler={(profile:any) => {
					setToDelete(profile)
				}}
				row={row}
				cell={cell}
				editHandler={(profile:any) => {
					history.push(createTestProfileEditPath(profile._id))
				}}
			/>
		</Row>
	)
}
