import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { Header } from '../../../../../components'
import AddEditAppointmentForm from '../../Appointments/components/AddEditAppointmentForm/AddEditAppointmentForm'
import useSingleTestProfile, { SingleTestProfileProvider } from '../../../../../data-hooks/TestProfiles/useSingleTestProfile'
import useAppointments, { AppointmentsProvider } from '../../../../../data-hooks/Appointments/useAppointments'
import { createAppointmentEditPath } from '../../../../../../../common/paths'

function InnerAppointmentFromTestProfile() {
	const { create } = useAppointments()
	const { id }:any = useParams()
	const { setTestProfileId, testProfile } = useSingleTestProfile()
	const { t } = useTranslation()
	const history = useHistory()

	useEffect(() => {
		setTestProfileId(id)
	}, [id])

	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(createAppointmentEditPath(res.data._id))
		return res.data
	}

	if (!testProfile) {
		return null
	}

	return (
		<div>
			<Header>
				{t('convert_to_appointment')}
			</Header>
			<AddEditAppointmentForm
				appointment={{
					testProfile,
				}}
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
			/>
		</div>
	)
}

export default function AppointmentFromTestProfile() {
	return (
		<AppointmentsProvider>
			<SingleTestProfileProvider>
				<InnerAppointmentFromTestProfile />
			</SingleTestProfileProvider>
		</AppointmentsProvider>
	)
}
