import React, { InputHTMLAttributes, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	onEnter?(e:any):void,
	wide?: boolean,
	error?: any,
	className?: string,
	name?: string,
	label?: string,
}

const TimePicker = forwardRef<any, Props>(({
	onEnter,
	className,
	error,
	label,
	...props
}, ref) => {
	function keyHandler(e:any) {
		if (e.key === 'Enter' && onEnter) {
			onEnter(e)
		}
	}

	return (
		<div
			className='wide'
		>
			{label && <label htmlFor={props.name}>{label}</label>}
			<input
				{...props}
				id={props.name}
				ref={ref}
				onKeyPress={keyHandler}
				type='time'
				className={`${'input'} ${className} wide ${error && 'error'}`}
			/>
		</div>
	)
})

interface FormProps extends Props {
	name: string
}
export function TimePickerForm (props: FormProps) {
	const form = useFormContext()

	return (
		<TimePicker {...props} {...form.register(props.name)}/>
	)
}

export default TimePicker
