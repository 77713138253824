export const LOGIN_PATH = '/Login'
export const REGISTER_PATH = "/Register";
export const CREATE_ACCOUNT_PATH = '/CreateAccount'
export const VERIFY_EMAIL_PATH = '/VerifyEmail'
export const CREATE_ORGANIZATION_PATH = '/Create'
export const RESET_PASSWORD_PATH = '/ForgotPassword'

export const INVITE_PATH = '/OrganizationInvites/:inviteId'
export const createInvitePath = (inviteId) => `/OrganizationInvites/${inviteId}`

export const MAIN_PATH = ''
export const DASHBOARD_PATH = `${MAIN_PATH}/Dashboard`
export const SETTINGS_PATH = `${MAIN_PATH}/Settings`

export const CUSTOMERS_PATH = `${MAIN_PATH}/Customers`
export const CUSTOMER_ADD_PATH = `${CUSTOMERS_PATH}/Add`;
export const CUSTOMER_PATH = `${CUSTOMERS_PATH}/View/:id/:tab`;
export const CUSTOMER_EDIT_PATH = `${CUSTOMERS_PATH}/Edit/:id`;
export const CUSTOMER_TO_APPOINTMENT_PATH = `${CUSTOMERS_PATH}/ToAppointment/:id`;
export const createCustomerPath = (id, tab = 'Details') => `${CUSTOMERS_PATH}/View/${id}/${tab}`;
export const createCustomerEditPath = (id) => `${CUSTOMERS_PATH}/Edit/${id}`;
export const createCustomerToAppointmentPath = (id) => `${CUSTOMERS_PATH}/ToAppointment/${id}`;

export const LABS_PATH = `${MAIN_PATH}/Labs`;
export const LAB_ADD_PATH = `${LABS_PATH}/Add`;
export const LAB_PATH = `${LABS_PATH}/View/:id`;
export const LAB_EDIT_PATH = `${LABS_PATH}/Edit/:id`;
export const createLabPath = (id) => `${LABS_PATH}/View/${id}`;
export const createLabEditPath = (id) => `${LABS_PATH}/Edit/${id}`;

export const TESTS_PATH = `${MAIN_PATH}/Tests`;
export const TEST_ADD_PATH = `${TESTS_PATH}/Add`;
export const TEST_PATH = `${TESTS_PATH}/View/:id`;
export const TEST_EDIT_PATH = `${TESTS_PATH}/Edit/:id`;
export const createTestPath = (id) => `${TESTS_PATH}/View/${id}`;
export const createTestEditPath = (id) => `${TESTS_PATH}/Edit/${id}`;

export const TEST_PROFILES_PATH = `${MAIN_PATH}/TestProfiles`;
export const TEST_PROFILE_ADD_PATH = `${TEST_PROFILES_PATH}/Add`;
export const TEST_PROFILE_PATH = `${TEST_PROFILES_PATH}/View/:id`;
export const TEST_PROFILE_EDIT_PATH = `${TEST_PROFILES_PATH}/Edit/:id`;
export const TEST_PROFILE_TO_APPOINTMENT_PATH = `${TEST_PROFILES_PATH}/ToAPPOINTMENT/:id`;
export const createTestProfilePath = (id) => `${TEST_PROFILES_PATH}/View/${id}`;
export const createTestProfileEditPath = (id) => `${TEST_PROFILES_PATH}/Edit/${id}`;
export const createTestProfileToAppointmentPath = (id) => `${TEST_PROFILES_PATH}/ToAPPOINTMENT/${id}`;

export const APPOINTMENTS_PATH = `${MAIN_PATH}/Appointments`;
export const APPOINTMENT_ADD_PATH = `${APPOINTMENTS_PATH}/Add`;
export const APPOINTMENT_PATH = `${APPOINTMENTS_PATH}/View/:id/:tab`;
export const APPOINTMENT_EDIT_PATH = `${APPOINTMENTS_PATH}/Edit/:id`;
export const createAppointmentPath = (id, tab = "Details") => `${APPOINTMENTS_PATH}/View/${id}/${tab}`;
	
export const createAppointmentEditPath = (id) => `${APPOINTMENTS_PATH}/Edit/${id}`;
export const createCustomerAppointmentViewPath = (slug, id, passcode) => `/${slug}/apt/${id}/${passcode}`;

export const ORGANIZATION_MEMBERS_PATH = `${MAIN_PATH}/OrganizationMembers`
export const ORGANIZATION_MEMBERS_ADD_PATH = `${MAIN_PATH}/OrganizationMembers/Add`;

export const ORDERS_PATH = `${MAIN_PATH}/Orders`;
export const ORDER_ADD_PATH = `${ORDERS_PATH}/Add`;
export const ORDER_PATH = `${ORDERS_PATH}/View/:id`;
export const ORDER_EDIT_PATH = `${ORDERS_PATH}/Edit/:id`;
export const ORDER_TO_APPOINTMENT_STEP_1_PATH = `${ORDERS_PATH}/ConvertToAppointment/:id/customer`;
export const ORDER_TO_APPOINTMENT_STEP_2_PATH = `${ORDERS_PATH}/ConvertToAppointment/:id/finalize/:customerId`;
export const createOrderPath = (id) => `${ORDERS_PATH}/View/${id}`;
export const createOrderEditPath = (id) => `${ORDERS_PATH}/Edit/${id}`;
export const createOrderToAppointmentStep1Path = (id) => `${ORDERS_PATH}/ConvertToAppointment/${id}/customer`;
	
export const createOrderToAppointmentStep2Path = (id, customerId) => `${ORDERS_PATH}/ConvertToAppointment/${id}/finalize/${customerId}`;
	
export const createCustomerOrderViewPath = (slug, id, passcode) => `/${slug}/order/${id}/${passcode}`;


export const PIPELINE_STEPS_PATH = `${MAIN_PATH}/PipelineSteps`;
export const PIPELINE_STEP_ADD_PATH = `${PIPELINE_STEPS_PATH}/Add`;
export const PIPELINE_STEP_PATH = `${PIPELINE_STEPS_PATH}/View/:id`;
export const PIPELINE_STEP_EDIT_PATH = `${PIPELINE_STEPS_PATH}/Edit/:id`;

export const createPipelineStepPath = (id) => `${PIPELINE_STEPS_PATH}/View/${id}`;
	
export const createPipelineStepEditPath = (id) => `${PIPELINE_STEPS_PATH}/Edit/${id}`;

export const SUBSCRIPTION_CHECKOUT_SUCCESS_PATH = `${MAIN_PATH}/Subscription/Success`
export const SUBSCRIPTION_REQUIRED_PATH = `${MAIN_PATH}/Subscription/Required`
