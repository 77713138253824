import React from 'react'
import useSingleLab from '../../../../../data-hooks/Labs/useSingleLab'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import LabView from '../components/LabView/LabView'

export default function ViewLab() {
	const { lab } = useSingleLab()
	const { t } = useTranslation()
	
	return (
		<LabView>
			<ViewContainer>
				<div>
					<ViewLabel>{t('id')}</ViewLabel>
					<div>{lab?.shortId}</div>
				</div>
				<div>
					<ViewLabel>{t('name')}</ViewLabel>
					<div>{lab?.name}</div>
				</div>
			</ViewContainer>
		</LabView>
	)
}
