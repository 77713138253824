import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { Header } from '../../../../../components'
import AddEditAppointmentForm from '../../Appointments/components/AddEditAppointmentForm/AddEditAppointmentForm'
import useSingleOrder, { SingleOrderProvider } from '../../../../../data-hooks/Orders/useSingleOrder'
import useAppointments, { AppointmentsProvider } from '../../../../../data-hooks/Appointments/useAppointments'
import { createAppointmentEditPath } from '../../../../../../../common/paths'
import LargeFormLayout from '../../../../../Layouts/LargeFormLayout/LargeFormLayout'
import useSingleCustomer, { SingleCustomerProvider } from '../../../../../data-hooks/Customers/useSingleCustomer'
import { CustomersProvider } from '../../../../../data-hooks/Customers/useCustomers'
import Button from '../../../../../components/Button/Button'
import Spacer from '../../../../../components/Spacer/Spacer'

function InnerConvertOrderToAppointment() {
	const { create } = useAppointments()
	const { setCustomerId, customer } = useSingleCustomer()
	const { id, customerId }:any = useParams()
	const { setOrderId, order } = useSingleOrder()
	const { t } = useTranslation()
	const history = useHistory()
	
	useEffect(() => {
		setCustomerId(customerId)
	}, [customerId])

	useEffect(() => {
		setOrderId(id)
	}, [id])

	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(createAppointmentEditPath(res.data._id))
		return res.data
	}
	
	if (!order) {
		return null
	}
	// button in header to view requisition form if possible
	return (
		<>
			<Header>
				{t('convert_to_appointment')}
				<Spacer />
				{order.requisitionImageUrl && (
					<Button onClick={() => window.open(order.requisitionImageUrl, '_blank')}>{t('view_requisition')}</Button>
				)}
			</Header>
			<LargeFormLayout>
				<AddEditAppointmentForm
					defaultCustomerSearch={order.phoneNo || order.email || order.name || ''}
					appointment={{
						testProfile: order.testProfile,
						lab: order.testProfile?.labId,
						price: ((order.testProfile?.price || 0) / 100),
						location: order.location,
						organizationMember: order.organizationMember,
						date: order.date,
						customer,
					}}
					cancelHandler={() => {
						history.goBack()
					}}
					submitHandler={submitHandler}
				/>
			</LargeFormLayout>
		</>
	)
}

export default function ConvertOrderToAppointment() {
	return (
		<CustomersProvider>
			<SingleCustomerProvider>
				<AppointmentsProvider>
					<SingleOrderProvider populate={['labId']}>
						<InnerConvertOrderToAppointment />
					</SingleOrderProvider>
				</AppointmentsProvider>
			</SingleCustomerProvider>
		</CustomersProvider>
	)
}
