import styled from 'styled-components'

const Row = styled.div`
	display: flex;
	gap: 8px;
`

export {
	Row,
}
