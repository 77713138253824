import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { Header } from '../../../../../components'
import AddEditAppointmentForm from '../../Appointments/components/AddEditAppointmentForm/AddEditAppointmentForm'
import useSingleCustomer, { SingleCustomerProvider } from '../../../../../data-hooks/Customers/useSingleCustomer'
import useAppointments, { AppointmentsProvider } from '../../../../../data-hooks/Appointments/useAppointments'
import { createAppointmentEditPath } from '../../../../../../../common/paths'

function InnerAppointmentFromCustomer() {
	const { create } = useAppointments()
	const { id }:any = useParams()
	const { setCustomerId, customer } = useSingleCustomer()
	const { t } = useTranslation()
	const history = useHistory()

	useEffect(() => {
		setCustomerId(id)
	}, [id])

	const submitHandler = async (data:any) => {
		const res = await create(data)
		history.push(createAppointmentEditPath(res.data._id))
		return res.data
	}

	if (!customer) {
		return null
	}

	return (
		<div>
			<Header>
				{t('convert_to_appointment')}
			</Header>
			<AddEditAppointmentForm
				appointment={{
					customer,
				}}
				cancelHandler={() => {
					history.goBack()
				}}
				submitHandler={submitHandler}
			/>
		</div>
	)
}

export default function AppointmentFromCustomer() {
	return (
		<AppointmentsProvider>
			<SingleCustomerProvider>
				<InnerAppointmentFromCustomer />
			</SingleCustomerProvider>
		</AppointmentsProvider>
	)
}
