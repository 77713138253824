import styled from 'styled-components'

const RequisitionRow = styled.div`
	display: flex;
	justify-content: space-between;
	a {
		margin-top: 10px;
	}
`

export {
	RequisitionRow,
}
