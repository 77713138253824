import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { Page } from 'react-ui-scaffold'
import useAuth from '../../data-hooks/useAuth'
import useOrganization, { OrganizationProvider } from '../../data-hooks/useOrganization'
import { CREATE_ORGANIZATION_PATH, CUSTOMERS_PATH, DASHBOARD_PATH, LABS_PATH, ORDERS_PATH, ORGANIZATION_MEMBERS_PATH, PIPELINE_STEPS_PATH, APPOINTMENTS_PATH, SETTINGS_PATH, TESTS_PATH, TEST_PROFILES_PATH, SUBSCRIPTION_CHECKOUT_SUCCESS_PATH, SUBSCRIPTION_REQUIRED_PATH } from '../../../../common/paths'
import Dashboard from './pages/Dashboard/Dashboard'
import LabRoutes from './pages/Labs'
import TestProfileRoutes from './pages/TestProfiles'
import TestRoutes from './pages/Tests'
import CustomerRoutes from './pages/Customers'
import AppointmentRoutes from './pages/Appointments'
import Settings from './pages/Settings/Settings'
import OrganizationMembers from './pages/Settings/OrganizationMembers'
import OrderRoutes from './pages/Orders'
import PipelineStepRoutes from './pages/PipelineSteps'
import MainLayout from '../../Layouts/MainLayout/MainLayout'
import SubscriptionCheckoutSuccess from './pages/SubscriptionCheckoutSuccess/SubscriptionCheckoutSuccess'
import SubscriptionRequired from './pages/SubscriptionRequired/SubscriptionRequired'
import { PricingProvider } from '../../data-hooks/usePricing'

function InnerMain() {
	const auth = useAuth()
	const history = useHistory()
	const { isLoading } = useOrganization()

	if (!auth.account.activeOrganization) {
		history.push(CREATE_ORGANIZATION_PATH)
	}

	return (
		<Page loading={isLoading || auth.isLoading}>
			<MainLayout>
				<Switch>
					<Route path={DASHBOARD_PATH} component={Dashboard} />
					<Route path={SETTINGS_PATH} component={Settings} />
					<Route path={CUSTOMERS_PATH} component={CustomerRoutes} />
					<Route path={LABS_PATH} component={LabRoutes} />
					<Route path={TESTS_PATH} component={TestRoutes} />
					<Route path={TEST_PROFILES_PATH} component={TestProfileRoutes} />
					<Route path={APPOINTMENTS_PATH} component={AppointmentRoutes} />
					<Route path={ORGANIZATION_MEMBERS_PATH} component={OrganizationMembers} />
					<Route path={ORDERS_PATH} component={OrderRoutes} />
					{/* Temporarily disabled */}
					{/* <Route path={PIPELINE_STEPS_PATH} component={PipelineStepRoutes} /> */}
					<Route path={SUBSCRIPTION_CHECKOUT_SUCCESS_PATH} component={SubscriptionCheckoutSuccess}/>
					<Route path={SUBSCRIPTION_REQUIRED_PATH} component={SubscriptionRequired}/>
					
					<Route path={'/'} component={Dashboard}/>
				</Switch>
			</MainLayout>
		</Page>
	)
}

export default function Main() {
	const { account } = useAuth()
	
	return (
		<PricingProvider>
			<OrganizationProvider organizationId={account.activeOrganization}>
				<InnerMain/>
			</OrganizationProvider>
		</PricingProvider>
	)
}
