import { faAddressBook, faBuilding, faClipboard, faCog, faEnvelope, faSyringe, faTimes, faUserCircle, faVial, faVials } from '@fortawesome/free-solid-svg-icons'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import DropdownButton from '../../components/DropdownMenu/DropdownButton'
import useAuth from '../../data-hooks/useAuth'
import { CUSTOMERS_PATH, DASHBOARD_PATH, LABS_PATH, ORDERS_PATH, APPOINTMENTS_PATH, SETTINGS_PATH, TESTS_PATH, TEST_PROFILES_PATH, MAIN_PATH } from '../../../../common/paths'
import DockButton from './DockButton'
import { useTranslation } from 'react-i18next'
import useOrganization from '../../data-hooks/useOrganization'
import { OrganizationAccessLevels } from '../../../../common/enums/OrganizationEnums'
import { useScreenSize } from '../../hooks/useScreenSize'
import { useDockState } from './DockStateProvider'
import logo from '../../assets/logo_white.svg'
import useMessageQueue from '../../hooks/MessageQueue/useMessageQueue'

const { REACT_APP_CUSTOMER_APP_URL }= process.env

export default function Dock() {
	const { addSuccessMesasage } = useMessageQueue()
	const { organization, accessLevel } = useOrganization()
	const { account, logOut } = useAuth()
	const history = useHistory()
	const { t } = useTranslation()

	const { screenSize } = useScreenSize()
	
	const signout = async () => {
		await logOut()
		history.push('/Login')
	}

	const organizationMenuItems = useMemo(() => {
		if (accessLevel == OrganizationAccessLevels.admin) {
			const onClick = () => {
				history.push(SETTINGS_PATH)
			}
			return [
				{ label: <b>{organization.name || ''}</b>, onClick },
			]
		}
		return []
	}, [accessLevel])
	
	const { hidden, setHidden } = useDockState()
	
	return (
		<>
			<div className={`Dock ${hidden ? 'hidden' : ''}`}>
				<img src={logo} />
				{screenSize === 'mobile' && <button className="button plain closeButton" onClick={() => setHidden(true)}>
					<FontAwesomeIcon icon={faTimes}/>
				</button>}
			
				<DockButton to={DASHBOARD_PATH} defaultPath title={t('dashboard') as string}>
					<FontAwesomeIcon icon={faLayerGroup} />
				</DockButton>
				
				<DockButton to={CUSTOMERS_PATH} title={t('customers') as string}>
					<FontAwesomeIcon icon={faAddressBook as any} />
				</DockButton>
			
				<DockButton to={TESTS_PATH} title={t('tests') as string}>
					<FontAwesomeIcon icon={faVial as any} />
				</DockButton>
			
				<DockButton to={TEST_PROFILES_PATH} title={t('test_profiles') as string}>
					<FontAwesomeIcon icon={faVials as any} />
				</DockButton>
			
				<DockButton to={ORDERS_PATH} title={t('orders') as string}>
					<FontAwesomeIcon icon={faEnvelope as any} />
				</DockButton>
			
				<DockButton to={APPOINTMENTS_PATH} title={t('appointments') as string}>
					<FontAwesomeIcon icon={faSyringe as any} />
				</DockButton>
			
				<DockButton to={LABS_PATH} title={t('labs') as string}>
					<FontAwesomeIcon icon={faBuilding as any} />
				</DockButton>
				<div className='spacer' />

				<span className='dockButtonContainer' onClick={() => {
					navigator.clipboard.writeText(`${REACT_APP_CUSTOMER_APP_URL}${organization.slugWithSuffix}`)
					addSuccessMesasage({
						title: t('intake_form_link_copied'),
					})
				}}>
					<button className='button DockButton wide plain'>
						<FontAwesomeIcon icon={faClipboard as any} />
						<span className="titleBox">{t('my_intake_form')}</span>
					</button>
				</span>

				{accessLevel == OrganizationAccessLevels.admin && <DockButton to={SETTINGS_PATH} title={t('settings') as string}>
					<FontAwesomeIcon icon={faCog as any} />
				</DockButton>}
				
				<DropdownButton
					className="DockButton button plain"
					direction='right'
					items={[
						...organizationMenuItems,
						{ label: account.email },
						{ label: t('sign_out'), color: 'var(--negativeAccentColor)', onClick: signout },
					]}
				>
					<FontAwesomeIcon icon={faUserCircle as any}/> <span className='titleBox'>My Account</span>
				</DropdownButton>
			</div>
			
			{(screenSize === 'mobile' && !hidden) && <div className='DockContentMask' onClick={() => setHidden(true)}></div>}
		</>
	)
}
