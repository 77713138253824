import React, { useEffect, useState } from 'react'
import useSingleAppointment from '../../../../../data-hooks/Appointments/useSingleAppointment'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import moment from 'moment'
import CustomerHotlink from '../../../../../components/hotlinks/CustomerHotlink'
import TestProfileHotlink from '../../../../../components/hotlinks/TestProfileHotlink'
import LabHotlink from '../../../../../components/hotlinks/LabHotlink'
import AppointmentView from '../components/AppointmentView/AppointmentView'
import TabHeaders from '../../../../../components/TabHeaders/TabHeaders'
import { createAppointmentPath } from '../../../../../../../common/paths'
import { useHistory, useParams } from 'react-router'
import { TabContainer } from './ViewAppointment.styled'
import NotesTable from '../../../../../components/NotesTable/NotesTable'
import TestHotlink from '../../../../../components/hotlinks/TestHotlink'
import useOrganization from '../../../../../data-hooks/useOrganization'

export default function ViewAppointment() {
	const { organization: { members } } = useOrganization()
	const history = useHistory()
	const { tab } = useParams<any>()
	const { appointment } = useSingleAppointment()
	const { t } = useTranslation()
	const [activeTabId, setActiveTabId] = useState<string>(tab)

	useEffect(() => {
		if (activeTabId != tab) {
			history.replace(createAppointmentPath(appointment?._id, activeTabId))
		}
	}, [activeTabId, tab])

	const member = members.find((member) => member._id == appointment?.organizationMember)
	
	return (
		<AppointmentView>
			<>
				<TabHeaders
					activeTabId={activeTabId}
					setActiveTabId={setActiveTabId}
					tabs={[
						{ id: 'Details', title: t('details') },
						{ id: 'Notes', title: t('notes') },
					]}
				/>
				<TabContainer>
					{activeTabId == 'Details' && <ViewContainer>
						<div>
							<ViewLabel>{t('id')}</ViewLabel>
							<div>{appointment?.shortId}</div>
						</div>
						<div>
							<ViewLabel>{t('customer')}</ViewLabel>
							<div>
								<CustomerHotlink customer={appointment?.customer} target="_blank" />
							</div>
						</div>
						{appointment?.testProfile && <div>
							<ViewLabel>{t('test_profile')}</ViewLabel>
							<div>
								<TestProfileHotlink testProfile={appointment?.testProfile} target="_blank" />
							</div>
						</div>}
						{Boolean(appointment?.tests?.length) && <div>
							<ViewLabel>{t('tests')}</ViewLabel>
							<div>
								{appointment?.tests?.map((test, i) => (
									<span key={test._id}>
										{i > 0 && ', '}
										<TestHotlink test={test} />
									</span>
								))}
							</div>
						</div>}
						<div>
							<ViewLabel>{t('location')}</ViewLabel>
							<div>${appointment?.location}</div>
						</div>
						<div>
							<ViewLabel>{t('price')}</ViewLabel>
							<div>${appointment?.price}</div>
						</div>
						<div>
							<ViewLabel>{t('lab')}</ViewLabel>
							<div>
								<LabHotlink lab={appointment?.lab} target="_blank" />
							</div>
						</div>
						<div>
							<ViewLabel>{t('assignee')}</ViewLabel>
							<div>
								{member && `${member.user.firstName} ${member.user.lastName}`}
							</div>
						</div>
						<div>
							<ViewLabel>{t('date')}</ViewLabel>
							<div>{appointment?.date ? moment(appointment.date).format('YYYY-MM-DD HH:mm') : ''}</div>
						</div>
					</ViewContainer>}
					{activeTabId == 'Notes' && <NotesTable hook={useSingleAppointment} />}
				</TabContainer>
			</>
		</AppointmentView>
	)
}
