import styled from 'styled-components'

const BannerWrapper = styled.div<{ showMore: boolean }>`
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	background-color: #ffeb9c;
	width: ${(props) => {
		return props.theme.screenSize == 'mobile' ? '100vw' : 'calc(100vw - var(--dockWidth))'
	}};
	padding: 4px 8px;
	white-space: ${(props) => props.showMore ? 'break-spaces' : 'nowrap'};
	> div {
		text-overflow: ellipsis;
		overflow: auto;
		width: ${(props) => {
		return props.theme.screenSize == 'mobile' ? 'calc(100% - 80px)' : '100%'
	}};
	}
`

export {
	BannerWrapper,
}
