import { CreateOrganizationInviteParams, OrganizationInvite } from '../../../common/types/OrganizationTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import useOrganization from './useOrganization'
import { deleteOrganizationInvite, getOrganizationInvites, inviteMemberToOrganization, updateOrganizationInvite } from '../api/organizations'

interface UpdateInviteParams {
	accessLevel: string,
	inviteId: string
}

const Context = createContext<{
	invites: OrganizationInvite[],
	isLoading: boolean,
	createInvite(params: Partial<CreateOrganizationInviteParams>): Promise<void>,
	remove(id:string): Promise<void>,
	update(params: UpdateInviteParams): Promise<void>,
}>(null as any)


interface ProviderProps{
	children:any
}

export const OrganizationInvitesProvider = ({ children }: ProviderProps) => {
	const [invites, setInvites] = useState<OrganizationInvite[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const { organization } = useOrganization()
	
	useEffect(() => {
		getOrganizationInvites().then((d) => {
			setInvites(d.data)
			setIsLoading(false)
		})
	}, [organization._id])
	
	const createInvite = useCallback(async (params:Partial<CreateOrganizationInviteParams>) => {
		await inviteMemberToOrganization({
			email: params.email as string,
			accessLevel: params.accessLevel as any,
			organizationId: organization._id,
		})
		await getOrganizationInvites().then(({ data }) => setInvites(data))
	}, [organization._id])
	
	const remove = useCallback(async (id:string) => {
		await deleteOrganizationInvite(id)
		await getOrganizationInvites().then(({ data }) => setInvites(data))
	}, [])

	const update = useCallback(async ({ accessLevel, inviteId }: UpdateInviteParams) => {
		await updateOrganizationInvite({ accessLevel, inviteId, organizationId: organization._id })
		await getOrganizationInvites().then(({ data }) => setInvites(data))
	}, [organization._id])

	return <Context.Provider value={{
		invites,
		isLoading,
		createInvite,
		remove,
		update,
	}}>
		{ children }
	</Context.Provider>
}

const useOrganizationInvites = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useOrganizationInvites outside provider!')
	}
	return val
}

export default useOrganizationInvites
