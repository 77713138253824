import React from 'react'
import useSingleOrder from '../../../../../data-hooks/Orders/useSingleOrder'
import { useTranslation } from 'react-i18next'
import { ViewContainer, ViewLabel } from '../../../../../components'
import TestProfileHotlink from '../../../../../components/hotlinks/TestProfileHotlink'
import OrderView from '../components/OrderView/OrderView'
import { StyledLink } from './ViewOrder.styled'

export default function ViewOrder() {
	const { order } = useSingleOrder()
	const { t } = useTranslation()
	
	return (
		<OrderView>
			<ViewContainer>
				<div>
					<ViewLabel>{t('id')}</ViewLabel>
					<div>{order?.shortId}</div>
				</div>
				<div>
					<ViewLabel>{t('name')}</ViewLabel>
					<div>{order?.name || '-'}</div>
				</div>
				<div>
					<ViewLabel>{t('email')}</ViewLabel>
					<div>{order?.email || '-'}</div>
				</div>
				<div>
					<ViewLabel>{t('phone')}</ViewLabel>
					<div>{order?.phoneNo || '-'}</div>
				</div>
				<div>
					<ViewLabel>{t('test_profile')}</ViewLabel>
					<div>
						<TestProfileHotlink testProfile={order?.testProfile} />
					</div>
				</div>
				{order?.requisitionImageUrl && (<div>
					<ViewLabel>{t('requisition')}</ViewLabel>
					<div>
						<StyledLink href={order.requisitionImageUrl} target='_blank'>{t('view')}</StyledLink>
					</div>
				</div>)}
			</ViewContainer>
		</OrderView>
	)
}
