import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DatePickerForm } from '../../../../../../../components/inputs/DatePicker/DatePicker'
import { ButtonContent, ButtonRow, FilterForm } from './AppointmentsFilters.styled'
import { useTranslation } from 'react-i18next'
import CustomerSelect from '../../../../../../../components/inputs/resourceSelects/CustomerSelect/CustomerSelect'
import LabSelect from '../../../../../../../components/inputs/resourceSelects/LabSelect/LabSelect'
import TestProfileSelect from '../../../../../../../components/inputs/resourceSelects/TestProfileSelect/TestProfileSelect'
import Button from '../../../../../../../components/Button/Button'
import Form from '../../../../../../../Form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import useAppointments, { AppointmentFilters } from '../../../../../../../data-hooks/Appointments/useAppointments'
import { Loading } from 'react-ui-scaffold'
import { useScreenSize } from '../../../../../../../hooks/useScreenSize'
import TextBox from '../../../../../../../components/inputs/TextBox'
import OrganizationMemberSelect from '../../../../../../../components/inputs/resourceSelects/OrganizationMemberSelect/OrganizationMemberSelect'

interface Props {
	view?: string
}
export default function AppointmentsFilters({ view }: Props) {
	const { setFilters, isLoading, filters } = useAppointments()
	const { t } = useTranslation()
	const { screenSize } = useScreenSize()
	const form = useForm<AppointmentFilters>()
	const { handleSubmit, reset, register, setValue } = form
	const [collapsed, setCollapsed] = useState<Boolean>(true)

	const onSubmit = (filters: AppointmentFilters) => {
		setFilters(filters)
	}

	useEffect(() => {
		setValue('startDate', undefined)
		setValue('endDate', undefined)
		const _filters = { ...filters }
		delete _filters.endDate
		delete _filters.startDate
		setFilters(_filters)
	}, [view])
	
	return (
		<div>
			<Button onClick={() => setCollapsed(!collapsed)} className='secondary wide'>
				<ButtonContent>
					{isLoading && <Loading className='spinner' />}
					Search
					<FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp}/>
				</ButtonContent>
			</Button>
			<FormProvider {...form}>
				<FilterForm collapsed={collapsed} screenSize={screenSize}>
					<Form showButtons={false} onSubmit={handleSubmit(onSubmit)} >
						<div className='fields'>
							<TextBox
								type='number'
								label={t('id') as string}
								wide
								{...register('shortId')}
							/>
							<CustomerSelect hideAdd clearable />
							{view != 'calendar' && (<>
								<DatePickerForm name='startDate' label={t('start_date') as string} />
								<DatePickerForm name='endDate' label={t('end_date') as string} />
							</>)}
							<LabSelect hideAdd clearable />
							<OrganizationMemberSelect clearable />
							<TestProfileSelect hideAdd clearable />
						</div>
						<ButtonRow>
							<Button className={'negative'} onClick={() => reset()}>{t('reset')}</Button>
							<Button className={'positive'} type='submit'>{t('search')}</Button>
						</ButtonRow>
					</Form>
				</FilterForm>
			</FormProvider>
		</div>
	)
}
