import { Order, UpdateOrderParams } from '../../../../common/types/OrderTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { getSingleOrder, updateOrder } from '../../api/orders'
import useOrders from './useOrders'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	order?: Order,
	orderId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateOrderParams, 'orderId'>): Promise<AxiosResponse<Order>>
	setOrder: (order: Order) => void
	setOrderId: (order: string) => void
}>(null as any)


interface ProviderProps{
	children:any
	populate?: string[],
}

export const SingleOrderProvider = ({ children, populate = [] }: ProviderProps) => {
	const [orderId, setOrderId] = useState<string>()
	const [order, setOrder] = useState<Order>()
	const [isLoading, setIsLoading] = useState(false)
	const { replace } = useOrders()
	
	useEffect(() => {
		if (orderId) {
			setIsLoading(true)
			
			getSingleOrder(orderId, populate)
				.then(({ data }) => setOrder(data))
				.finally(() => setIsLoading(false))
		}
	}, [orderId])
	
	const update = useCallback(async (params: any) => {
		const newOrder = await updateOrder(params, order?._id as string)
		
		replace(newOrder.data)
		getSingleOrder(order?._id as string, populate).then(({ data }) => setOrder(data))
		
		return newOrder
	},[order?._id])

	return <Context.Provider value={{
		order,
		orderId,
		isLoading,
		update,
		setOrder,
		setOrderId,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleOrder = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleOrder outside provider!')
	}
	return val
}

export default useSingleOrder
