import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { ActionIcon, ActionRow } from './BasicActions.styled'


interface _Props { // eslint-disable-line 
	editHandler?(row: any): any
	deleteHandler(row: any): any
	hideEdit?(row: any): boolean
	hideDelete?(row: any): boolean,
}

export default function BasicActions({ deleteHandler, editHandler, hideEdit, hideDelete, row }: Partial<QuickTableCellProps>) {
	const shouldHideEdit = () => hideEdit ? (hideEdit(row)) : false
	const shouldHideDelete = () => hideDelete ? (hideDelete(row)) : false
	
	return (
		<ActionRow>
			{!shouldHideEdit() && <ActionIcon icon={faPen} onClick={() => editHandler && editHandler(row)} tooltip='edit' />}
			{!shouldHideDelete() && <ActionIcon icon={faTrash} onClick={() => deleteHandler&& deleteHandler(row)} tooltip='delete' />}
		</ActionRow>
	)
}
