import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorText } from './ErrorMessage.styled'

interface Props {
	name?: string
}
export default function ErrorMessage({ name }: Props) {
	const { formState: { errors } } = useFormContext()
	const { t } = useTranslation()
	
	if (!name || !errors[name]) {
		return null
	}

	const nameForError = name as string
	let errorCode
	if (errors[nameForError]?.type == 'validate') {
		errorCode = errors[nameForError]?.message as string
	} else {
		errorCode = `error_${errors[nameForError]?.type as string}`
	}
	
	return (
		<ErrorText>{t(errorCode)}</ErrorText>
	)
}
