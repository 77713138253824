import { TestProfile, UpdateTestProfileParams } from '../../../../common/types/TestProfileTypes'
import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { getSingleTestProfile, updateTestProfile } from '../../api/testProfiles'
import useTestProfiles from './useTestProfiles'
import { AxiosResponse } from 'axios'

const Context = createContext<{
	testProfile?: TestProfile,
	testProfileId?: string,
	isLoading: boolean,
	update(p:Omit<UpdateTestProfileParams, 'testProfileId'>): Promise<AxiosResponse<TestProfile>>
	setTestProfile: (testProfile: TestProfile) => void
	setTestProfileId: (testProfile: string) => void
}>(null as any)


interface ProviderProps{
	children:any
}

export const SingleTestProfileProvider = ({ children }: ProviderProps) => {
	const [testProfileId, setTestProfileId] = useState<string>()
	const [testProfile, setTestProfile] = useState<TestProfile>()
	const [isLoading, setIsLoading] = useState(false)
	const { replace } = useTestProfiles()
	
	useEffect(() => {
		if (testProfileId) {
			setIsLoading(true)
			
			getSingleTestProfile(testProfileId)
				.then(({ data }) => setTestProfile(data))
				.finally(() => setIsLoading(false))
		}
	}, [testProfileId])
	
	const update = useCallback(async (params: Omit<UpdateTestProfileParams, 'testProfileId'>) => {
		const newTestProfile = await updateTestProfile({
			...params,
			testProfileId: testProfile?._id as string,
		})
		
		replace(newTestProfile.data)
		getSingleTestProfile(testProfile?._id as string).then(({ data }) => setTestProfile(data))
		
		return newTestProfile
	},[testProfile?._id])

	return <Context.Provider value={{
		testProfile,
		testProfileId,
		isLoading,
		update,
		setTestProfile,
		setTestProfileId,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleTestProfile = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleTestProfile outside provider!')
	}
	return val
}

export default useSingleTestProfile
