import React, { useMemo } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import i18n from '../../../../../../../i18n'
import useAppointments from '../../../../../../../data-hooks/Appointments/useAppointments'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { createAppointmentEditPath } from '../../../../../../../../../common/paths'
import { CalendarWrapper } from './CalendarView.styled'
import moment from 'moment'
import { useScreenSize } from '../../../../../../../hooks/useScreenSize'

export default function CalendarView() {
	const { t } = useTranslation()
	const history = useHistory()
	const { screenSize } = useScreenSize()
	const { appointments, appointmentPipeline, filters, setFilters } = useAppointments()
	const events = useMemo(() => {
		const stepColorMap: any = {}
		appointmentPipeline?.steps.map((step) => {
			stepColorMap[step._id] = step.color
		})
		return appointments
			.filter((appointment) => !!appointment.date)
			.map((appointment) => {
				return {
					title: t('test_profile_for_customer', { testProfile: appointment.testProfile?.code || appointment.tests?.map((test) => test.code).join(', '), customer: appointment.customer?.name }),
					date: appointment.date,
					color: stepColorMap[appointment.pipelineStep] || '#000',
					id: appointment._id,
				}
			})
	}, [appointments, t, appointmentPipeline])

	const onClick = (e: any) => {
		history.push(createAppointmentEditPath(e.event.id))
	}

	const dayWidth = useMemo(() => {
		if (screenSize == 'mobile') {
			return 145
		} else {
			return 165
		}
	}, [screenSize])

	return (
		<CalendarWrapper dayWidth={dayWidth}>
			<FullCalendar
				plugins={[timeGridPlugin, dayGridPlugin]}
				initialView='timeGridWeek'
				events={events}
				locale={i18n.language}
				allDaySlot={false}
				eventClick={onClick}
				headerToolbar={{
					left: '',
					center: '',
					right: 'today prev,next',
				}}
				datesSet={(e) => {
					setFilters({
						...filters,
						startDate: moment(e.start).format('YYYY-MM-DD'),
						endDate: moment(e.end).format('YYYY-MM-DD'),
					})
				}}
				height='auto'
				slotMinWidth={200}
			/>
		</CalendarWrapper>
	)
}
