import { Lab, CreateLabParams, UpdateLabParams } from '../../../common/types/LabTypes'
import { PaginateResponse } from '../../../common/types/PaginationTypes'
import { formatQueryString } from '../utils/formatUtils'
import axios, { urls } from './axios'

export const getLabs = ({ searchText, type, category, page }:{
	type?: string,
	searchText?: string
	category?: string
	page?: number
}) => axios.get<PaginateResponse<Lab[]>>(`${urls.organization}/labs${formatQueryString({ q: searchText, type, category, page })}`)

export const createLab = (params: CreateLabParams) => axios.post<Lab>(`${urls.organization}/labs`, params)

export const getSingleLab = (labId: string) => axios.get<Lab>(`${urls.organization}/labs/${labId}`)

export const deleteLab = (labId: string) => axios.delete<Lab>(`${urls.organization}/labs/${labId}`)

export const updateLab = (params:UpdateLabParams) => axios.patch<Lab>(`${urls.organization}/labs/${params.labId}`, params)
