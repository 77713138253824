import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import DropdownButton, { DropdownItem } from '../DropdownMenu/DropdownButton'
import Icon from '../Icon/Icon'
import { EditIconsRow } from './EditIcons.styled'

interface Props {
	items: Array<DropdownItem>
}
export default function EditIcons({ items }: Props) {
	return (
		<EditIconsRow>
			<DropdownButton
				className='titleButton plain light'
				items={items}
			>
				<Icon icon={faEllipsisH} />
			</DropdownButton>
		</EditIconsRow>
	)
}
