import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Header from './Header/Header'

const Container = styled.div`
	padding: 20px;
`

const Content = styled.div`
	margin: 12px;
`

const SidebarHeader = styled.h1`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ViewLabel = styled.label`
	font-size: 16px;
    font-weight: bold;
`

const ViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: var(--mediumSpacing);
`

const EntityHeader = styled.div`
    overflow: hidden;
`

const StyledLink = styled(Link)`
	color: #0000EE;
    text-decoration: underline;
`

export {
	Container,
	Header,
	SidebarHeader,
	Content,
	ViewLabel,
	ViewContainer,
	EntityHeader,
	StyledLink,
}
